import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { imageWidth, imagePaddingTop, imageBackgroundColor, } from './constants';
import { FILE_ATTACHMENT_ACCESS_LEVEL, FILE_ATTACHMENT_CUSTOM_PATH } from '../../../../../store/constants';
import { Storage } from 'aws-amplify';
import { openAdvertiserUrl } from '../util';

const IMAGE_LINK_EXPIRES_SECONDS = 0.5 * 60 * 60; // 30 minutes

const getImageDiv = imageUrl => {
    return {
        width: imageWidth,
        paddingTop: imagePaddingTop,
        backgroundColor: imageBackgroundColor,
        backgroundImage: `url("${imageUrl}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
    };
};

const FileHeaderSection = ({ headerText, imageKey, advertiserUrl, classes, }) => {

    const [imageUrl, setImageUrl] = useState(null);

    const getImageUrl = async () => {

        const config = {
            level: FILE_ATTACHMENT_ACCESS_LEVEL,
            expires: IMAGE_LINK_EXPIRES_SECONDS,
            customPrefix: {
                [FILE_ATTACHMENT_ACCESS_LEVEL]: FILE_ATTACHMENT_CUSTOM_PATH,
            },
        };

        const url = await Storage.get(imageKey, config);
        setImageUrl(url);
    };

    useEffect(() => {
        getImageUrl();
    }, []);

    const openAdvertisement = () => {
        if (advertiserUrl) {
            openAdvertiserUrl(advertiserUrl);
        }
    };

    return (
        <div className={classes.container}>
            { imageUrl ? <div style={getImageDiv(imageUrl)} onClick={openAdvertisement}/> :
                <div className={classes.imgPlaceholder}>
                    <div className={classes.activityIndicatorContainer}>
                        <CircularProgress className={classes.activityIndicator} color='inherit' />
                    </div>
                </div>
            }
            <div className={classes.headerTxtContainer}>
                <Typography align='left' variant='h6' color='textPrimary'>{headerText}</Typography>
            </div>
        </div>
    );
}

export default FileHeaderSection;