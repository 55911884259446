
import _ from 'lodash';
import { isIOS, isSafari } from 'react-device-detect';

/**
 * 
 * Copies the text to the clipboard for an iOS device
 * 
 * @param {string} text - the text to copy
 */
export const iOSCopyTextToClipboard = text => {
    // add a temporary textarea:
    // - should contain the text to copy
    // - should be focussed and selected before calling: document.execCommand('copy')
    const textArea = document.createElement('textarea');
    textArea.value = text;

    textArea.setAttribute('contentEditable', 'true');
    textArea.setAttribute('readOnly', 'false');
    textArea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    const range = document.createRange();
    range.selectNodeContents(textArea);

    // select the range
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);

    let success = false;
    try {
        success = document.execCommand('copy');
    } catch (error) {
        console.log('iOSCopyToClipboard: Error using document.execCommand("copy") - error: ', error);
    }

    // remove the temporarily added textarea
    document.body.removeChild(textArea);

    return success;
}

/**
 * 
 * Copies the text to the clipboard. Tries to use the newer navigator.clipboard api if available, falls back to document.execCommand('copy')
 * Important Note: Should be called within a user click event
 * 
 * @param {string} text - the text to copy to clipboard
 * @returns {boolean} result - true if the copy succeeded, false otherwise
 */
export const copyTextToClipboard = async text => {

    let useFallbackCopy = false;

    if (navigator.clipboard) {
        try {
            await navigator.clipboard.writeText(text);
            return true;
        } catch(error) {
            console.log('Error using navigator.clipboard api, fallback to document.execCommand("copy") - error: ', error);
            useFallbackCopy = true;
        }
    } else {
        useFallbackCopy = true;
    }

    if(useFallbackCopy) {
        if (isIOS) {
            return iOSCopyTextToClipboard(text);
        } else {
            // add a temporary textarea:
            // - should contain the text to copy
            // - should be focussed and selected before calling: document.execCommand('copy')
            const textArea = document.createElement('textarea');
            textArea.value = text;

            if (isSafari) {
                textArea.setAttribute('contentEditable', 'true');
                textArea.setAttribute('readOnly', 'false');
            }

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            let success = false;
            try {
                success = document.execCommand('copy');
            } catch (error) {
                console.log('Error using document.execCommand("copy") - error: ', error);
            }

            // remove the temporarily added textarea
            document.body.removeChild(textArea);

            return success;
        }
    }
}

export const isShareSupported = !!navigator.share;

/**
 * 
 * Uses the navigator.share api if available, falls back to try copying the file url to the clipboard
 * Important Note: Should be called within a user click event
 * 
 * @param {string} fileUrl - url of the file to share
 * @returns {Object} result - contains three booleans: success, shared, copiedToClipboard
 */
export const shareFile =  async (fileUrl, fallbackToCopyToClipboard=true) => {
    if (navigator.share) {
        try {
            await navigator.share({
                url: fileUrl
            });
            return {
                success: true,
                shared: true,
                copiedToClipboard: false,
            };
        } catch(error) {
            console.log('Error using navigator.share - error: ', error);
            if ((_.get(error, 'name') === 'AbortError') && _.includes(_.get(error, 'message', ''), 'cancel')) {
                // On iOS if the user cancels the share flow we get the following error:
                // AbortError: Abort due to cancellation of share
                // In this case we say the operation was successful since the user could successfully share if they wanted to, but they cancelled the iOS share dialog
                // and this should not result in an error showing
                return {
                    success: true,
                    shared: false,
                    copiedToClipboard: false,
                };
            };
            return {
                success: false,
                shared: false,
                copiedToClipboard: false,
            };
        }
    } else {

        if (!fallbackToCopyToClipboard) {
            return {
                success: false,
                shared: false,
                copiedToClipboard: false,
            };
        }

        const copiedToClipboard =  copyTextToClipboard(fileUrl);
        return {
            success: copiedToClipboard,
            shared: false,
            copiedToClipboard: copiedToClipboard,
        };
    }
}
