import React from 'react';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import ArrowRightIcon from '@material-ui/icons/ArrowForward';
import { withStyles } from '@material-ui/core/styles';

const ExportFilesIcon = ({ classes }) => {
    return (
        <div className={classes.container}>
            <FileIcon />
            <ArrowRightIcon className={classes.arrowIcon} />
        </div>
    );
}

const materialStyles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    arrowIcon: {
        position: 'absolute',
        color: '#3D4450',
        fontSize: 14,
        marginTop: 2,
    },
});

export default withStyles(materialStyles)(ExportFilesIcon);