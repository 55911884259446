import { createMuiTheme } from '@material-ui/core/styles';
import colors from './colors.scss';

// red palette
const primary = {
    50: '#FEE8E7',
    100: '#FCC7C3',
    200: '#FAA19B',
    300: colors.primaryLightColor,
    400: '#F65F54',
    500: colors.primaryColor,
    600: '#F33D30',
    700: colors.primaryDarkColor,
    800: '#EF2C22',
    900: '#EC1E16',
    A100: '#FFFFFF',
    A200: '#FFE9E9',
    A400: '#FFB8B6',
    A700: '#FF9F9C',
    contrastText: '#FFFFFF',
    dark: colors.primaryDarkColor, // matches 700
    light: colors.primaryLightColor, // matches 300
    main: colors.primaryColor, // matches 500
};

// grey palette
const secondary = {
    50: '#E7E7E7',
    100: '#C2C2C2',
    200: '#999999',
    300: colors.secondaryLightColor,
    400: '#525252',
    500: colors.secondaryColor,
    600: '#2E2E2E',
    700: colors.secondaryDarkColor,
    800: '#202020',
    900: '#141414',
    A100: '#EF6E6E',
    A200: '#EA4040',
    A400: '#F60000',
    A700: '#DD0000',
    contrastText: '#FFFFFF',
    dark: colors.secondaryDarkColor, // matches 700
    light: colors.secondaryLightColor, // matches 300
    main: colors.secondaryColor, // matches 500
};

const error = {
    light: colors.errorColor,
    main: colors.errorColor,
    dark: '#A2001D',
    contrastText: '#FFFFFF',
};

const text = {
    primary: 'rgba(255, 255, 255, 0.87)',
    secondary: 'rgba(255, 255, 255, 0.6)',
    disabled: 'rgba(255, 255, 255, 0.38)',
    hint: 'rgba(255, 255, 255, 0.38)',
};

const background = {
    paper: '#FFFFFF',
    default: colors.backgroundColor,
};

const common = {
  ratingFail: '#EB7265',
  ratingFailBg: 'rgba(235, 114, 101, 0.1) ',
  ratingCaution: '#EEC334',
  ratingCautionBg: 'rgba(238, 195, 52, 0.1)',
  ratingPass: '#31B899',
  ratingPassBg: 'rgba(49, 184, 153, 0.1)',
};

const materialAppTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: primary,
    secondary: secondary,
    error: error,
    text: text,
    background: background,
    common: common,
    divider: text.secondary,
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        minHeight: 48,
      },
      contained: {
        color: secondary.light,
      },
      containedPrimary: {
        color: text.primary,
        '&:disabled': {
          color: 'rgba(0, 0, 0, 0.38)',
          backgroundColor: '#DFDFDF',
        },
        '&:hover': {
          color: text.primary,
          backgroundColor: '#F65F54',
        }
      },
    },
    MuiFab: {
      root: {
        '&:hover': {
          color: text.primary,
          backgroundColor: '#F65F54',
        }
      }
    },
    MuiDialogTitle: {
      root: {
        color: 'rgba(0, 0, 0, 0.87)',
      }
    },
    MuiDialogContentText: {
      root: {
        color: 'rgba(0, 0, 0, 0.6)',
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: secondary['800'],
        color: '#FFFFFF',
        paddingLeft: 16,
        paddingRight: 16,
      },
      message: {
        padding: 0,
      },
      action: {
        paddingLeft: 4,
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: '#3D4450',
      },
    },
    MuiMenuItem: {
      root: {
        minWidth: 160,
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: `1px solid ${text.primary}`,
        },
        "&:after": {
          borderBottom: `1px solid ${text.primary}`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: text.primary,
        "&$focused": {
          color: text.primary
        },
      },
    },
  },
});

export default materialAppTheme;