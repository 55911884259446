export const questionFontSize = '1.1rem';
const iconFontSize = '3.2rem';
const labelFontSize = '1.2rem';

export default theme => ({
    ratingsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    ratingContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing.unit,
        padding: theme.spacing.unit,
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.secondary['100'],
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundColor: '#F3F3F3',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: questionFontSize,
    },
    ratingQuestion: {
        wordWrap: 'break-word',
    },
    ratingButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    ratingBtnLbl: {
        display: 'flex',
        flexDirection: 'column',
    },
    defaultIconContainer: {
        color: theme.palette.secondary['100'],
        fontSize: iconFontSize,
    },
    failIconContainer: {
        color: theme.palette.common.ratingFail,
        fontSize: iconFontSize,
    },
    cautionIconContainer: {
        color: theme.palette.common.ratingCaution,
        fontSize: iconFontSize,
    },
    passIconContainer: {
        color: theme.palette.common.ratingPass,
        fontSize: iconFontSize,
    },
    defaultText: {
        color: theme.palette.secondary['100'],
        fontSize: labelFontSize,
        fontWeight: theme.typography.fontWeightMedium,
    },
    selectedText: {
        color: theme.palette.common.black,
        fontSize: labelFontSize,
        fontWeight: theme.typography.fontWeightMedium,
    },
});