import { awsApiRequest } from '../../utils/func';
import { BATCH_ACTION_TYPE } from './constants';

export const fetchFolderContents = async ({ folderId }) => {

    if (!folderId) {
        console.error('api-folder-get-folder-contents - No folderId specified');
        return {
            errorMessage: 'common:err_default'
        }
    }

    try {
        return await awsApiRequest({
            method: 'GET',
            path: `/folders/${folderId}/contents`,
        });

    } catch (error) {
        return error;
    }
};

export const updateFolderContentOrder = async ({ folderId, order=-1 }) => {

    if (!folderId) {
        console.error('api-folder-update-folder-order - No folderId specified');
        return {
            errorMessage: 'common:err_default'
        }
    }

    if (order < 0) {
        console.error(`api-folder-update-folder-order - Inappropriate folder order specified: ${order}`);
        return {
            errorMessage: 'common:err_default'
        }
    }

    try {
        return await awsApiRequest({
            method: 'PUT',
            path: `/folders/${folderId}/order-num`,
            params: {
                body: {
                    order: order,
                },
            },
        });
    } catch (error) {
        return error;
    }
}

export const createFolder = async ({ folderName, parentId, }) => {

    if (!parentId || !folderName) {
        console.error(`api-folder-create-folder - Missing folderName:${!folderName} or parentId:${!parentId}`);
        return {
            errorMessage: 'common:err_default'
        }
    }

    try {
        return await awsApiRequest({
            method: 'POST',
            path: '/folders',
            params: {
                body: {
                    parentId: parentId,
                    folderName: folderName,
                },
            },
        });
    } catch(error) {
        return error;
    }
}

export const addFileToFolder = async ({ fileAlias, folderId, }) => {

    if (!fileAlias || !folderId) {
        console.error(`api-add-file-to-folder - Missing fileAlias:${!fileAlias} or folderId:${!folderId}`);
        return {
            errorMessage: 'common:err_default'
        }
    }

    try {
        return await awsApiRequest({
            method: 'PUT',
            path: `/folders/${folderId}/files`,
            params: {
                body: {
                    file_id: fileAlias
                },
            },
        });
    } catch(error) {
        return error;
    }
}

export const renameFolder = async ({ folderId, folderName }) => {

    if (!folderId || !folderName) {
        console.error(`api-folder-rename-folder - Missing folderId:${!folderId} or folderName:${!folderName}`);
        return {
            errorMessage: 'common:err_default'
        }
    }

    try {
        return await awsApiRequest({
            method: 'PUT',
            path: `/folders/${folderId}`,
            params: {
                body: {
                    folderName: folderName,
                },
            },
        });
    } catch(error) {
        return error;
    }
}

export const removeFolder = async ({ folderId }) => {

    if (!folderId) {
        console.error('api-folder-remove-folders - No folderId specified');
        return {
            errorMessage: 'common:err_default'
        }
    }

    try {
        return await awsApiRequest({
            method: 'DELETE',
            path: `/folders/${folderId}`
        });
    } catch(error) {
        return error;
    }
}

/**
 *
 * @param {string} folderId - the id of the folder to remove the file(s) from
 * @param {string[]} fileIds - an array of file ids ['1234567', '1234568']
 */
export const removeFile = async ({ folderId, fileId }) => {

    if (!folderId || !fileId) {
        console.error(`api-folder-remove-file - Missing folderId:${!folderId} or fileId:${fileId}`);
        return {
            success: false,
            errorMessage: 'common:err_default'
        }
    }

    try {
        return await awsApiRequest({
            method: 'DELETE',
            path: `/folders/${folderId}/files/${fileId}`,
        });
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param {Object} data - the request data
 * @param {string} data.sourceFolderId - the folder id of the origin folder
 * @param {string} data.targetFolderId - the folder id of the destination folder
 * @param {string} data.fileId - the file id of the file to move
 */
export const moveFileToFolder = async ({ sourceFolderId, targetFolderId, fileId, }) => {

    if (!targetFolderId || !sourceFolderId || !fileId) {
        console.error(`api-move-files-to-folder - Missing sourceFolderId:${!sourceFolderId}, targetFolderId:${!targetFolderId} or fileId:${!fileId}`);
        return {
            errorMessage: 'common:err_default'
        }
    }

    try {
        return await awsApiRequest({
            method: 'PUT',
            path: `/folders/${targetFolderId}/files/move`,
            params: {
                body: {
                    source_folder_id: sourceFolderId,
                    file_id: fileId,
                },
            },
        });
    } catch(error) {
        return error;
    }
}

/**
 *
 * @param {Object} data - the request data
 * @param {Number} action - the batch action type (one of BATCH_ACTION_TYPE)
 * @param {string} data.targetFolderId - the folder id of the final folder to perform the action on
 * @param {string[]} data.fileIds - an array of file ids ['1234567', '1234568']
 * @param {string} data.sourceFolderId - the folder id of the source folder (only required when data.type === BATCH_ACTION_TYPE.MOVE_FILE)
 */
const batchAction = async ({ action, targetFolderId, fileIds, sourceFolderId, }) => {

    if (!action || !targetFolderId || (!fileIds || fileIds.length === 0)) {
        console.error(`api-folder-batch-action - Missing action:${!action}, sourceFolderId:${!sourceFolderId}, targetFolderId:${!targetFolderId} or fileIds:${(!fileIds || fileIds.length === 0)}`);
        return {
            errorMessage: 'common:err_default'
        }
    }

    if ((action === BATCH_ACTION_TYPE.MOVE_FILES) && !sourceFolderId) {
        console.error('api-folder-batch-action - Missing sourceFolderId');
        return {
            errorMessage: 'common:err_default'
        }
    }

    const body = {
        action,
        file_ids: fileIds
    };

    if (action === BATCH_ACTION_TYPE.MOVE_FILES) {
        body.source_folder_id = sourceFolderId;
    }

    try {
        return await awsApiRequest({
            method: 'PUT',
            path: `/folders/${targetFolderId}/files/batch-actions`,
            params: {
                body: body,
            },
        });
    } catch(error) {
        return error;
    }
}

/**
 *
 * @param {Object} data - the request data
 * @param {string} data.sourceFolderId - the folder id of the origin folder
 * @param {string} data.targetFolderId - the folder id of the destination folder
 * @param {string[]} data.fileIds - an array of file ids ['1234567', '1234568']
 */
export const batchMove = async ({ targetFolderId, fileIds, sourceFolderId, }) =>  batchAction({
    action: BATCH_ACTION_TYPE.MOVE_FILES,
    targetFolderId,
    fileIds,
    sourceFolderId
});

/**
 * 
 * @param {Object} data - the request data
 * @param {string} data.folderId - the id of the folder to remove the file(s) from
 * @param {string[]} data.fileIds - an array of file ids ['1234567', '1234568']
 */
export const batchRemove = async ({ folderId, fileIds, }) =>  batchAction({
    action: BATCH_ACTION_TYPE.REMOVE_FILES,
    targetFolderId: folderId,
    fileIds,
});

/**
 * 
 * @param {Object} data - the request data
 * @param {string} data.userEmail - the email of the user to send the exported files to
 * @param {string} data.folderId - the id of the folder we should export the files from
 */
export const exportFiles = async ({ userEmail, folderId, }) =>  {
    if (!userEmail || !folderId) {
        console.error(`api-export-files - Missing  userEmail:${!userEmail} or folderId:${!folderId}`);
        return {
            success: false,
            errorMessage: 'common:err_default'
        }
    }

    try {
        return await awsApiRequest({
            method: 'POST',
            path: `/folders/${folderId}/export-files`,
            params: {
                body: {
                    recipient_email: userEmail,
                    view_files_base_url: `${window.location.origin}/file`,
                },
            },
        });
    } catch (error) {
        return error;
    }
}
