import _ from 'lodash';
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTranslation } from 'react-i18next';

const MenuItemContent = ({ MenuItemIcon, text }) => {
    return (
        <>
            <ListItemIcon>
                <MenuItemIcon />
            </ListItemIcon>
            <ListItemText primary={text} />
        </>
    );
}

const ContextMenu = ({ isOpen, menuItems, openContextMenu, closeContextMenu, }) => {

    const { t } = useTranslation('common');
    const [anchorEl, setAnchorEl] = useState(null);

    const toggleContextMenu = e => {
        if (isOpen) {
            setAnchorEl(null);
            closeContextMenu();
        } else {
            setAnchorEl(e.currentTarget);
            openContextMenu();
        }
    }

    if (_.isEmpty(menuItems)) {
        return null;
    }

    return (
        <div>
            <IconButton aria-label={t('common:context_menu_toggle_btn')} onClick={toggleContextMenu}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={toggleContextMenu}>
                {
                    menuItems.map(item =>
                        (
                            <MenuItem key={item.text} disabled={!item.enabled} onClick={event => {
                                    closeContextMenu();
                                    item.handleClick(event);
                                }}>
                                <MenuItemContent MenuItemIcon={item.icon} text={item.text} />
                            </MenuItem>
                        )
                    )
                }
            </Menu>
        </div>
    );
};

export default ContextMenu;