import { inputThemeLightUnderline } from '../../../common/form/form.materialStyles';

export default theme => ({
    container: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    searchBtn: {
        position: 'absolute',
        right: theme.spacing.unit*1.5,
        bottom: -24,
        zIndex: 2,
    },
    itemInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: '#F3F3F3',
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit*3,
        paddingLeft: theme.spacing.unit*2,
        paddingRight: theme.spacing.unit*2,
        position: 'relative',
    },
    label: {
        color: theme.palette.secondary.dark,
        '&$labelFocused': {
            color: theme.palette.secondary.dark,
        },
    },
    labelFocused: {
        // Just required to access the rule above
    },
    input: {
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing.unit,
    },
    underline: inputThemeLightUnderline(theme),
    clearIconBtn: {
        color: theme.palette.secondary.light,
    },
});