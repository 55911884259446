import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import FolderIcon from '@material-ui/icons/Folder';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FileIndicator from './fileIndicator';
import WarningIcon from './warningIcon';
import { isMobile, isIOS } from 'react-device-detect';
import { FOLDER_ITEM_TYPE_FOLDER, FOLDER_ITEM_TYPE_FILE } from '../../constants';

// Note: onShowFolderItemContextMenu is only used on Desktop, mobile devices context menu long press is handled in the dashboard's SortableFolder component
const FolderItem = ({ id, name, type, itemsCount, isReadOnly, parentId, parentName, isContextMenuDisabled, problemsFolderId, viewingUserDashboardProblemsFolderId, onShowFolderItemContextMenu, showMultiFileSelect, isMultiSelected, addFileToSelectedFileIds, removeFileFromSelectedFileIds, navViewFolder, navViewFile, classes }) => {

    const handleClick = e => {
        if (e.type === 'click') {
            if (type === 'folder') {
                navViewFolder({
                    folderId: id
                });
            } else if (type === FOLDER_ITEM_TYPE_FILE) {
                navViewFile({
                    fileAlias: id,
                    parentFolderId: parentId,
                    parentFolderName: parentName,
                });
            }
        } else if (e.type === 'contextmenu') {
            e.preventDefault();

            if (isContextMenuDisabled) {
                return;
            }

            onShowFolderItemContextMenu({
                folderItemId: id,
                folderItemType: type,
                eventCurrentTarget: e.currentTarget,
            });
        }
    }

    const handleMultiSelectClick = e => {
        e.stopPropagation();
        if (isMultiSelected) {
            removeFileFromSelectedFileIds(id);
        } else {
            addFileToSelectedFileIds(id);
        }
    }

    const currentProblemsFolderId = viewingUserDashboardProblemsFolderId ? viewingUserDashboardProblemsFolderId : problemsFolderId;

    // Note iOS: had to set disableTouchRipple to true for iOS device to prevent a lingering ripple effect see notes in SortableFolder component

    return (
        <ButtonBase onClick={handleClick} onContextMenu={isMobile ? null : handleClick} aria-owns={`context-menu-${id}`} aria-haspopup='true' disableTouchRipple={isIOS}>
            <div className={classes.container}>
                <div className={classes.iconContainer}>
                    { type === FOLDER_ITEM_TYPE_FOLDER && <FolderIcon color={isReadOnly ? 'primary' : 'inherit'} fontSize='inherit' /> }
                    { type === FOLDER_ITEM_TYPE_FILE && <FileIndicator /> }
                    { ((type === FOLDER_ITEM_TYPE_FOLDER) && (itemsCount > 0) && (id !== currentProblemsFolderId))&&
                        <div className={classes.countContainer}>
                            <Typography color='textPrimary'>{itemsCount}</Typography>
                        </div>
                    }
                    { ((type === FOLDER_ITEM_TYPE_FOLDER) && (itemsCount > 0) && (id === currentProblemsFolderId))&&
                        <div className={classes.warningContainer}>
                            <WarningIcon />
                        </div>
                    }
                    { (showMultiFileSelect && (type === FOLDER_ITEM_TYPE_FILE)) &&
                        <div className={classes.multiSelectCheckCircle} onClick={handleMultiSelectClick}>
                            { isMultiSelected && <CheckIcon />}
                        </div>
                    }
                </div>
                <Tooltip title={
                        <Typography className={classes.tooltipNameWrapper} noWrap>{name}</Typography>
                    } placement='bottom'>
                    <div className={classes.nameContainer}>
                        <Typography className={classes.name} color='textPrimary' align='center' variant='inherit'>{name}</Typography>
                    </div>
                </Tooltip>
            </div>
        </ButtonBase>
    );
}

export default FolderItem;