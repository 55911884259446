import _ from 'lodash';
import { SORT_TYPE } from "../../../store/constants";
import { FOLDER_ITEM_TYPE_FOLDER } from '../../../constants';

/**
 * 
 * Takes the map of folder contents and sorts it according to the contentsSortType
 * // Note: folders are always sorted by order (default), while files can be sorted by order (default), name ascending or name descending
 * 
 * @param {Object} contents - the folder contents map, i.e. { folderItemId1: {..., type: 'file' }, folderItemId2: {..., type: 'folder' } }
 * @param {String} contentsSortType - the sort type - one of SORT_TYPE
 * @returns {Array} of ordered folder contents
 */
export const getOrderedFolderContents = (contents, contentsSortType) => {

    if (_.isEmpty(contents)) {
        return [];
    }

    const [folders, files] = _.partition(contents, item => item.type === FOLDER_ITEM_TYPE_FOLDER);
    let orderFileByKey; // file property like name and order or a function
    let orderFileByOrder; // asc or desc

    if ((contentsSortType === SORT_TYPE.A_TO_Z) || (contentsSortType === SORT_TYPE.Z_TO_A)) {
        orderFileByKey = item => item.name.toLowerCase(); // to lower case so that we can do a case insensitive sort
        if (contentsSortType === SORT_TYPE.A_TO_Z) {
          orderFileByOrder = 'asc';
        } else {
          orderFileByOrder = 'desc';
        }
    } else {
      orderFileByKey = 'order';
      orderFileByOrder = 'asc';
    }

    return _.concat(
      _.orderBy(folders, ['order'], ['asc']),
      _.orderBy(files, [orderFileByKey], [orderFileByOrder])
    );
};