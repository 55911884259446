import Legal from './legal';
import legalMaterialStyles  from './legal.materialStyles';
import { withStyles } from '@material-ui/core';
import { compose } from '../../../utils/func';
import { connect } from 'redhooks';
import { setContextMenuItems } from '../../../store/store.actions';
import withGA from '../../../utils/withGA'

export default compose(
    withStyles(legalMaterialStyles),
    connect(null, {
        setContextMenuItems
    }),
    withGA,
)(Legal);