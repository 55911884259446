import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

export default ({ isOpen, handleClose, handleDelete, t }) => {
    return (
        <Dialog open={isOpen}
              onClose={handleClose}
              aria-labelledby='delete-dialog-title'
              aria-describedby='delete-dialog-description'
              maxWidth='xs'>
            <DialogTitle id='delete-dialog-title' disableTypography={true}>
              <Typography variant='h6' color='inherit'>{t('dashboard:dialog_title_remove_folder')}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="delete-dialog-description">
                    {t('dashboard:dialog_msg_remove_folder')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary' autoFocus>
                    {t('common:btn_cancel')}
                </Button>
                <Button onClick={handleDelete} color='primary' autoFocus>
                    {t('common:btn_remove')}
                </Button>
          </DialogActions>
        </Dialog>
    );
}