import _ from 'lodash';
import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const FileField = ({ name, value, acceptedFileType, setFieldValue, setFieldTouched, classes }) => {

    const { t } = useTranslation(['common', 'form']);

    const handleFileInputChange = (event) => {
        const file = _.get(event, 'currentTarget.files[0]');
        setFieldValue(name, file);
        if (setFieldTouched) {
            setFieldTouched(name);
        }
    };

    const clearFile = () => {
        setFieldValue(name, '');
    };

    const inputProps = {
        id: 'attach-file-input',
        className: classes.input,
        name: name,
        type: 'file',
        onChange: handleFileInputChange,
    };

    if (acceptedFileType) {
        inputProps.accept = acceptedFileType;
    }

    return (
        <>
            <input {...inputProps} />
            <label className={classes.button}
                htmlFor="attach-file-input">
                <Button component="span" color='secondary' variant='outlined'>
                    {t('form:btn_choose_file')}
                    <AttachFileIcon />
                </Button>
            </label>
            <div className={classes.fileNameContainer}>
                { _.hasIn(value, 'name') && <FileIcon color='inherit' /> }
                { !_.hasIn(value, 'name') && <Typography className={classes.fileName} color='inherit' variant='inherit'>{t('form:lbl_no_file_selected')}</Typography>}
                <Typography className={classes.fileName} color='inherit' variant='inherit' noWrap>{_.get(value, 'name', '')}</Typography>
                { _.hasIn(value, 'name') &&
                    <IconButton className={classes.clearFileIconBtn}  onClick={clearFile}>
                        <ClearIcon color='secondary' fontSize='small' />
                    </IconButton>
                }
            </div> 
        </>
    );
};

const materialStyles = theme => ({
    input: {
        display: 'none',
    },
    button: {
        textTransform: 'none',
    },
    fileNameContainer : {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        color: theme.palette.secondary.light,
        minHeight: 32, // use default icon button height
    },
    fileName: {
        marginLeft: theme.spacing.unit,
    },
    clearFileIconBtn: {
        width: 32,
        height: 32,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: theme.spacing.unit,
    },
});

export default withStyles(materialStyles)(FileField);
