import React from 'react';
import FolderItemToolbar from '../../../../folder-item-toolbar';
import { openAdvertiserUrl } from '../util';

const FolderContentToolbar = ({ parentFolderId, parentFolderName, numFileEntries=0, advertiserUrl, navViewFolder, t }) => {

    const handleGoToParentFolder = () => {
        navViewFolder({
            folderId: parentFolderId
        });
    };

    const handleVisitAdvertiserLink = () => {
        if (advertiserUrl) {
            openAdvertiserUrl(advertiserUrl);
        }
    };

    return  (
        <FolderItemToolbar leftLinkText={`< ${parentFolderName}`}
            handleLeftLinkClick={handleGoToParentFolder}
            middleText={t('file:entries', { count: numFileEntries })}
            rightLinkText={ !!advertiserUrl ? t('file:visit_link') : ''} handleRightLinkClick={handleVisitAdvertiserLink}
            showDividers={true} />
    );
}

export default FolderContentToolbar