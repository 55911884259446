import { folderItemNameSizeREM, folderItemNameMaxLines, folderItemNameLineHeight, folderItemIconSize, folderItemHeight } from "./constants";

const multiSelectCircleSize = folderItemIconSize*0.65;
const multiSelectCircleMarginLeft = (folderItemIconSize - multiSelectCircleSize)/2;
const multiSelectCircleMarginBottom = 28 + (folderItemIconSize - multiSelectCircleSize)/2;
const multiSelectCircleBorderRadius = multiSelectCircleSize/2;

export default theme => ({
    container: {
        width: folderItemIconSize,
        height: folderItemHeight,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
    },
    iconContainer: {
        fontSize: folderItemIconSize,
        width: folderItemIconSize,
        height: folderItemIconSize,
        color: '#55617F',
    },
    tooltipNameWrapper: {
        maxWidth: '300px', // Same as MUI Tooltip maxWidth
    },
    nameContainer: {
        fontSize: `${folderItemNameSizeREM}rem`,
    },
    name: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        WebkitLineClamp: folderItemNameMaxLines,
        WebkitBoxOrient: 'vertical', 
        lineHeight: folderItemNameLineHeight,
        maxHeight: `${folderItemNameMaxLines*folderItemNameLineHeight}rem`,
    },
    countContainer: {
        width: 28,
        height: 28,
        borderRadius: 14,
        backgroundColor: '#31B899',
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        position: 'absolute',
        bottom: 34,
        right:  2,
    },
    warningContainer: {
        width: '100%',
        height: folderItemIconSize,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems:'center',
        position: 'absolute',
        top: 5,
        left: 1,
        right:  0,
        bottom: 0,
    },
    multiSelectCheckCircle: {
        width: multiSelectCircleSize,
        height: multiSelectCircleSize,
        borderRadius: multiSelectCircleBorderRadius,
        borderColor: theme.palette.secondary.light,
        borderStyle: 'solid',
        borderWidth: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        position: 'absolute',
        bottom: multiSelectCircleMarginBottom,
        left: multiSelectCircleMarginLeft,
    },
});