export default theme => ({
    container: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing.unit*2,
    },
    userIconContainer: {
        marginTop: 24,
        width: 48,
        height: 48,
        borderRadius: 24,
        backgroundColor: '#55617f',
        fontSize: 48,
    },
    userIcon: {
        color: theme.palette.common.white,
    },
    name: {
        marginTop: theme.spacing.unit*2,
    },
})