import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import FileIndicator from '../../../folder-item/fileIndicator';

const SearchListItem = ({ alias, name, description, parentId, onFileClick, classes: { listItemIcon, listItemText, divider } }) => {

    const handleFileClick = () => {
        onFileClick(alias, parentId);
    };

    return (
        <>
            <ListItem button disableGutters={true} onClick={handleFileClick}>
                <ListItemIcon className={listItemIcon}>
                    <FileIndicator />
                </ListItemIcon>
                <ListItemText className={listItemText}
                    primary={name}
                    primaryTypographyProps={{ noWrap:  true }} 
                    secondary={description}
                    secondaryTypographyProps={{ noWrap: true }}/>
            </ListItem>
            <Divider className={divider} variant='middle'/>
        </>
    );
}

export default SearchListItem;