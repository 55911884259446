import * as actionTypes from './dashboard.action.types';

export const setFolderContentToolbar = payload => ({
    type: actionTypes.ACTION_SET_FOLDER_CONTENT_TOOLBAR,
    payload: payload,
});

export const setErrorMessage = errorMessage => ({
    type: actionTypes.ACTION_SET_ERROR_MESSAGE,
    payload: errorMessage,
});

export const setContextMenu = payload => ({
    type: actionTypes.ACTION_SET_CONTEXT_MENU,
    payload: payload,
});

export const showRemoveFolderDialog = folderId => ({
    type: actionTypes.ACTION_SHOW_REMOVE_FOLDER_DIALOG,
    payload: folderId,
});

export const hideRemoveDialog = () => ({
    type: actionTypes.ACTION_HIDE_REMOVE_DIALOG,
});

export const setShowRemoveFolderDialog = ({ show, folderId='' }) => ({
    type: actionTypes.ACTION_SET_SHOW_REMOVE_DIALOG,
    payload: {
        show,
        folderId,
    },
});

export const showRenameFolderDialog = folderId => ({
    type: actionTypes.ACTION_SHOW_RENAME_FOLDER_DIALOG,
    payload: folderId,
});

export const hideRenameDialog = () => ({
    type: actionTypes.ACTION_HIDE_RENAME_DIALOG,
});

export const setShowRenameFolderDialog = ({ show, folderId='' }) => ({
    type: actionTypes.ACTION_SET_SHOW_RENAME_DIALOG,
    payload: {
        show,
        folderId,
    },
});

export const showCreateFolderDialog = () => ({
    type: actionTypes.ACTION_SHOW_CREATE_FOLDER_DIALOG,
});

export const hideCreateFolderDialog = () => ({
    type: actionTypes.ACTION_HIDE_CREATE_FOLDER_DIALOG,
});

export const setShowCreateFolderDialog = show => ({
    type: actionTypes.ACTION_SET_SHOW_CREATE_FOLDER_DIALOG,
    payload: show,
});