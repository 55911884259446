import React, { useState } from 'react';
import TextField from '../../components/common/form/texField/textField';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import Interweave from 'interweave';

const ConfirmCodeForm = ({ email, authApi, t, classes }) => {

    const initialValues = { email: email, code: '' };

    const requiredTranslated = t('form:required');

    const SignUpSchema = Yup.object().shape({
        email: Yup.string()
          .required(requiredTranslated),
        code: Yup.string()
          .required(requiredTranslated)
    });

  const onSubmit = async (values, actions) => {
    const { success, errorMessage } = await authApi.confirmSignUp(values.email, values.code);
    actions.setSubmitting(false);
    if (success) {
      authApi.goToPage(`/signin/${values.email}`);
    } else {
      actions.setStatus({ msg: errorMessage });
    }
  };

  const [isResendingCode, setIsResendingCode] = useState(false);

  const resendCode = async () => {
    setIsResendingCode(true);
    await authApi.resendSignUp(email);
    setIsResendingCode(false);
  }

  return (
    <div className={ classes.container }>
      <Typography className={ classes.formTitleVerticalSpace } variant='subtitle1' align='center' color="textSecondary"><Interweave content={t('authentication:info_confirm_code')} /></Typography>
      <Formik
        initialValues={ initialValues }
        validationSchema={ SignUpSchema }
        onSubmit={ onSubmit }
        render={ ({ status, isSubmitting, isValid }) => (
          <Form>
            <Field name="email"
                   type="email"
                   required
                   component={ TextField }
                   label={ t('authentication:email') }
                   disabled={true}
                   disableUnderline={true} />
            <Field name="code"
                   type="text"
                   required
                   component={ TextField }
                   label={ t('authentication:confirmation_code') }/>
            <div className={ classes.formComponentVerticalSpace }>
                { status && status.msg && <FormHelperText error>{ t(status.msg) }</FormHelperText> }
            </div>
            <Button className={ classes.formComponentVerticalSpace } type="submit" color="primary" variant="contained" disabled={ isSubmitting || isResendingCode } fullWidth>
              {t('authentication:btn_confirm_code')}
            </Button>
          </Form>
        ) }
      />
      <div className={ classes.formLabelLinkContainer }>
          <Typography className={ classes.formLabelMarginRight } variant="body2" color="textSecondary">{t('authentication:resend_code_label')}</Typography>
          <Link
            component="button"
            variant="button"
            color="textPrimary"
            underline="always"
            onClick={resendCode}
            disabled={ isResendingCode }
          >
            {t('authentication:btn_resend_code')}
          </Link>
      </div>
    </div>
  );
};

export default ConfirmCodeForm;
