import React from 'react';
import materialStyles from './pageContainer.materialStyles';
import { withStyles } from '@material-ui/core/styles';

const PageContainer = ({ children, classes, theme='dark' }) => (
    <div className={classes.pageContainer}>
        <div className={theme === 'light' ? classes.pageContentContainerLight : classes.pageContentContainerDark}>
            {children}
        </div>
    </div>
);

export default withStyles(materialStyles)(PageContainer);