
import ViewingUserDashboard from './viewingUserDashboard';
import materialStyles from './viewingUserDashboard.materialStyles';
import { withStyles } from '@material-ui/core';
import { connect } from 'redhooks';
import { stopViewingUserDashboard } from '../../store/store.actions';
import { selectViewingUserDashboardName, selectViewingUserDashboardId } from '../../store/store.selectors';
import { navViewFolder } from '../../nav/nav.actions';
import { compose } from '../../utils/func';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
    userDashboardId: selectViewingUserDashboardId(state),
    userDisplayName: selectViewingUserDashboardName(state),
});

export default compose(
    withRouter,
    withStyles(materialStyles),
    connect(mapStateToProps, {
        stopViewingUserDashboard,
        navViewFolder,
    }),
)(ViewingUserDashboard)