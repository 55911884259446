import _ from 'lodash';
import { call, put, select, takeLatest, } from 'redux-saga/effects';
import { ACTION_ADD_FILE_TO_DASHBOARD, ACTION_SHOW_ADD_FILE_TO_DASHBOARD_DIALOG, ACTION_HIDE_ADD_FILE_TO_DASHBOARD_DIALOG } from './addFileToDashboardDialog.action.types';
import { hideAddFileToDashboardDialog, setShowAddFileToDashboardDialog } from './addFileToDashboardDialog.actions';
import { setShowActivityIndicator, showInfoSnackbar, } from '../../../../../store/store.actions';
import { selectUser } from '../../../../../store/store.selectors';
import { addFileToFolder } from '../../../../../api/folder/folder';
import { getError } from '../../../../../store/store.folder.sagas';
import i18next from 'i18next';
import { createSaga } from '../../../../../store/store.util.sagas';

export function* addFileToDashboardSaga({ payload: { fileAlias, successMessage } }) {
    yield put(hideAddFileToDashboardDialog());
    yield put(setShowActivityIndicator(true));
    const user = yield select(selectUser);
    const addsFolderId = _.get(user, 'systemFolders.adds.id');
    const result = yield call(addFileToFolder, { fileAlias: fileAlias, folderId: addsFolderId });
    if (result.success) {
        yield put(setShowActivityIndicator(false));
        yield put(showInfoSnackbar(successMessage));
    } else {
        yield put(setShowActivityIndicator(false));
        const fileIdErrorMessages = _.get(result, 'errorData.messages.file_id', []);
        const fileAlreadyAddedToFolder = _.some(fileIdErrorMessages, message => _.includes(message, 'already added to folder'));
        if (fileAlreadyAddedToFolder) {
            const folderName = _.get(result, 'errorData.messages.conflict_folder_name', '');
            const message = i18next.t('common:err_file_already_added_to_folder', {folderName: folderName})
            yield put(showInfoSnackbar(message));
        } else {
            throw getError(result);
        }
    }
}

export function* showAddFileToDashboardSaga() {
    yield put(setShowAddFileToDashboardDialog(true));
}

export function* hideAddFileToDashboardSaga() {
    yield put(setShowAddFileToDashboardDialog(false));
}

export default function* rootSaga() {
    yield takeLatest(ACTION_ADD_FILE_TO_DASHBOARD, createSaga(addFileToDashboardSaga));
    yield takeLatest(ACTION_SHOW_ADD_FILE_TO_DASHBOARD_DIALOG, createSaga(showAddFileToDashboardSaga));
    yield takeLatest(ACTION_HIDE_ADD_FILE_TO_DASHBOARD_DIALOG, createSaga(hideAddFileToDashboardSaga));
}
