import PropTypes from 'prop-types';
import React from 'react';
import materialStyles from '../form.materialStyles';
import { FormHelperText, FormControl, InputLabel, Select, withStyles } from '@material-ui/core';

export const SelectField = (props) => {
  const { label, options, disabled, form: { errors, touched, handleChange }, field, classes } = props;
  const showError = touched[field.name] && !!errors[field.name];

  return (
    <FormControl className={classes.formControl} fullWidth={true} error={showError} disabled={disabled}>
      {
        label ?
          (<InputLabel className={classes.formInputLabel}>{label}</InputLabel>) :
          null
      }
      <Select
            className={classes.input}
            classes={{ icon: classes.selectIcon }}
            native
            onChange={handleChange}
            inputProps={field}
          >
            <option key="None" label=""></option>
            {
              options.map(option => (
                <option key={option.value} label={option.label} className={classes.option}>{option.value}</option>
              ))
            }
      </Select>
      <FormHelperText className={classes.formHelperText}>{showError ? errors[field.name] : ''}</FormHelperText>
    </FormControl>
  );
};

SelectField.defaultProps = {
  disabled: false,
};

SelectField.propTypes = {
  label: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })),
  disabled: PropTypes.bool,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
  }),
  field: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default withStyles(materialStyles)(SelectField);