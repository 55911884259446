export default theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        maxWidth: '100vw',
        [theme.breakpoints.up('sm')]: {
            width: theme.breakpoints.values.sm,
        },
        backgroundColor: theme.palette.background.default,
    },
    header: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    noResults: {
        padding: theme.spacing.unit*4,
    },
    searchList: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    searchListItemIcon: {
        marginRight: theme.spacing.unit,
    },
    searchListItemText: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    searchListItemDivider: {
        marginRight: theme.spacing.unit*3,
        marginLeft: theme.spacing.unit*3,
    },
});