import _ from 'lodash';

const DEFAULT_MAX_AGE_MS = 5 * 60 * 1000; // 5 minutes
const DEFAULT_TIMEOUT_MS = 5 * 1000; // 5 seconds

export const LOCATION_ERROR = {
    NOT_SUPPORTED: 'NOT_SUPPORTED',
    UNKNOWN: 'UNKNOWN',
    PERMISSION_DENIED: 'PERMISSION_DENIED',
    POSITION_UNAVAILABLE: 'POSITION_UNAVAILABLE',
    TIMED_OUT: 'TIMED_OUT',  
};

/**
 * 
 * @param {Object} options  - geo location options used when calling navigator.geolocation.getCurrentPosition
 *                          options.maximumAge will default to DEFAULT_MAX_AGE_MS
 *                          options.timeout will default to DEFAULT_TIMEOUT_MS
 * @returns {Object} result
 *                          result.error if getting the user location failed, the error will be one of the errors specified in LOCATION_ERROR
 *                          result.latitude and result.longitude if getting the user location succeeded
 */
export const getUserLocation = async options => {
    if (navigator.geolocation) {
        return await new Promise(resolve => {

            const onSuccess = position => {
                if (_.hasIn(position, 'coords.latitude') && _.hasIn(position, 'coords.longitude')) {
                    resolve({
                        latitude: _.get(position, 'coords.latitude'),
                        longitude: _.get(position, 'coords.longitude'),
                    });
                } else {
                    resolve({
                        error: LOCATION_ERROR.POSITION_UNAVAILABLE
                    });
                }
            };

            const onError = error => {
                let locationError;
                switch(error.code) {
                    case 1:
                        locationError = LOCATION_ERROR.PERMISSION_DENIED;
                        break;
                    case 2:
                        locationError = LOCATION_ERROR.POSITION_UNAVAILABLE;
                        break;
                    case 3:
                        locationError = LOCATION_ERROR.TIMED_OUT;
                        break;
                    case 0:
                    default:
                        locationError = LOCATION_ERROR.UNKNOWN;
                        break;
                }
                resolve({
                    error: locationError,
                });
            };

            navigator.geolocation.getCurrentPosition(onSuccess, onError, {
                ...options,
                maximumAge: _.get(options, 'maximumAge', DEFAULT_MAX_AGE_MS),
                timeout: _.get(options, 'timeout', DEFAULT_TIMEOUT_MS),
            });
        });
    }
    else {
        return {
            error: LOCATION_ERROR.NOT_SUPPORTED,
        };
    }
}

