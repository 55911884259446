import { toolbarTextSizeREM, toolbarTextMaxLines, toolbarTextLineHeight } from "./constants";

const toolbarTextContainer = {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    fontSize: `${toolbarTextSizeREM}rem`,
};

export default theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.palette.secondary['800'],
        padding: theme.spacing.unit*1.5,
    },
    toolbarTextContainer: toolbarTextContainer,
    toolbarTextRightContainer: {
        ...toolbarTextContainer,
        justifyContent: 'flex-end',
    },
    toolbarText: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        WebkitLineClamp: toolbarTextMaxLines,
        WebkitBoxOrient: 'vertical',
        lineHeight: toolbarTextLineHeight,
        maxHeight: `${toolbarTextMaxLines*toolbarTextLineHeight}rem`,
    },
    divider: {
        width: 1,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        backgroundColor: theme.palette.common.white,
    }
});