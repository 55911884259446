import _ from 'lodash';
import React, { useState } from 'react';
import RemoveIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const CheckListQuestion = ({ placeholder, index, question, handleChange, showRemoveIcon, handleRemove, focus, disabled=false, t, classes, }) => (
    <FormControl className={classes.questionContainer} fullWidth={true}>
            <Input
                className={classes.inputThemeLight}
                classes={{
                    root: classes.inputThemeLightRoot,
                    disabled: classes.inputThemeLightDisabled,
                    underline: classes.inputThemeLightUnderline,
                }}
                color='textSecondary'
                type='text'
                disableUnderline={true}
                placeholder={placeholder ? placeholder : t('file:lbl_specify_question')}
                endAdornment = {
                    showRemoveIcon && !disabled ?
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={t('file:remove_question')}
                            onClick={() => handleRemove({ index })}
                        >
                            <div className={classes.removeIconContainer}>
                                <RemoveIcon color='inherit'/>
                            </div>
                        </IconButton>
                    </InputAdornment>
                    : null
                }
                value={question}
                onChange={e => handleChange({ index, newValue: e.target.value })}
                autoFocus={focus}
                disabled={disabled}
            />
    </FormControl>
)

const ChecklistQuestions = ({ field, disabled, t, classes }) => {

    const [state, setState] = useState({ pendingQuestion: '', questions: _.get(field, 'value', []), focusIndex: -1 });

    const updateFieldValue = updatedQuestions => {
        // Mock the input change event
        field && field.onChange && field.onChange({
            target: {
                name: field.name,
                value: updatedQuestions
            },
        });
    };

    const handleAddQuestion = () => {
        if (state.pendingQuestion === '') {
            return;
        }
        const updatedQuestions = _.concat(state.questions, state.pendingQuestion);
        setState({
            pendingQuestion: '',
            questions: updatedQuestions,
        });
        updateFieldValue(updatedQuestions);
    }

    const handleAddQuestionChange = ({ newValue }) => {
        setState({
            ...state,
            pendingQuestion: newValue,
            focusIndex: -1,
        });
    }

    const handleQuestionChange = ({ index, newValue }) => {
        const updatedQuestions = state.questions.slice(0, index).concat([newValue]).concat(state.questions.slice(index + 1));
        setState({
            ...state,
            questions: updatedQuestions,
            focusIndex: index,
        });
        updateFieldValue(updatedQuestions);
    }

    const handleRemoveQuestion = ({ index }) => {
        const updatedQuestions = _.filter(state.questions, (_, i) => i !== index);
        setState({
            ...state,
            questions: updatedQuestions,
        });
        updateFieldValue(updatedQuestions);
    }

    return (
        <>
            <div className={classes.questionsContainer}>
                {
                    state.questions.map((question, index) => (
                        <CheckListQuestion key={`${index}-${question}`}
                            index={index} question={question}
                            handleChange={handleQuestionChange}
                            showRemoveIcon={true}
                            handleRemove={handleRemoveQuestion}
                            focus={state.focusIndex === index}
                            disabled={disabled}
                            t={t}
                            classes={classes}/>    
                    ))
                }
            </div>
            { !disabled &&
                <div className={classes.addQuestionContainer}>
                    <div className={classes.addQuestionInputContainer}>
                        <CheckListQuestion placeholder={t('file:lbl_add_new_question')}
                            question={state.pendingQuestion}
                            handleChange={handleAddQuestionChange}
                            showRemoveIcon={false}
                            t={t}
                            classes={classes} />
                    </div>
                    <div className={classes.addQuestionFabContainer}>
                        <Fab className={classes.addQuestionFab} color='primary' onClick={handleAddQuestion} aria-label={t('file:fab_add_question')}>
                            <AddIcon fontSize='large' />
                        </Fab>
                    </div>
                </div>
            }
        </>
        
    );
}

export default ChecklistQuestions;