import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import materialStyles from './materialStyles';
import { withStyles } from '@material-ui/core/styles';
import { compose } from '../../../utils/func';
import { connect } from 'redhooks';
import { selectShowActivityIndicator } from '../../../store/store.selectors';

const ActivityIndicator = ({ isShowing=false, classes }) => (
    <Modal open={isShowing}>
        <div className={classes.container}>
            <CircularProgress color='primary' />
        </div>
    </Modal>
);

const mapStateToProps = state => ({
    isShowing: selectShowActivityIndicator(state),
});

export default compose(
    withStyles(materialStyles),
    connect(mapStateToProps),
)(ActivityIndicator);