export default theme => ({
    subtitle: {
        color: theme.palette.secondary.light,
        marginTop: 8,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    btnsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        minWidth: 280,
    },
    btnOk: {
        marginLeft: theme.spacing.unit*2,
    }
});