import FileContentToolbar from './fileContentToolbar';
import { withTranslation } from 'react-i18next';
import { compose } from '../../../../../utils/func';
import { connect } from 'redhooks';
import { navViewFolder } from '../../../../../nav/nav.actions';

export default compose(
    withTranslation(['file']),
    connect(null, {
      navViewFolder,
    }),
  )(FileContentToolbar);