import React from 'react';

const AuthenticationContext = React.createContext({});

/**
 * Adds Auth api and user data to the component
 * @param Component
 * @returns
 */
export const withAuthentication = Component => {
  return props => (<AuthenticationContext.Consumer>
    {
      authentication => (<Component authApi={ authentication.api }
                                    user={ authentication.user }
                                    isSignedIn={ authentication.isSignedIn }
                                    { ...props }/>)
    }
  </AuthenticationContext.Consumer>);
};

export default AuthenticationContext;