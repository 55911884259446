import { withAuthentication } from '../authenticationContext';
import SignInPage from './signInPage';
import { compose } from '../../utils/func';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import formMaterialStyles from '../../components/common/form/form.materialStyles';
import withGA from '../../utils/withGA'

export default compose(
  withStyles(formMaterialStyles),
  withAuthentication,
  withRouter,
  withGA,
)(SignInPage);