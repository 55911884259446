import { awsApiRequest } from '../../utils/func';

export const getUserList = async () => {
    try {
        return await awsApiRequest({
            method: 'GET',
            path: '/users'
        });
    } catch (error) {
        return error;
    } 
};

/**
 * @param {String} userId - the id of the user to be enabled
 */
export const enableUser = async ({userId}) => {
    try {
        return await awsApiRequest({
            method: 'PUT',
            path: `/users/${userId}/active-status`,
            params: {
                body: {
                    active: true,
                },
            },
        });
    } catch (error) {
        return error;
    }
};


/**
 * @param {String} userId - the id of the user to be disabled
 */
export const disableUser = async ({userId}) => {
    try {
        return await awsApiRequest({
            method: 'PUT',
            path: `/users/${userId}/active-status`,
            params: {
                body: {
                    active: false,
                },
            },
        });
    } catch (error) {
        return error;
    }
};