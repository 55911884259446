import { withTranslation } from 'react-i18next';
import AddToHomeScreenDialog from './AddToHomeScreenDialog';
import { compose } from '../../../../utils/func';
import { withStyles } from '@material-ui/core';
import materialStyles from './AddToHomeScreenDialog.materialStyles';


export default compose(
  withTranslation(['common', 'authentication']),
  withStyles(materialStyles),
)(AddToHomeScreenDialog);