import React, { useEffect } from 'react';
import PageContainer from '../../common/page-container';
import SearchForm from './search-form';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import SearchListItem from './search-list-item';
import { useTranslation } from 'react-i18next';
import SuspenseView from '../../suspense-view';

const Search = ({ setContextMenuItems, searchFiles, files, lastSearch, isInitialSearch, navViewFile, classes }) => {

    const { t, ready } = useTranslation('search');

    useEffect(() => {
        setContextMenuItems([]);
        searchFiles(lastSearch);
    }, []);

    const handleFileClick = (fileAlias, parentId) => {
        navViewFile({
            fileAlias: fileAlias,
            parentFolderId: parentId,
            parentFolderName: t('search:parent_folder'),
            fromSearchResults: true,
        });
    };

    const handleSearch = ({ name, description }) => {
        searchFiles({
            name,
            description,
        });
    };

    if (!ready) {
        return <SuspenseView />;
    }

    return (
        <PageContainer theme='light'>
            <div className={classes.container}>
                <Typography className={classes.header} variant='h6'>{t('search:header')}</Typography>
                <SearchForm onSubmitSearch={handleSearch} initialValues={lastSearch}/>
                { ((files.length === 0) && !isInitialSearch) &&
                    <Typography className={classes.noResults} color='textPrimary' align='center'>{t('search:no_files_found')}</Typography>
                }
                <List className={classes.searchList}>
                    {
                        files.map(file =>
                            <SearchListItem key={file.id}
                                alias={file.id}
                                parentId={file.parent_id}
                                name={file.name}
                                description={file.description}
                                onFileClick={handleFileClick}
                                classes={{
                                    listItemIcon: classes.searchListItemIcon,
                                    listItemText: classes.searchListItemText,
                                    divider: classes.searchListItemDivider,
                                }} />
                        )
                    }
                </List>
            </div>
        </PageContainer>
    );
};

export default Search;