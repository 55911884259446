import * as actionTypes from './drawer.action.types';

const initialState = {
    isOpen: false,
};

export const REDUCER_NAME = 'drawer';

const drawerReducer = (state=initialState, action) => {
    switch(action.type) {
        case actionTypes.ACTION_OPEN_DRAWER:
            return {
                ...state,
                isOpen: true,
            };
        case actionTypes.ACTION_CLOSE_DRAWER:
            return {
                ...state,
                isOpen: false,
            };
        case actionTypes.ACTION_TOGGLE_DRAWER:
            return {
                ...state,
                isOpen: !state.isOpen,
            };
        default:
            return state;
    }
};

export default drawerReducer;