const pageContentContainer = (theme, backgroundColor) => {
    return {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            width: theme.breakpoints.values.sm,
        },
        backgroundColor: backgroundColor,
    }
};

export default theme => ({
    pageContainer: {
        display: 'flex',
        flex: 1,
        margin: 0,
        justifyContent: 'center',
    },
    pageContentContainerLight: pageContentContainer(theme, theme.palette.secondary['50']),
    pageContentContainerDark: pageContentContainer(theme, theme.palette.background.surface),
});