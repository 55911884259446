export default theme => ({
    drawerPaper: {
        width: '85%',
        [theme.breakpoints.up('sm')]: {
            width: theme.breakpoints.values.sm,
        },
    },
    loaderContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    }
});