import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom';

const DrawerMenuList = ({ menuItems, classes }) => {
    return (
        <div className={classes.container}>
            <List>
                {menuItems.map(menuItem => (
                    <ListItem key={menuItem.text}
                              className={classes.listItem}
                              button
                              component={NavLink}
                              exact={menuItem.exact}
                              to={menuItem.path}
                              activeClassName={classes.listItemActive}
                              onClick={menuItem.handleClick}>
                        <ListItemIcon classes={{ root: classes.listItemIcon }}><menuItem.icon /></ListItemIcon>
                        <ListItemText classes={{ primary: classes.listItemText }} primary={menuItem.text} primaryTypographyProps={{ variant: 'body2' }} />
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

export default DrawerMenuList