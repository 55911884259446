import FileForm from './fileForm';
import newFileMaterialStyles from './fileForm.materialStyles';
import { selectIsAdminUser } from '../../../../store/store.selectors';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
import { compose } from '../../../../utils/func';
import { connect } from 'redhooks';
import { createFile, updateFile } from '../../../../store/store.actions';
import { navViewFile } from '../../../../nav/nav.actions';

const mapStateToProps = (state, props) => ({
  isAdmin: selectIsAdminUser(state),
});

export default compose(
  withRouter,
  withStyles(newFileMaterialStyles),
  connect(mapStateToProps, {
    createFile,
    updateFile,
    navViewFile,
  }),
)(FileForm);