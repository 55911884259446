import React from 'react';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';

const DownloadAttachmentIcon = ({ classes }) => {
    return (
        <div className={classes.container}>
            <AttachmentIcon className={classes.attachmentIcon} color='inherit' fontSize='large' />
            <div className={classes.arrowContainer}>
                <ArrowDownwardIcon color='inherit' fontSize='inherit' />
            </div>
        </div>
    );
};

const materialStyles = theme => ({
    container: {
        position: 'relative',
        color: theme.palette.secondary.main,
    },
    attachmentIcon: {
        transform: 'rotate(-45deg)',
    },
    arrowContainer: {
        position: 'absolute',
        bottom: 0,
        right: 2,
        backgroundColor: theme.palette.secondary.light,
        width: 20,
        height: 20,
        borderRadius: 10,
        fontSize: 18,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.common.white,
    },
});

export default withStyles(materialStyles)(DownloadAttachmentIcon);