import _ from 'lodash';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import ViewIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/ClearRounded';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';

const ViewingUserDashboard = ({ userDashboardId, userDisplayName, stopViewingUserDashboard, navViewFolder, history, classes, }) => {

    const { t, ready } = useTranslation('dashboard');
    if (!userDisplayName || _.get(history, 'location.pathname') === '/users') {
        return null;
    }

    const handleGoToUserDashboard = () => {
        navViewFolder({
            folderId: userDashboardId,
        });
    };

    return (
        <div className={classes.container}>
            { ready &&
                <>
                    <div className={classes.textContainer}>
                        <ViewIcon color='inherit' fontSize='small' />
                        <Typography className={classes.txtLeft} color='inherit' noWrap>
                            {t('dashboard:txt_viewing_user', { name: userDisplayName })}
                        </Typography>
                        <Link className={classes.linkDashboard} onClick={handleGoToUserDashboard} underline='always' color='inherit' component='button'>
                            <Typography>
                                {t('dashboard:txt_dashboard')}
                            </Typography>
                        </Link>
                    </div>
                    <IconButton className={classes.btn} onClick={stopViewingUserDashboard} aria-label={t('dashboard:btn_exit_view_other_user_dashboard')}>
                        <CloseIcon fontSize='small' />
                    </IconButton>
                </>
            }
        </div>
    );
};

export default ViewingUserDashboard;