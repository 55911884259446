import { REDUCER_NAME } from './dashboard.reducer';

export const selectFolderContentToolbar = state => state[REDUCER_NAME].folderContentToolbar;

export const selectErrorMessage = state => state[REDUCER_NAME].errorMessage;

export const selectContextMenu = state => state[REDUCER_NAME].contextMenu;

export const selectRemoveDialog = state => state[REDUCER_NAME].removeDialog;

export const selectRenameDialog = state => state[REDUCER_NAME].renameDialog;

export const selectShowCreateFolderDialog = state => state[REDUCER_NAME].showCreateFolderDialog;
