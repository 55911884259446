import AppDrawer from './drawer';
import materialStyles from './drawer.materialStyles';
import { withStyles } from '@material-ui/core';
import { compose } from '../../utils/func';
import { withAuthentication } from '../../auth/authenticationContext';
import { withRouter } from 'react-router';
import { connect } from 'redhooks';
import { selectIsOpen } from './drawer.selectors';
import { closeDrawer } from './drawer.actions';
import { selectUser } from '../../store/store.selectors';
import { navDashboard, navCreateFile, } from '../../nav/nav.actions';

const mapStateToProps = state => ({
    isOpen: selectIsOpen(state),
    user: selectUser(state),
});

export default compose(
    withStyles(materialStyles),
    withAuthentication,
    withRouter,
    connect(mapStateToProps, { closeDrawer, navDashboard, navCreateFile }),
)(AppDrawer);