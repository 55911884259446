import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography  from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const ClaimFileDialog = ({ isShowing, mode, isAdminUser, fileAlias, hideClaimUnclaimFileDialog, claimFile, unclaimFile}) => {

    const { t } = useTranslation(['common', 'file']);
    let title;
    let message;
    let btn;
    let handleActionClick;

    const folderName = isAdminUser ? t('file:folder_claims') : t('file:folder_owned');

    if (mode === 'claim') {
        title = t('file:dialog_title_claim_file');
        message = t('file:dialog_message_claim_file', { folderName });
        btn = t('file:btn_claim');
        handleActionClick = () => claimFile({ fileAlias: fileAlias, successMessage: t('file:msg_claim_file_success') });
    } else if (mode === 'unclaim') {
        title = t('file:dialog_title_unclaim_file');
        message = t('file:dialog_message_unclaim_file', { folderName });
        btn = t('file:btn_unclaim');
        handleActionClick = () => unclaimFile({ fileAlias: fileAlias, successMessage: t('file:msg_unclaim_file_success') });
    } else {
        return null;
    }

    return (
        <Dialog open={isShowing}
                onClose={hideClaimUnclaimFileDialog}
                aria-labelledby='claim-file-dialog-title'
                aria-describedby='claim-file-dialog-description'
                maxWidth='xs'>
                <DialogTitle id='claim-file-dialog-title' disableTypography={true}>
                <Typography variant='h6' color='inherit'>{ title }</Typography>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id='claim-file-dialog-description'>
                    { message }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hideClaimUnclaimFileDialog} color='primary' autoFocus>
                    {t('common:cancel')}
                </Button>
                <Button onClick={handleActionClick} color='primary'>
                    { btn }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ClaimFileDialog;