// From https://github.com/react-ga/react-ga/blob/master/demo/app/withTracker.jsx

import React, { Component } from 'react';
import ReactGA from 'react-ga';

export default function withGA(WrappedComponent, options = {}) {

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      ReactGA.pageview(page);
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        ReactGA.pageview(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}