export default theme => ({
  dialogTitle: {
    textAlign: 'center',
  },
  dialogContent: {
    textAlign: 'center',
  },
  dialogAppLogoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dialogAppLogo: {
    width: 80,
    height: 80,
    marginBottom: theme.spacing.unit*2,
    alignSelf: 'center',
  },
  dialogFootnote: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: theme.overrides.MuiDialogContentText.root.color,
    padding: theme.spacing.unit,
    marginTop: theme.spacing.unit*2,
    fontSize: '0.84rem',
  },
  shareIcon: {
    width: 24,
    height: 24,
    marginLeft: theme.spacing.unit/4,
    marginRight: theme.spacing.unit/4,
  }
});
