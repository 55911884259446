import _ from 'lodash';
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import CreateFileIcon from '@material-ui/icons/NoteAdd';
import LegalIcon from '@material-ui/icons/Info';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SignInIcon from '@material-ui/icons/Person';
import UsersIcon from '@material-ui/icons/PeopleOutlined';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import DrawerHeader from './drawerHeader';
import DrawerMenuList from './drawerMenuList';
import SuspenseView from '../suspense-view';
import { useTranslation } from 'react-i18next';

const AppDrawer = ({ authApi, user, isOpen, closeDrawer, navDashboard, navCreateFile, classes, children, }) => {

    const { t, ready } = useTranslation('drawer');

    const handleSignOut = async () => {
        await authApi.signOut();
    }

    const getMenuItems = () => {

        const items = [];

        // Add 'Login / Register' if the user is signed out
        if (!user) {
            items.push({
                text: t('drawer:menu_item_sign_in'),
                icon: SignInIcon,
                path: '/signin',
                exact: false,
                handleClick: closeDrawer,
            });
        }

        // Add 'Dashboard' if the user is signed in
        if (user) {
            items.push({
                text: t('drawer:menu_item_dashboard'),
                icon: DashboardIcon,
                path: '/dashboard',
                exact: false,
                handleClick: e => {
                    e.preventDefault();
                    closeDrawer();
                    navDashboard();
                },
            });
        }

        // Add 'New File' if the user is signed in
        items.push({
            text: t('drawer:menu_item_new_file'),
            icon: CreateFileIcon,
            path: '/file',
            exact: true,
            handleClick: e => {
                e.preventDefault();
                closeDrawer();
                navCreateFile();
            },
        });

        if (user.isAdmin) {
            items.push({
                text: t('drawer:menu_item_users'),
                icon: UsersIcon,
                path: '/users',
                exact: true,
                handleClick: closeDrawer,
            });
        }

        // Add the 'Legal Information' option if the user is signed in
        if (user) {
            items.push({
                text: t('drawer:menu_item_legal_information'),
                icon: LegalIcon,
                path: '/legal',
                exact: true,
                handleClick: e => {
                    closeDrawer();
                },
            });
        }

        // Add 'Sign Out' if the user is signed in
        if (user) {
            items.push({
                text: t('drawer:menu_item_sign_out'),
                icon: SignOutIcon,
                path: '/signin',
                exact: false,
                handleClick: e => {
                    e.preventDefault();
                    closeDrawer();
                    handleSignOut();
                },
            });
        }

        return items;
    }

    const menuItems = getMenuItems();

    const showLoader = ((user === null) || !ready);

    return (
        <>
            <Drawer classes={{ paper: classes.drawerPaper }} anchor="left" open={isOpen} onClose={closeDrawer}>
                { showLoader &&
                    <SuspenseView />
                }
                { !showLoader &&
                    <>
                        <DrawerHeader name={_.get(user, 'displayName', '')} email={_.get(user, 'email', '')} />
                        <DrawerMenuList menuItems={menuItems} />
                    </>
                }
            </Drawer>
            {children}
        </>
    );
}

export default AppDrawer;