import * as actionTypes from './addFileToDashboardDialog.action.types';

const initialState = {
    isShowing: false,
};

export const REDUCER_NAME = 'addFileToDashboardDialog';

const addFileToDashboardDialogReducer = (state=initialState, action) => {
    switch(action.type) {
        case actionTypes.ACTION_SET_SHOW_ADD_FILE_TO_DASHBOARD_DIALOG:
            return {
                isShowing: action.payload,
            };
        default:
            return state;
    }
};

export default addFileToDashboardDialogReducer;