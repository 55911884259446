import { createStore } from 'redhooks';
import createSagaMiddleware from "redux-saga";

import { user, main, infoSnackbar, activityIndicator, headerContextMenu, sessionExpiredDialog, } from './store.reducer';
import drawer, { REDUCER_NAME as DRAWER_REDUCER } from '../components/drawer/drawer.reducer';
import dashboard, { REDUCER_NAME as DASHBOARD_REDUCER } from '../components/pages/dashboard/dashboard.reducer';
import claimUnclaimFileDialog, { REDUCER_NAME as CLAIM_UNCLAIM_FILE_DIALOG_REDUCER } from '../components/pages/file/view-file/claim-unclaim-file-dialog/claimUnclaimFileDialog.reducer';
import enableDisableUserDialog, { REDUCER_NAME as ENABLE_DISABLE_USER_DIALOG_REDUCER } from '../components/pages/users/user-list/enableDisableUserDialog.reducer'
import addFileToDashboardDialog, { REDUCER_NAME as ADD_FILE_TO_DASHBOARD_DIALOG_REDUCER } from '../components/pages/file/view-file/add-file-to-dashboard-dialog/addFileToDashboardDialog.reducer';
import locationExplanationDialog, { REDUCER_NAME as LOCATION_EXPLANATION_DIALOG_REDUCER } from '../components/pages/file/view-file/location-explanation-dialog/locationExplanationDialog.reducer';
import searchFiles, { REDUCER_NAME as SEARCH_FILES_REDUCER } from '../components/pages/search/search.reducer';

import navSaga from "../nav/nav.sagas";
import userSaga from "./store.user.sagas";
import folderSaga from "./store.folder.sagas";
import fileSaga from "./store.file.sagas";
import dashboardSaga from '../components/pages/dashboard/dashboard.sagas';
import searchFilesSaga from '../components/pages/search/search.sagas';
import claimFileSaga from '../components/pages/file/view-file/claim-unclaim-file-dialog/claimUnclaimFileDialog.sagas';
import addFileToDashboardSaga from '../components/pages/file/view-file/add-file-to-dashboard-dialog/addFileToDashboardDialog.sagas';
import enableDisableUserDialogSaga from '../components/pages/users/user-list/userList.sagas'

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store = createStore({
    user,
    main, 
    infoSnackbar,
    activityIndicator,
    headerContextMenu,
    sessionExpiredDialog,
    [DRAWER_REDUCER]: drawer,
    [DASHBOARD_REDUCER]: dashboard,
    [SEARCH_FILES_REDUCER]: searchFiles,
    [CLAIM_UNCLAIM_FILE_DIALOG_REDUCER]: claimUnclaimFileDialog,
    [ENABLE_DISABLE_USER_DIALOG_REDUCER]: enableDisableUserDialog,
    [ADD_FILE_TO_DASHBOARD_DIALOG_REDUCER]: addFileToDashboardDialog,
    [LOCATION_EXPLANATION_DIALOG_REDUCER]: locationExplanationDialog,
}, {
    middlewares
});

store.onload = () => {
    sagaMiddleware.run(userSaga);
    sagaMiddleware.run(folderSaga);
    sagaMiddleware.run(fileSaga);
    sagaMiddleware.run(dashboardSaga);
    sagaMiddleware.run(claimFileSaga);
    sagaMiddleware.run(enableDisableUserDialogSaga)
    sagaMiddleware.run(addFileToDashboardSaga);
    sagaMiddleware.run(navSaga);
    sagaMiddleware.run(searchFilesSaga);
};

export default store;