import React, { useEffect } from 'react';
import FileForm from '../file-form';
import { setContextMenuItems } from '../../../../store/store.actions';
import { connect } from 'redhooks';

const NewFile = ({ setContextMenuItems }) => {

  useEffect(() => {
    setContextMenuItems([]);
  });

  return (
    <FileForm mode='create' />
  );
};


export default connect(null, { setContextMenuItems })(NewFile);