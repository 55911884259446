import _ from 'lodash';
import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { MAX_NUM_SELECTED_FILES, SELECT_FILES_MODE_MOVE, } from '../../../../store/constants';

export const SelectFilesSnackbar = ({ isOpen, selectFilesMode, currentFolderId, disabledMoveToFolderIds, completeSelectFiles, cancelSelectFiles, numFilesSelected, isMaxSelectedFilesReached, showActionFailedErrorMessage }) => {

    const { t } = useTranslation('common');

    const handleDone = () => {
        completeSelectFiles({
            newFolderId: currentFolderId
        });
    };

    let message;
    let completeActionBtnTxt;
    let disableCompleteActionBtn = false;
    if (selectFilesMode === SELECT_FILES_MODE_MOVE) {
        message = showActionFailedErrorMessage ? t('common:snackbar_move_file_in_progress_failed') : t('common:snackbar_move_file_in_progress');
        completeActionBtnTxt = t('common:btn_move');
        disableCompleteActionBtn = (_.includes(disabledMoveToFolderIds, currentFolderId) || (numFilesSelected === 0));
    } else {
        message = t('common:snackbar_remove_file_in_progress');
        completeActionBtnTxt = t('common:btn_remove_items');
    }

    if (!isOpen) {
        return null;
    }

    return (    
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open={isOpen}
            ContentProps={{
                'aria-describedby': 'system-snackbar-select-files',
            }}
            message={
                <Typography id='system-snackbar-select-files' color='inherit' variant='caption' style={{ display: 'flex', flexDirection: 'column' }} component='div'>
                    { message }
                    <Typography color={isMaxSelectedFilesReached ? 'primary' : 'default'} variant='caption'>
                        {t('common:num_files_selected', { numFilesSelected: numFilesSelected, max: MAX_NUM_SELECTED_FILES })}
                    </Typography>
                </Typography>
            }
            action={[
                <Button key='cancel_select_file' size='small' onClick={cancelSelectFiles}>
                    {t('common:btn_cancel')}
                </Button>,
                <Button key='complete_select_file' color='primary' size='small' onClick={handleDone} disabled={disableCompleteActionBtn}>
                    {completeActionBtnTxt}
                </Button>,
            ]}
        />
    );
}

export default SelectFilesSnackbar;