import * as actionTypes from './search.action.types';

export const REDUCER_NAME = 'searchFiles';

const searchInitialState = {
    lastSearch: {
        name: '',
        description: '',
    },
    isInitialSearch: true,
    files: [],
};

export const searchReducer = (state=searchInitialState, { type, payload }) => {
    switch(type) {
        case actionTypes.ACTION_SEARCH_FILES:
            return {
                ...state,
                lastSearch: {
                    name: payload.name,
                    description: payload.description,
                },
            };
        case actionTypes.ACTION_SET_SEARCH_FILES_RESULT:
            return {
                ...state,
                files: payload,
                isInitialSearch: false,
            };
        default:
            return state;
    }
};

export default searchReducer;