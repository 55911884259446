import _ from 'lodash';
import moment from 'moment';
import { call, put, } from 'redux-saga/effects';
import { setShowActivityIndicator, showInfoSnackbar, } from './store.actions';
import { Storage } from 'aws-amplify';
import { isIOS } from 'react-device-detect';

/**
 * 
 * Uploads the file to the S3 bucket
 * 
 * @param {File} file - the file that needs to be uploaded to the S3 bucket
 * @param {String} accessLevel - allowed access levels: public, protected and private (as specified in the Storage module) - defaults to public
 * @param {String} customPath - custom path for specified access level
 * 
 * @returns {Object} the Storage.put response: will have the s3 object 'key' returned if the request was successful
 */
export function* uploadFileSaga({ file, accessLevel='public', customPath, }) {
    try {
        // File name format: <epoch timestamp in ms>-<file name>
        const fileName = `${moment().valueOf()}${_.hasIn(file, 'name') ? '-' : ''}${_.get(file, 'name', '')}`;

        // Config Notes:
        // - access level: public otherwise we need to have a reference the user's identity id as well (To limit access: we set the expire option when generating the presigned url - see downloadFileSaga)
        // - contentDisposition: setting this header lets the browser know to download and not open the file: https://github.com/eligrey/FileSaver.js/wiki/Saving-a-remote-file#using-http-header
        const config = {
            level: accessLevel,
            contentDisposition: `attachment; filename="${fileName}" filename*="${fileName}"`,
        };

        if (customPath) {
            config.customPrefix = {
                [accessLevel]: customPath,
            };
        }

        if (file.type) {
            config.contentType = file.type;
        }

        return yield Storage.put(fileName, file, config);
    } catch(error) {
        return error;
    }
}

const DOWNLOAD_LINK_EXPIRES_SECONDS = 1 * 60 * 60; // 1 hour

/**
 * 
 * Downloads the file linked to the s3Key
 * 
 * @param {String} s3Key - the s3 key for the storage object
 * @param {String} accessLevel - allowed access levels: public, protected and private (as specified in the Storage module - Note: important to be the same as when file was uploaded) 
 * @param {String} customPath - custom path for specified access level - Note: important to be the same as when file was uploaded
 */
export function* downloadFileSaga({ s3Key, accessLevel, customPath }) {
    try {
        yield put(setShowActivityIndicator(true));
        // First get a download url from S3
        const config = {
            level: accessLevel,
            expires: DOWNLOAD_LINK_EXPIRES_SECONDS,
        };

        if (customPath) {
            config.customPrefix = {
                [accessLevel]: customPath,
            };
        }

        const url = yield call(Storage.get, s3Key, config);
        yield put(setShowActivityIndicator(false));

        if (!(url && _.isString(url) && url.startsWith('https://'))) {
            const error = {
                errorMessage: 'common:err_failed_to_get_download_url',
            };
            throw error;
        }

        // Use the s3 presigned url to download the file
        let target;
        if (isIOS) {
            // Note: Mobile Safari/Chrome browsers does not support file download, workaround:
            // We fallback to opening the link in a new tab, from where the user can use the native share button and use the 'Save file' option
            target = '_blank';
        } else {
            target = '_top';
        }
        // Let the browser do the download url interception (it will see the Content-Disposition attachment header and know to download the file - if possible - instead of opening/showing the link)
        window.open(url, target);
    } catch(error) {
        yield put(setShowActivityIndicator(false));
        yield put(showInfoSnackbar(_.get(error, 'errorMessage', 'common:err_default')));
    }
}

/**
 * 
 * Removes the file from S3 Storage
 * 
 * @param {String} s3Key - the s3 key for the storage object
 * @param {String} accessLevel - allowed access levels: public, protected and private (as specified in the Storage module - Note: important to be the same as when file was uploaded) 
 * @param {String} customPath - custom path for specified access level - Note: important to be the same as when file was uploaded
 */
export function* removeFileSaga({ s3Key, accessLevel, customPath }) {
    try {
        const config = {
            level: accessLevel,
        };

        if (customPath) {
            config.customPrefix = {
                [accessLevel]: customPath,
            };
        }

        yield call(Storage.remove, s3Key, config);

    } catch(error) {
        console.log('Failed to remove file from S3 Storage with error: ', error);
        throw error;
    }
}