export const SYSTEM_FOLDER_LOOKUP_NAMES = {
    dashboard: 'dashboard',
    adds: 'adds',
    claims: 'claims',
    unclaimed: 'unclaimed',
    builds: 'builds',
    owned: 'owned',
    templates: 'templates',
    problems: 'problems',
};

export const BATCH_ACTION_TYPE = {
    MOVE_FILES: 1,
    REMOVE_FILES: 2,
};