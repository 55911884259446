import { call, put, select, takeLatest, } from 'redux-saga/effects';
import { claimFile, unclaimFile } from '../../../../../api/file';
import { ACTION_CLAIM_FILE, ACTION_UNCLAIM_FILE, } from './claimUnclaimFileDialog.action.types';
import { hideClaimUnclaimFileDialog, } from './claimUnclaimFileDialog.actions';
import { setShowActivityIndicator, setViewFile, showInfoSnackbar } from '../../../../../store/store.actions';
import { selectUser } from '../../../../../store/store.selectors';
import { createSaga } from '../../../../../store/store.util.sagas';
import { getError } from '../../../../../store/store.file.sagas';

export function* claimFileSaga({ payload: { fileAlias, successMessage } }) {
    yield put(hideClaimUnclaimFileDialog());
    yield put(setShowActivityIndicator(true));
    const user = yield select(selectUser);
    const result = yield call(claimFile, { fileAlias: fileAlias, userId: user.userId });
    if (result.success) {
        yield put(setViewFile(result.data));
        yield put(setShowActivityIndicator(false));
        yield put(showInfoSnackbar(successMessage));
    } else {
        throw getError(result);
    }
}

export function* unclaimFileSaga({ payload: { fileAlias, successMessage } }) {
    yield put(hideClaimUnclaimFileDialog());
    yield put(setShowActivityIndicator(true));
    const user = yield select(selectUser);
    const result = yield call(unclaimFile, { fileAlias: fileAlias, userId: user.userId });
    if (result.success) {
        yield put(setViewFile(result.data));
        yield put(setShowActivityIndicator(false));
        yield put(showInfoSnackbar(successMessage));
    } else {
        throw getError(result);
    }
}

export default function* rootSaga() {
    yield takeLatest(ACTION_CLAIM_FILE, createSaga(claimFileSaga));
    yield takeLatest(ACTION_UNCLAIM_FILE, createSaga(unclaimFileSaga));
}