import _ from 'lodash';
import ContextMenu from './contextMenu';
import { withTranslation } from 'react-i18next';
import { selectUser, selectDisabledFolderActionFolderIds } from '../../../../store/store.selectors';
import { connect } from 'redhooks';
import { compose } from '../../../../utils/func';

const mapStateToProps = (state, props) => ({
    user: selectUser(state),
    hideDeleteOption: _.includes(selectDisabledFolderActionFolderIds(state), props.currentFolderId),
});

export default compose(
    withTranslation('dashboard'),
    connect(mapStateToProps),
)(ContextMenu);