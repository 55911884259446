import _ from 'lodash';
import React, { useEffect } from 'react';
import FileLabelText from '../file-label-text';
import Ratings from '../../../../file/ratings';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import DownloadAttachmentIcon from '../download-attachment-icon';
import Typography from '@material-ui/core/Typography';
import { getDisplayTimestamp, getGoogleMapsUrl, getDisplayCoordinate, } from '../util';

const EntryView = ({ entry, viewFileEntry, classes, t, handleViewAllEntries, downloadFileEntryAttachment, match }) => {

    const fileAlias = _.get(match, 'params.fileAlias', '');
    const entryId = _.get(match, 'params.entryId', '');
    const shouldLoadEntry = _.isEmpty(entry) || (_.get(entry, 'entry_id') !== entryId);

    useEffect(() => {
        if (shouldLoadEntry) {
            viewFileEntry({
                fileAlias: fileAlias,
                entryId: entryId,
            });
        }
    }, []);

    const handleDownloadAttachment = e => {
        downloadFileEntryAttachment({
            attachmentKey: entry.attachment_key
        });
    };

    if (shouldLoadEntry) {
        return null;
    }
    
    let googleMapsUrl;
    if (_.has(entry, 'location.latitude') && _.has(entry, 'location.longitude')) {
        googleMapsUrl = getGoogleMapsUrl(_.get(entry, 'location.latitude'), _.get(entry, 'location.longitude'));
    }

    return (
        <>

            <div className={classes.container}>
                <div className={classes.entryHeaderContainer}>
                    <Typography className={classes.title} color='primary' variant='h6'>{t('file:header_section_entry')}</Typography>
                    <Link component='button' color='primary' underline='always' onClick={handleViewAllEntries}>{t('file:btn_view_all_file_entries')}</Link>
                </div>
                <div className={classes.entryContainer}>
                    <Typography className={classes.entryInfoBold} component='div'>{t('file:lbl_created_by')}<Typography className={classes.entryInfoNormal}>{entry.username}</Typography></Typography>
                    <Typography className={classes.entryInfoBold}>{getDisplayTimestamp(entry.timestamp)}</Typography>
                    { !!googleMapsUrl &&
                        <div className={classes.entryLocationContainer}>
                            <Typography className={classes.entryInfoBold} component='div'>{t('file:lbl_lat')}<Typography className={classes.entryInfoNormal}>{getDisplayCoordinate(entry.location.latitude)}</Typography></Typography>
                            <Typography className={classes.entryInfoBold} component='div'>{t('file:lbl_long')}<Typography className={classes.entryInfoNormal}>{getDisplayCoordinate(entry.location.longitude)}</Typography></Typography>
                            <div>
                                (<Link color='primary' target='_blank' rel='noreferrer' href={googleMapsUrl} underline='always'>{t('file:btn_map')}</Link>)
                            </div>
                        </div>
                    }
                    { !!_.get(entry, 'attachment_key') &&
                        <IconButton className={classes.attachmentBtn} color='secondary' aria-label={t('file:btn_download_attachment')} onClick={handleDownloadAttachment}>
                            <DownloadAttachmentIcon />
                        </IconButton>
                    }
                </div>
                { (_.get(entry, 'ratings.length', 0) > 0) &&
                    <div className={classes.entryRatingsContainer}>
                        <Typography className={classes.title} color='primary' variant='h6'>{t('file:header_section_ratings')}</Typography>
                        <Ratings field={{ value: entry.ratings }} disabled={true} />
                    </div>
                }
            </div>
            { !!entry.comment && <FileLabelText label={t('file:header_section_comment')} text={entry.comment} /> }
        </>
    );
};

export default EntryView;