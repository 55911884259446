import { withRouter } from 'react-router';
import Header from './header';
import { compose } from '../../utils/func';
import { connect } from 'redhooks';
import { toggleDrawer } from '../drawer/drawer.actions';
import { navSearch, navDashboard } from '../../nav/nav.actions';
import materialStyles from './header.materialStyles';
import { withStyles } from '@material-ui/core';

export default compose(
  withRouter,
  withStyles(materialStyles),
  connect(null, { toggleDrawer, navSearch, navDashboard, }),
)(Header);