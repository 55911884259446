import React from 'react';
import TextField from '../../components/common/form/texField/textField';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Typography } from '@material-ui/core';
import Interweave from 'interweave';
import { PASSWORD_FORMAT_REGEX, PASSWORD_FORMAT_CONFIG, PASSWORD_FORMAT_MAX_CHARS } from '../constants';

const ConfirmResetPasswordForm = ({ email, authApi, t, classes }) => {

    const initialValues = { email: email, code: '', password: '', confirmPassword: '' };
    const passwordFormatTranslated = t('form:passwordFormat', PASSWORD_FORMAT_CONFIG);
    const requiredTranslated = t('form:required');

    const SignUpSchema = Yup.object().shape({
        email: Yup.string()
          .required(requiredTranslated),
        code: Yup.string()
          .required(requiredTranslated),
        password: Yup.string()
            .required(requiredTranslated)
            .matches(PASSWORD_FORMAT_REGEX, passwordFormatTranslated),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], t('form:passwordsDoNotMatch'))
            .required(requiredTranslated)
    });

  const onSubmit = async (values, actions) => {
    const { success, errorMessage } = await authApi.confirmResetPassword(values.email, values.code, values.password);
    actions.setSubmitting(false);
    if (success) {
      authApi.goToPage(`/signin/${values.email}`);
    } else {
      actions.setStatus({ msg: errorMessage });
    }
  };

  return (
    <div className={ classes.container }>
      <Typography className={ classes.formTitleVerticalSpace } variant='subtitle1' align='center' color="textSecondary"><Interweave content={t('authentication:info_confirm_password_reset')} /></Typography>
      <Formik
        initialValues={ initialValues }
        validationSchema={ SignUpSchema }
        onSubmit={ onSubmit }
        render={ ({ status, isSubmitting, isValid }) => (
          <Form  className={ classes.formFieldsContainer }>
            <Field name="email"
                   type="email"
                   required
                   component={ TextField }
                   label={ t('authentication:email') }
                   disabled={true}
                   disableUnderline={true} />
            <Field name="code"
                   type="text"
                   required
                   component={ TextField }
                   label={ t('authentication:confirmation_code') }/>
            <Field name="password"
                   type="password"
                   required
                   component={ TextField }
                   label={ t('authentication:new_password') }
                   maxLength={PASSWORD_FORMAT_MAX_CHARS} />
            <Field name="confirmPassword"
                   type="password"
                   component={ TextField }
                   label={ t('authentication:repeat_new_password') }
                   maxLength={PASSWORD_FORMAT_MAX_CHARS} />
            <div className={ classes.formComponentVerticalSpace }>
                { status && status.msg && <FormHelperText error>{ t(status.msg) }</FormHelperText> }
            </div>
            <Button className={ classes.formComponentVerticalSpace } type="submit" color="primary" variant="contained" disabled={ isSubmitting } fullWidth>
              {t('authentication:btn_confirm_password_reset')}
            </Button>
          </Form>
        ) }
      />
    </div>
  );
};

export default ConfirmResetPasswordForm;
