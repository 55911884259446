import _ from 'lodash';
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FailIcon from '@material-ui/icons/Cancel';
import CautionIcon from '@material-ui/icons/Warning';
import PassIcon from '@material-ui/icons/CheckCircle';
import { RATINGS } from '../../../api/file/constants';

const Rating = ({ index, rating, question, handleRatingChange, disabled=false, t, classes, }) => {

    const handleSelectFail = () => {
        handleRatingChange({ index: index, rating: RATINGS.FAIL });
    };

    const handleSelectCaution = () => {
        handleRatingChange({ index: index, rating: RATINGS.CAUTION });
    };

    const handleSelectPass = () => {
        handleRatingChange({ index: index, rating: RATINGS.PASS });
    };

    return (
        <div className={classes.ratingContainer}>
            <Typography className={classes.ratingQuestion} color='inherit' fontSize='inherit' variant='inherit'>{question}</Typography>
            <div className={classes.ratingButtonsContainer}>
                <IconButton
                    classes={{ label: classes.ratingBtnLbl }}
                    aria-label={t('file:rating_fail')}
                    onClick={handleSelectFail}
                    disabled={disabled}
                >
                    <div className={rating === RATINGS.FAIL ? classes.failIconContainer : classes.defaultIconContainer}>
                        <FailIcon color='inherit' fontSize='inherit' />
                    </div>
                    <Typography className={rating === RATINGS.FAIL ? classes.selectedText : classes.defaultText} color='inherit'>{ t('file:btn_rating_fail') }</Typography>
                </IconButton>
                <IconButton
                    classes={{ label: classes.ratingBtnLbl }}
                    aria-label={t('file:rating_caution')}
                    onClick={handleSelectCaution}
                    disabled={disabled}
                >
                    <div className={rating === RATINGS.CAUTION ? classes.cautionIconContainer : classes.defaultIconContainer}>
                        <CautionIcon color='inherit' fontSize='inherit' />
                    </div>
                    <Typography className={rating === RATINGS.CAUTION ? classes.selectedText : classes.defaultText} color='inherit'>{ t('file:btn_rating_caution') }</Typography>
                </IconButton>
                <IconButton
                    classes={{ label: classes.ratingBtnLbl }}
                    aria-label={t('file:rating_pass')}
                    onClick={handleSelectPass}
                    disabled={disabled}
                >
                    <div className={rating === RATINGS.PASS ? classes.passIconContainer : classes.defaultIconContainer}>
                        <PassIcon color='inherit' fontSize='inherit' />
                    </div>
                    <Typography className={rating === RATINGS.PASS ? classes.selectedText : classes.defaultText} color='inherit'>{ t('file:btn_rating_pass') }</Typography>
                </IconButton>
            </div>
        </div>
    );
};

const Ratings = ({ field, disabled, t, classes }) => {

    const [state, setState] = useState({ ratings: _.get(field, 'value', []) });

    const updateFieldValue = updatedRatings => {
        // Mock the input change event
        field && field.onChange && field.onChange({
            target: {
                name: field.name,
                value: updatedRatings
            },
        });
    };

    const handleRatingChange = ({ index, rating }) => {

        if (index < 0 || index >= state.ratings.length) return;

        const updatedRatings = state.ratings
            .slice(0, index)
            .concat([{
                ...state.ratings[index],
                rating: rating
            }])
            .concat(state.ratings.slice(index + 1));

        setState({
            ratings: updatedRatings,
        });

        updateFieldValue(updatedRatings);
    }

    return (
        <div className={classes.ratingsContainer}>
            {
                state.ratings.map((rating, index) => (
                    <Rating key={`${rating.question}`}
                        index={index}
                        rating={rating.rating}
                        question={rating.question}
                        handleRatingChange={handleRatingChange}
                        disabled={disabled}
                        t={t}
                        classes={classes} />    
                ))
            }
        </div>
    );
}

export default Ratings;