export default theme => ({
    iconContainer: {
        position: 'relative',
    },
    indicator: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: theme.palette.common.ratingPass,
    },
});