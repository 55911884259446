export default theme => ({
    container: {
        backgroundColor: theme.palette.surface,
        padding: theme.spacing.unit*2,
    },
    listItem: {
        color: theme.palette.common.black,
        borderRadius: theme.shape.borderRadius,
    },
    listItemActive: {
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(244,67,54, 0.14)', // 14% opacity of the primary.main color
    },
    listItemIcon: {
        color: 'inherit',
    },
    listItemText: {
        color: 'inherit',
    },
})