import _ from 'lodash';
import React, { useEffect } from 'react';
import FileForm from '../file-form';
import { withRouter } from 'react-router';
import { selectEditFile, } from '../../../../store/store.selectors';
import { editFile, } from '../../../../store/store.actions';
import { connect } from 'redhooks';
import { compose } from '../../../../utils/func';
import withGA from '../../../../utils/withGA';

const EditFile = ({ file, editFile, match }) => {

  const fileAlias = _.get(match, 'params.fileAlias');
  const shouldLoadFileData = _.isEmpty(file) || (file.alias !== fileAlias);
  useEffect(() => {
    if (shouldLoadFileData) {
      editFile({ fileAlias });
    }
  }, []);

  if (shouldLoadFileData) {
    // Don't load/render the form while the file data is being loaded
    return null;
  }

  return (
    <FileForm mode='edit' editFileData={file} />
  );
};

const mapStateToProps = state => ({
  file: selectEditFile(state),
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    editFile,
  }),
  withGA,
)(EditFile);