import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Link from '@material-ui/core/Link';
import {COOKIE_NAME, COOKIE_VALUE, COOKIE_EXPIRY_DATE} from './constants';
import Cookies from "js-cookie";
import Typography  from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';

const CookieDisclaimer = ({ history }) => {
    
    const { t, ready } = useTranslation('legal');
    const [visible, setVisible] = useState(Cookies.get(COOKIE_NAME) === undefined);

    const accept = () => {

        Cookies.set(COOKIE_NAME, COOKIE_VALUE, 
            COOKIE_EXPIRY_DATE
        );
        setVisible(false);
      };

    const goToLegalInfo = () => {
      history.push('/legal');
    }

    const isLegalPage = _.get(history, 'location.pathname', '').startsWith('/legal');

    if (!ready || isLegalPage) {
        return null;
    } else { 
        return (
          <div>        
            <Snackbar id="snackBar"
              open={visible}
              message={ 
                <div>
                <Typography  color='inherit'> {t('legal:info_cookie_disclaimer')} </Typography>
                <br/>
                    <Link style={{float:"right"}}
                      color='primary'
                      underline='hover'
                      onClick={goToLegalInfo}>
                      {t('legal:lbl_privacy_policy')}
                    </Link> 
                </div>}
              action={[
                <Button
                  key="accept"
                  color="primary"
                  size="medium"
                  onClick={accept}>
                  {t('legal:btn_accept')}
                </Button>
              ]}
            />
          </div>
        );
    }
}

export default withRouter(CookieDisclaimer);
