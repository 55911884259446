import React from 'react';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearTextIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
    
const SearchField = ({ name, label, value, onChange, clearTextLabel, classes }) => {

    const clearText = () => onChange({
        target: {
            name: name,
            value:  '',
        },
    });

    return (
        <Field id={name}
            name={name}
            label={label}
            component={TextField}
            type='text'
            value={value}
            onChange={onChange}
            fullWidth={true}
            InputLabelProps={{
                classes: {
                    root: classes.label,
                    focused: classes.labelFocused,
                }
            }}
            InputProps={{
                classes: {
                    root: classes.input,
                    underline: classes.underline
                },
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            className={classes.clearIconBtn}
                            aria-label={clearTextLabel}
                            onClick={clearText} >
                            <ClearTextIcon color='inherit' />
                        </IconButton>
                    </InputAdornment>
                ),
            }} />
    );
}

const SearchForm = ({ initialValues, onSubmitSearch, classes, }) => {

    const { t } = useTranslation('search');

    const SearchFilesValidationSchema = Yup.object().shape({
        name: Yup.string(),
        description: Yup.string(),
    });
  
    const onSubmit = async (values, actions) => {
        actions.setSubmitting(false); // we show the activity indicator
        onSubmitSearch({
            name: values.name,
            description: values.description,
        });
    };

    return (
            <Formik
                initialValues={ initialValues }
                validationSchema={ SearchFilesValidationSchema }
                onSubmit={ onSubmit }
                render={ ({ values, handleChange, }) => (
                <Form className={classes.container}>
                    <div className={classes.itemInfoContainer}>
                        <SearchField name='name'
                            label={t('search:lbl_include_file_name')}
                            value={values.name}
                            onChange={handleChange}
                            clearTextLabel={t('search:lbl_clear_name_text_input')}
                            classes={classes} />
                        <SearchField name='description'
                            label={t('search:lbl_include_file_description')}
                            value={values.description}
                            onChange={handleChange}
                            clearTextLabel={t('search:lbl_clear_description_text_input')}
                            classes={classes} />
                        <Fab className={ classes.searchBtn }
                            aria-label={t('search:btn_submit_search')}
                            type='submit'
                            color='primary'
                            size='medium'>
                            <SearchIcon />
                        </Fab>
                    </div>
                </Form>
            ) }
            />
    );
}

export default SearchForm;