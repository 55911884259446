import React from 'react';
import FolderItemToolbar from '../../../folder-item-toolbar';

const FolderContentToolbar = ({ parentFolderId, parentFolderName, currentFolderName, navViewFolder }) => {

    const handleGoToParentFolder = () => {
        navViewFolder({
            folderId: parentFolderId
        });
    };

    return  (
        <FolderItemToolbar leftLinkText={`< ${parentFolderName}`} handleLeftLinkClick={handleGoToParentFolder} middleText={currentFolderName} />
    );
}

export default FolderContentToolbar