import _ from 'lodash';
import { call, put, select, takeLeading, } from 'redux-saga/effects';
import { ACTION_SEARCH_FILES } from './search.action.types';
import { searchUserFiles } from '../../../api/user';
import { selectUser, selectViewingUserDashboard } from '../../../store/store.selectors';
import { setSearchFilesResult } from './search.actions';
import { setShowActivityIndicator } from '../../../store/store.actions';
import { getUserSaga } from '../../../store/store.user.sagas';
import { createSaga } from '../../../store/store.util.sagas';

export function* searchFilesSaga({ payload: { name, description } }) {
    try {
        yield put(setShowActivityIndicator(true));

        let userId;
        const viewingOtherUserDashboard = yield select(selectViewingUserDashboard);
        if (!!_.get(viewingOtherUserDashboard, 'userId')) {
            userId = viewingOtherUserDashboard.userId;
        } else {
            let signedInUser = yield select(selectUser);
            if (_.isEmpty(signedInUser)) {
                yield call(getUserSaga);
                signedInUser = yield select(selectUser);
            }
            userId = signedInUser.userId;
        }

        const result = yield call(searchUserFiles, {
            userId: userId,
            fileNameSearchTerm: name,
            fileDescriptionSearchTerm: description,
        });

        if (result.success) {
            yield put(setSearchFilesResult(result.data));
            yield put(setShowActivityIndicator(false));
        } else {
            throw result;
        }
    } catch(error) {
        _.set(error, 'errorMessage', 'search:snackbar_error_file_search_failed');
        throw error;
    }
}

export default function* rootSaga() {
    yield takeLeading(ACTION_SEARCH_FILES, createSaga(searchFilesSaga));
}