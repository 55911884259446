import folderItemMaterialStyles from './folderItem.materialStyles';
import FolderItem from './folderItem';
import { compose } from '../../utils/func';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'redhooks';
import { selectSelectedFileIds, selectSelectingFilesFromFolderId, selectDisabledFolderActionFolderIds, selectUserProblemsFolderId, selectViewingUserDashboardProblemsFolderId, selectCurrentFolderId } from '../../store/store.selectors';
import { addFileToSelectedFileIds, removeFileFromSelectedFileIds } from '../../store/store.actions';
import { navViewFolder, navViewFile, } from '../../nav/nav.actions';

const mapStateToProps = (state, props) => ({
    isMultiSelected: _.includes(selectSelectedFileIds(state), props.id),
    showMultiFileSelect: selectSelectingFilesFromFolderId(state) ===  selectCurrentFolderId(state),
    isContextMenuDisabled: _.includes(selectDisabledFolderActionFolderIds(state), props.parentId) || props.isReadOnly,
    problemsFolderId: selectUserProblemsFolderId(state),
    viewingUserDashboardProblemsFolderId: selectViewingUserDashboardProblemsFolderId(state),
});

export default compose(
    withStyles(folderItemMaterialStyles),
    withRouter,
    connect(mapStateToProps, {
        addFileToSelectedFileIds,
        removeFileFromSelectedFileIds,
        navViewFolder,
        navViewFile,
    }),
)(FolderItem);