import * as actionTypes from './search.action.types';

export const searchFiles = ({ name, description }) => ({
    type: actionTypes.ACTION_SEARCH_FILES,
    payload: { name, description },
});

export const setSearchFilesResult = files => ({
    type: actionTypes.ACTION_SET_SEARCH_FILES_RESULT,
    payload: files,
});