import React from 'react';
import SortFilesAtoZIcon from '@material-ui/icons/ArrowUpward';
import SortFilesZtoAIcon from '@material-ui/icons/ArrowDownward';
import materialStyles from './materialStyles';
import { withStyles } from '@material-ui/core/styles';

export const SortFilesAtoZIconActive = withStyles(materialStyles)(
    ({ classes }) => {
        return (
            <div className={classes.iconContainer}>
                <SortFilesAtoZIcon />
                <div className={classes.indicator} />
            </div>
        );
    }
);

export const SortFilesZtoAIconActive = withStyles(materialStyles)(
    ({ classes }) => {
        return (
            <div className={classes.iconContainer}>
                <SortFilesZtoAIcon />
                <div className={classes.indicator} />
            </div>
        );
    }
);
