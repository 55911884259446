export default theme => ({
    container: {
        flex: 1,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: theme.breakpoints.values.sm,
        },
        backgroundColor: theme.palette.background.paper,
    },
    fileFormTitle: {
        width: '100%',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit*1.5,
    },
    sectionHeader: {
        fontWeight: '400',
    },
    itemInfoContainer: {
        backgroundColor: '#F3F3F3',
        paddingTop: theme.spacing.unit*2,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit*2,
        paddingRight: theme.spacing.unit*2,
        width: '100vw',
        [theme.breakpoints.up('sm')]: {
            width: theme.breakpoints.values.sm,
        },
    },
    itemInfoFieldContainer: {
        backgroundColor: '#FBFBFB',
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        marginTop: theme.spacing.unit,
    },
    checklistQuestionsContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit*2,
    },
    errorMessageContainer: {
        paddingLeft: theme.spacing.unit*2,
        paddingRight: theme.spacing.unit*2,
        paddingBottom: theme.spacing.unit,
    },
    btnsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    btn: {
        margin: theme.spacing.unit*2,
    },
    numberOfCopiesContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'center',
    },
    imageFieldContainer: {
        backgroundColor: '#FBFBFB',
        marginTop: theme.spacing.unit,
        paddingTop: theme.spacing.unit*2,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit*2,
        paddingRight: theme.spacing.unit*2,
        fontSize: '16px', // To match other form labels
    },
    imageFieldErrorMessageContainer: {
        paddingBottom: theme.spacing.unit,
    },
    imageLabel: {
        marginBottom: theme.spacing.unit,
    },
});