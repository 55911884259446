import LocationExplanationDialog from './locationExplanationDialog';
import { selectIsShowing, } from './locationExplanationDialog.selectors';
import { hideLocationExplanationDialog } from './locationExplanationDialog.actions';
import { connect } from 'redhooks';

const mapStateToProps = state => ({
    isShowing: selectIsShowing(state),
});

export default connect(mapStateToProps, {
    hideLocationExplanationDialog,
})(LocationExplanationDialog);