import React from 'react';
import Typography from '@material-ui/core/Typography';

const FileLabelText = ({ label, text, classes, }) => {
    return (
        <div className={classes.container}>
            <Typography className={classes.title} color='primary' variant='h6'>{label}</Typography>
            <div className={classes.description}>
                <Typography color='inherit'>{text}</Typography>
            </div>
        </div>
    );
};

export default FileLabelText;