import ClaimUnclaimFileDialog from './claimUnclaimFileDialog';
import { selectIsShowing, selectMode, } from './claimUnclaimFileDialog.selectors';
import { hideClaimUnclaimFileDialog, claimFile, unclaimFile, } from './claimUnclaimFileDialog.actions';
import { connect } from 'redhooks';
import { selectIsAdminUser } from '../../../../../store/store.selectors';

const mapStateToProps = state => ({
    isShowing: selectIsShowing(state),
    mode: selectMode(state),
    isAdminUser: selectIsAdminUser(state),
});

export default connect(mapStateToProps, {
    hideClaimUnclaimFileDialog,
    claimFile,
    unclaimFile,
})(ClaimUnclaimFileDialog);