import * as actionTypes from './userList.action.types';

export const setEnableUser = userId => ({
    type: actionTypes.ACTION_ENABLE_USER,
    payload: userId,
});

export const setDisableUser = userId => ({
    type: actionTypes.ACTION_DISABLE_USER,
    payload: userId,
});

export const showEnableUserDialog = (userId) => ({
    type: actionTypes.ACTION_SHOW_ENABLE_USER_DIALOG,
    payload: userId
});

export const showDisableUserDialog = (userId) => ({
    type: actionTypes.ACTION_SHOW_DISABLE_USER_DIALOG,
    payload: userId
});

export const hideEnableDisableUserDialog = () => ({
    type: actionTypes.ACTION_HIDE_ENABLE_DISABLE_USER_DIALOG,
});