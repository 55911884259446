import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const WarningIcon = props => (
    <SvgIcon { ...props }>
        <svg xmlns="http://www.w3.org/2000/svg" width="21.508" height="20" viewBox="0 0 21.508 20">
            <g id="warning" transform="translate(0)">
                <path id="Path_2131" data-name="Path 2131" d="M21.112,15.324l-7.6-13.7a3.156,3.156,0,0,0-5.52,0L.4,15.324A3.157,3.157,0,0,0,3.156,20h15.2a3.157,3.157,0,0,0,2.76-4.676Zm0,0" transform="translate(0)" fill="#fdbf00"/>
                <path id="Path_2132" data-name="Path 2132" d="M266.348,18.45a3.13,3.13,0,0,1-2.72,1.55H256V0a3.158,3.158,0,0,1,2.771,1.626l7.628,13.7A3.1,3.1,0,0,1,266.348,18.45Zm0,0" transform="translate(-245.287)" fill="#ff9f00"/>
                <path id="Path_2133" data-name="Path 2133" d="M211,86.1V95.59h3.8V86.1Zm0,0" transform="translate(-202.144 -82.497)" fill="#5a5a5a"/>
                <path id="Path_2134" data-name="Path 2134" d="M212.9,341.1a1.9,1.9,0,1,0,1.9,1.9A1.895,1.895,0,0,0,212.9,341.1Zm0,0" transform="translate(-202.144 -326.794)" fill="#5a5a5a"/>
                <g id="Group_415" data-name="Group 415" transform="translate(10.75 3.554)">
                <path id="Path_2135" data-name="Path 2135" d="M257.9,343a1.9,1.9,0,0,1-1.9,1.9v-3.8A1.9,1.9,0,0,1,257.9,343Zm0,0" transform="translate(-256 -330.348)" fill="#444"/>
                <path id="Path_2136" data-name="Path 2136" d="M256,86.1h1.9V95.59H256Zm0,0" transform="translate(-256 -86.102)" fill="#444"/>
                </g>
            </g>
        </svg>
    </SvgIcon>
);
WarningIcon.displayName = 'WarningIcon';
WarningIcon.muiName = 'SvgIcon';

export default WarningIcon;