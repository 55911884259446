import _ from 'lodash';
import React, { useState } from 'react';
import Ratings from '../../../../file/ratings';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormHelperText  from '@material-ui/core/FormHelperText';
import Typography  from '@material-ui/core/Typography';
import LocationExplanationDialog from '../location-explanation-dialog';
import TextField from '../../../../common/form/texField/textField';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { RATINGS } from '../../../../../api/file/constants';
import FileField from '../../../../common/form/fileField';
import { useTranslation } from 'react-i18next';
import SuspenseView from '../../../../suspense-view';

const MAX_FILE_SIZE_MB = 50;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB*1000*1000;

const EntryForm = ({ questions, addFileEntry, closeFileEntryForm, classes, }) => {

    const { t, ready } = useTranslation(['common', 'form', 'file']);

    const ratings = questions.map(question => ({ question: question.question, rating: RATINGS.NONE }));

    const initialValues = {
        ratings: ratings,
        comment: '',
        attachment: '',
    };

    const FileEntryValidationSchema = Yup.object().shape({
        ratings: Yup.array().of(Yup.object().shape({
            question: Yup.string(),
            rating: Yup.mixed().oneOf([ RATINGS.FAIL, RATINGS.CAUTION, RATINGS.PASS ]).required()
        })),
        comment: Yup.string(),
        attachment: Yup.mixed().test('test-file-too-large', 'FILE_TOO_LARGE', file => {
            return MAX_FILE_SIZE_BYTES > _.get(file, 'size', 0);
        }),
    });
  
    const onSubmit = async (values, actions) => {

        actions.setSubmitting(false); // we show the activity indicator

        addFileEntry({
            ratings: values.ratings,
            comment: values.comment,
            attachment: values.attachment,
        });
    };

    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

    const showCancelDialog = () => {
        if (!isCancelDialogOpen) {
            setIsCancelDialogOpen(true);
        }
    }

    const hideCancelDialog = () => {
        if (isCancelDialogOpen) {
            setIsCancelDialogOpen(false);
        }
    }

    const handleCloseFileEntryForm = () => {
        hideCancelDialog();
        closeFileEntryForm();
    }

    if(!ready) {
        return <SuspenseView />;
    }

    return (
            <>
                <Formik
                    initialValues={ initialValues }
                    validationSchema={ FileEntryValidationSchema }
                    onSubmit={ onSubmit }
                    render={ ({ isSubmitting, errors, touched, values, setFieldValue }) => (
                    <Form className={classes.container}>
                        { ratings.length > 0 &&
                            <>
                                <Typography className={classes.sectionHeader} color='primary' variant='h6'>{t('file:header_section_ratings')}</Typography>
                                <Field name='ratings'
                                        component={ Ratings }
                                        disabled={false} />
                            </>
                        }
                        <Typography className={classes.sectionHeader} color='primary' variant='h6'>{t('file:header_section_comment')}</Typography>
                        <div className={classes.commentContainer}>
                            <Typography className={classes.lblComment} color='inherit' variant='inherit'>{t('file:lbl_comment')}</Typography>
                            <div className={classes.commentInputFieldContainer}>
                                <Field name='comment'
                                    required
                                    component={ TextField }
                                    disableUnderline={true}
                                    type='text'
                                    theme='light'
                                    hideFieldError={true}
                                    multiline={true}
                                    rows={6}
                                    rowsMax={6} />
                            </div>
                        </div>
                        <div>
                            <Typography className={classes.sectionHeader} color='primary' variant='h6'>{t('file:header_section_attach_file')}</Typography>
                            <div className={classes.fileFieldContainer}>
                                <FileField name='attachment' value={values.attachment} setFieldValue={setFieldValue} />
                                { errors.attachment &&
                                    <div className={classes.errorMessageContainer}>
                                        <FormHelperText error>
                                            { t('file:max_file_size_mb', { mb: MAX_FILE_SIZE_MB }) }
                                        </FormHelperText>
                                    </div>
                                }
                            </div>
                        </div>
                        { (touched.ratings && errors.ratings) &&
                            <div className={classes.errorMessageContainer}>
                                <FormHelperText error>
                                    { t('file:every_question_requires_rating') }
                                </FormHelperText>
                            </div>
                        }
                        <div className={classes.btnsContainer}>
                            <Button className={ classes.btn } variant='contained' disabled={ isSubmitting } fullWidth onClick={showCancelDialog}>
                                {t('common:btn_cancel')}
                            </Button>
                            <Button className={ classes.btn } type='submit' color='primary' variant='contained' disabled={ isSubmitting } fullWidth>
                                {t('common:btn_submit')}
                            </Button>
                        </div>
                    </Form>
                ) }
                />
                <LocationExplanationDialog />
                <Dialog open={isCancelDialogOpen}
                    onClose={hideCancelDialog}
                    aria-labelledby='cancel-entry-dialog-title'
                    aria-describedby='cancel-entry-dialog-description'>
                    <DialogTitle id='cancel-entry-dialog-title' disableTypography={true}>
                    <Typography variant='h6' color='inherit'>{ t('file:dialog_title_cancel_create_entry') }</Typography>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id='cancel-entry-dialog-description'>
                        { t('file:dialog_message_cancel_file_form') }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={hideCancelDialog} color='primary' autoFocus>
                        {t('common:cancel')}
                    </Button>
                    <Button onClick={handleCloseFileEntryForm} color='primary'>
                        {t('common:yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EntryForm;