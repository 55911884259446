import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const ViewFileInAppSnackbar = ({ isOpen, handleClose, fileLink, }) => {

    const { t } = useTranslation('common');

    const handleViewFileInApp = e => {
        handleClose();
        window.open(fileLink, '_blank', 'rel=noopener');
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open={isOpen}
            ContentProps={{
                'aria-describedby': 'view-file-in-app-snackbar-message',
            }}
            message={<Typography id='view-file-in-app-snackbar-message' color='inherit'>{t('common:view_file_in_app_snackbar')}</Typography>}
            action={[
                <Button key='cancel-view-in-app' size='small' onClick={handleClose}>
                    {t('common:btn_cancel')}
                </Button>,
                <Button key='view-file-in-app' color='primary' size='small' onClick={handleViewFileInApp}>
                    {t('common:btn_view_in_app')}
                </Button>,
            ]}
      />
    );
};

export default ViewFileInAppSnackbar;