import { questionFontSize } from "../../../../file/ratings/ratings.materialStyles";

export default theme => ({
    container: {
        flex: 1,
        width: '100vw',
        [theme.breakpoints.up('sm')]: {
            width: theme.breakpoints.values.sm,
        },
        backgroundColor: theme.palette.background.paper,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit*3,
        paddingRight: theme.spacing.unit*3
    },
    sectionHeader: {
        fontWeight: '400',
        marginTop: theme.spacing.unit,
    },
    commentContainer: {
        padding: theme.spacing.unit*2,
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.secondary['100'],
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundColor: '#F3F3F3',
        marginTop: theme.spacing.unit,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: questionFontSize,
    },
    lblComment: {
        marginBottom: theme.spacing.unit*2,
    },
    fileFieldContainer: {
        marginTop: theme.spacing.unit,
    },
    commentInputFieldContainer: {
        padding: theme.spacing.unit,
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.secondary['200'],
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundColor: '#F9F9F9',
    },
    errorMessageContainer: {
        paddingBottom: theme.spacing.unit,
    },
    btnsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    btn: {
        margin: theme.spacing.unit*2,
    }
});