import Amplify, { Auth } from 'aws-amplify';
import { fillInWithEnvVariables, getEnvVariable } from '../utils/environmentVariables';
import ReactGA from 'react-ga';

const awsAmplifyConfig = fillInWithEnvVariables({
  Auth: {
    identityPoolId: "%REACT_APP_IDENTITY_POOL_ID%",
    userPoolId: "%REACT_APP_USER_POOL_ID%",
    userPoolWebClientId: "%REACT_APP_USER_POOL_WEB_CLIENT_ID%",
    region: "%REACT_APP_REGION%",
  },
  API: {
    endpoints: [
      {
        name: "%REACT_APP_API_NAME%",
        endpoint: "%REACT_APP_API_URL%",
        region: "%REACT_APP_REGION%",
      }
    ],
  },
  Storage: {
    AWSS3: {
      bucket: 'punch-it-%REACT_APP_ENV_NAME%-assets',
      region: '%REACT_APP_REGION%',
    },
  },
}, getEnvVariable);

const headers = async () => {
  try {
    return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
  } catch (e) {
    return {};
  }
};

const GAConfig = fillInWithEnvVariables({
  uaKey: "%REACT_APP_GA_UA_KEY%",
  config: {debug: false},
}, getEnvVariable);

awsAmplifyConfig.API.endpoints[0].custom_header = headers;

Amplify.configure(awsAmplifyConfig);

ReactGA.initialize(GAConfig.uaKey, GAConfig.config);