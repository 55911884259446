export const STATE_STAY_LOGGED_IN = 'stayLoggedIn';

export const PASSWORD_FORMAT_MIN_CHARS = 8;
export const PASSWORD_FORMAT_MAX_CHARS = 99;
export const PASSWORD_FORMAT_MIN_LOWER_CASE = 2;
export const PASSWORD_FORMAT_MIN_UPPER_CASE = 1;
export const PASSWORD_FORMAT_MIN_NUMBERS = 1;
// Config is used to get the translation for 'form:passwordFormat'
export const PASSWORD_FORMAT_CONFIG = {
    minLength: PASSWORD_FORMAT_MIN_CHARS,
    lowerCaseMinCount: PASSWORD_FORMAT_MIN_LOWER_CASE,
    upperCaseMinCount: PASSWORD_FORMAT_MIN_UPPER_CASE,
    numberMinCount: PASSWORD_FORMAT_MIN_NUMBERS,
};
// Note: we optionally allow special characters and up to 99 characters in total as specified by Cognito:
// https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html#user-pool-settings-password-policies
export const PASSWORD_FORMAT_REGEX = /^(?=(.*[a-z]){2,})(?=.*[A-Z])(?=.*\d)[a-zA-Z\d(^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`)?]{8,99}$/;

export const OVERRIDE_LANDING_PAGE = 'overrideLandingPage';