import { awsApiRequest } from '../../utils/func';

export const getIndustries = async () => {
    try {
        const result = await awsApiRequest({
            method: 'GET',
            path: '/industries'
        });
        if(result.success) {
            result.data = result.data.map(item => ({ id: item.id,  name: item.name }));
        }
        return result;
    } catch (error) {
        return error;
    }
};
