export const ACTION_SET_FOLDER_CONTENT_TOOLBAR = 'ACTION_SET_FOLDER_CONTENT_TOOLBAR';

export const ACTION_SET_ERROR_MESSAGE = 'ACTION_SET_ERROR_MESSAGE';

export const ACTION_SET_CONTEXT_MENU = 'ACTION_SET_CONTEXT_MENU';

export const ACTION_SHOW_REMOVE_FOLDER_DIALOG = 'ACTION_SHOW_REMOVE_FOLDER_DIALOG';
export const ACTION_HIDE_REMOVE_DIALOG = 'ACTION_HIDE_REMOVE_DIALOG';
export const ACTION_SET_SHOW_REMOVE_DIALOG = 'ACTION_SET_SHOW_REMOVE_DIALOG';

export const ACTION_SHOW_RENAME_FOLDER_DIALOG = 'ACTION_SHOW_RENAME_FOLDER_DIALOG';
export const ACTION_HIDE_RENAME_DIALOG = 'ACTION_HIDE_RENAME_DIALOG';
export const ACTION_SET_SHOW_RENAME_DIALOG = 'ACTION_SET_SHOW_RENAME_DIALOG';

export const ACTION_SHOW_CREATE_FOLDER_DIALOG = 'ACTION_SHOW_CREATE_FOLDER_DIALOG';
export const ACTION_HIDE_CREATE_FOLDER_DIALOG = 'ACTION_HIDE_CREATE_FOLDER_DIALOG';
export const ACTION_SET_SHOW_CREATE_FOLDER_DIALOG = 'ACTION_SET_SHOW_CREATE_FOLDER_DIALOG';