export const ACTION_RESET = 'ACTION_RESET';

export const ACTION_GET_USER = 'ACTION_GET_USER';
export const ACTION_SET_USER = 'ACTION_SET_USER';
export const ACTION_GET_USER_LIST = 'ACTION_GET_USER_LIST';
export const ACTION_SET_USER_LIST = 'ACTION_SET_USER_LIST';

export const ACTION_GET_FOLDER_DATA = 'ACTION_GET_FOLDER_DATA';
export const ACTION_SET_FOLDER_DATA = 'ACTION_SET_FOLDER_DATA';

export const ACTION_REORDER_FOLDER_CONTENTS = 'ACTION_REORDER_FOLDER_CONTENTS';
export const ACTION_SET_FOLDER_CONTENTS = 'ACTION_SET_FOLDER_CONTENTS';

export const ACTION_SORT_FILES_DEFAULT = 'ACTION_SORT_FILES_DEFAULT';
export const ACTION_SORT_FILES_A_TO_Z = 'ACTION_SORT_FILES_A_TO_Z';
export const ACTION_SORT_FILES_Z_TO_A = 'ACTION_SORT_FILES_Z_TO_A';

export const ACTION_EXPORT_FILES = 'ACTION_EXPORT_FILES';

export const ACTION_CREATE_FOLDER = 'ACTION_CREATE_FOLDER';
export const ACTION_RENAME_FOLDER = 'ACTION_RENAME_FOLDER';
export const ACTION_SET_FOLDER_NAME = 'ACTION_SET_FOLDER_NAME';
export const ACTION_REMOVE_FOLDER = 'ACTION_REMOVE_FOLDER';

export const ACTION_CREATE_FILE = 'ACTION_CREATE_FILE';
export const ACTION_UPDATE_FILE = 'ACTION_UPDATE_FILE';
export const ACTION_VIEW_FILE = 'ACTION_VIEW_FILE';
export const ACTION_SET_VIEW_FILE = 'ACTION_SET_VIEW_FILE';
export const ACTION_EDIT_FILE = 'ACTION_EDIT_FILE';
export const ACTION_SET_EDIT_FILE = 'ACTION_SET_EDIT_FILE';

export const ACTION_CREATE_FILE_ENTRY = 'ACTION_CREATE_FILE_ENTRY';
export const ACTION_VIEW_FILE_ENTRY = 'ACTION_VIEW_FILE_ENTRY';
export const ACTION_SET_VIEW_FILE_ENTRY = 'ACTION_SET_VIEW_FILE_ENTRY';

export const ACTION_DOWNLOAD_FILE_ENTRY_ATTACHMENT = 'ACTION_DOWNLOAD_FILE_ENTRY_ATTACHMENT';

export const ACTION_SHOW_INFO_SNACKBAR = 'ACTION_SHOW_INFO_SNACKBAR';
export const ACTION_HIDE_INFO_SNACKBAR = 'ACTION_HIDE_INFO_SNACKBAR';

export const ACTION_SET_SHOW_ACTIVITY_INDICATOR = 'ACTION_SET_SHOW_ACTIVITY_INDICATOR';

export const ACTION_OPEN_CONTEXT_MENU = 'ACTION_OPEN_CONTEXT_MENU';
export const ACTION_CLOSE_CONTEXT_MENU = 'ACTION_CLOSE_CONTEXT_MENU';
export const ACTION_SET_CONTEXT_MENU_ITEMS = 'ACTION_SET_CONTEXT_MENU_ITEMS';

export const ACTION_START_SELECT_FILES = 'ACTION_START_SELECT_FILES';
export const ACTION_SET_SELECT_FILES_IN_PROGRESS = 'ACTION_SET_SELECT_FILES_IN_PROGRESS';
export const ACTION_ADD_FILE_TO_SELECTED_FILE_IDS = 'ACTION_ADD_FILE_TO_SELECTED_FILE_IDS';
export const ACTION_REMOVE_FILE_FROM_SELECTED_FILE_IDS = 'ACTION_REMOVE_FILE_FROM_SELECTED_FILE_IDS';
export const ACTION_COMPLETE_SELECT_FILES = 'ACTION_COMPLETE_SELECT_FILES';
export const ACTION_CANCEL_SELECT_FILES = 'ACTION_CANCEL_SELECT_FILES';
export const ACTION_SET_SHOW_SELECTED_FILES_ACTION_FAILED = 'ACTION_SET_SHOW_SELECTED_FILES_ACTION_FAILED';

export const ACTION_SHOW_SESSION_EXPIRED_DIALOG = 'ACTION_SHOW_SESSION_EXPIRED_DIALOG';
export const ACTION_HIDE_SESSION_EXPIRED_DIALOG = 'ACTION_HIDE_SESSION_EXPIRED_DIALOG';
export const ACTION_CONTINUE_SESSION_EXPIRED = 'ACTION_CONTINUE_SESSION_EXPIRED';

export const ACTION_VIEW_USER_DASHBOARD = 'ACTION_VIEW_USER_DASHBOARD';
export const ACTION_STOP_VIEWING_USER_DASHBOARD = 'ACTION_STOP_VIEWING_USER_DASHBOARD';
export const ACTION_SET_VIEWING_USER_DASHBOARD = 'ACTION_SET_VIEWING_USER_DASHBOARD';
