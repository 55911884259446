import React from 'react';
import PunchIcon from '../../images/punch_icon.png';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { folderItemIconSize } from "./constants";

const FileIcon = props => (
    <SvgIcon { ...props }>
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="baseline-description-24px">
                    <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                    <path d="M14,2 L6,2 C4.9,2 4.01,2.9 4.01,4 L4,20 C4,21.1 4.89,22 5.99,22 L18,22 C19.1,22 20,21.1 20,20 L20,8 L14,2 Z" id="Path" fill="#EFEBDF" fillRule="nonzero"></path>
                    <polygon id="Path" fill="#D5D0BC" fillRule="nonzero" points="16 18 8 18 8 16 16 16"></polygon>
                    <polygon id="Path" fill="#D5D0BC" fillRule="nonzero" points="16 14 8 14 8 12 16 12"></polygon>
                    <polygon id="Path" fill="#D5D0BC" fillRule="nonzero" points="14 8 14 2 20 8"></polygon>
                </g>
            </g>
        </svg>
    </SvgIcon>
);
FileIcon.displayName = 'FileIcon';
FileIcon.muiName = 'SvgIcon';

export const FileIndicator = ({ classes }) => {

    const { t } = useTranslation('file');

    return (
        <div className={classes.container}>
            <FileIcon color='inherit' fontSize='inherit' />
            <img className={classes.punchIcon} src={PunchIcon} alt={t('file:file_icon')} />
        </div>
    );
};

const materialStyles = theme => ({
    container: {
        color: '#EFEBDF',
        fontSize: folderItemIconSize,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    punchIcon: {
        position: 'absolute',
        bottom: 0,
        right: 4,
        width: 24,
        height: 24,
    },
});


export default withStyles(materialStyles)(FileIndicator);