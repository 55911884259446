import _ from 'lodash';
import { awsApiRequest } from '../../utils/func';
import { CLAIM_STATUS } from './constants';

/**
 * 
 * @param {Object} fileData - the data of the new file to be created
 * @param {string} fileData.userId - the id of the signed in user
 * @param {string} fileData.claimStatus - a string representing the CLAIM_STATUS
 * @param {string} fileData.name - the name of the file
 * @param {string} fileData.description - the description of the file
 * @param {string} fileData.alias - the alias of the file
 * @param {string} fileData.imageKey - (optional) the uploaded S3 image key of the file
 * @param {string} fileData.advertiserUrl - (optional) the advertiser url of the file image
 * @param {string[]} fileData.questions - an array of questions
 * @param {number} fileData.numCopies - (optional) the number of file copies to create
 */
export const createFile = async ({ userId, claimStatus, name, description, imageKey, advertiserUrl, questions, numCopies }) => {

    if (!userId || !name || !description) {
        console.error(`api-file-create-new-file - Missing userId:${!userId}, name:${!name} or description:${!description}`);
        return {
            success: false,
            errorMessage: 'common:err_default'
        } 
    }

    try {

        const body = {
            name: name,
            description: description,
            questions: questions ? questions.map(question => ({
                question: question,
            })) : [],
            creator: userId,
        };

        if (claimStatus === CLAIM_STATUS.CLAIMED) {
            body.claimed_by = userId;
            body.claim_status = CLAIM_STATUS.CLAIMED;
        } else {
            body.claim_status = CLAIM_STATUS.UNCLAIMED;
        }

        if (_.isNumber(numCopies) && (numCopies > 1)) {
            body.num_copies = numCopies;
        }

        if (imageKey) {
            body.image_key = imageKey;
        }

        if (advertiserUrl) {
            body.advertiser_url = advertiserUrl;
        }

        return await awsApiRequest({
            method: 'POST',
            path: `/files`,
            params: {
                body: body,
            },
        });
    } catch (error) {
        return error;
    }
};

export const updateFile = async ({ currentAlias, name, description, alias, imageKey, advertiserUrl}) => {

    if (!currentAlias || !name || !description) {
        console.error(`api-file-edit-file - Missing  currentAlias:${!currentAlias}, name:${!name} or description:${!description}`);
        return {
            success: false,
            errorMessage: 'common:err_default'
        } 
    }

    const body = {
        name: name,
        description: description,
    };

    if (alias) {
        body.alias = alias;
    }

    if (imageKey) {
        body.image_key = imageKey;
    }

    if (advertiserUrl) {
        body.advertiser_url = advertiserUrl;
    }

    try {
        return await awsApiRequest({
            method: 'PUT',
            path: `/files/${currentAlias}`,
            params: {
                body: body,
            },
        });
    } catch (error) {
        return error;
    }
};

/**
 * 
 * @param {string} fileAlias - the alias of the file
 */
export const getFile = async (fileAlias) => {

    if (!fileAlias) {
        console.error(`api-file-get-file - Missing fileAlias:${!fileAlias}`);
        return {
            success: false,
            errorMessage: 'common:err_default'
        } 
    }

    try {
        return await awsApiRequest({
            method: 'GET',
            path: `/files/${fileAlias}`,
        });
    } catch (error) {
        return error;
    }
};

/**
 * 
 * @param {string} fileAlias - the alias of the file
 * @param {userId} userId - the id of user claiming the file
 */
export const claimFile = async ({ fileAlias, userId }) => {

    if (!fileAlias || !userId) {
        console.error(`api-file-claim-file - Missing fileAlias:${!fileAlias} or userId:${!userId}`);
        return {
            success: false,
            errorMessage: 'common:err_default'
        } 
    }

    try {
        return await awsApiRequest({
            method: 'PUT',
            path: `/files/${fileAlias}/claim-status`,
            params: {
                body: {
                    user_id: userId,
                    claim_status: CLAIM_STATUS.CLAIMED,
                },
            },
        });
    } catch (error) {
        return error;
    }
};

/**
 * 
 * @param {string} fileAlias - the alias of the file
 * @param {userId} userId - the id of user unclaiming the file
 */
export const unclaimFile = async ({ fileAlias, userId }) => {

    if (!fileAlias || !userId) {
        console.error(`api-file-unclaim-file - Missing fileAlias:${!fileAlias} or userId:${!userId}`);
        return {
            success: false,
            errorMessage: 'common:err_default'
        } 
    }

    try {
        return await awsApiRequest({
            method: 'PUT',
            path: `/files/${fileAlias}/claim-status`,
            params: {
                body: {
                    user_id: userId,
                    claim_status: CLAIM_STATUS.UNCLAIMED,
                },
            },
        });
    } catch (error) {
        return error;
    }
};

/**
 * 
 * @param {String} fileAlias - the alias of the file to which we will add the entry to
 * @param {Object} entryData - the data of the new file entry to be created
 * @param {String} entryData.userId - (required) the user id of the signed in user
 * @param {String} entryData.username - (required) the user username of the signed in user
 * @param {Array} entryData.ratings - (required) array of rating objects [{ question, rating }]
 * @param {String} entryData.comment - (optional) the comment text
 * @param {Object} entryData.location - (optional) object with the user device's latitude and longitude
 * @param {String} entryData.attachmentKey - (optional) the attachment key, i.e. the key of the S3 uploaded file
 * 
 */
export const createFileEntry = async ({ fileAlias, userId, username, ratings, comment, location, attachmentKey }) => {

    if (!fileAlias || !userId || !username || !ratings) {
        console.error(`api-file-create-file-entry - Missing fileAlias:${!fileAlias}, userId:${!userId}, username:${!username} or ratings:${!ratings}`);
        return {
            success: false,
            errorMessage: 'common:err_default'
        } 
    }

    const body = {
        user_id: userId,
        username,
        ratings,
    };

    if (comment) {
        body.comment = comment;
    }

    if (_.has(location, 'latitude') && _.has(location, 'longitude')) {
        body.location = location;
    }

    if (attachmentKey) {
        body.attachment_key = attachmentKey;
    }

    try {
        return await awsApiRequest({
            method: 'PUT',
            path: `/files/${fileAlias}/entries`,
            params: {
                body: body,
            },
        });
    } catch (error) {
        return error;
    }
};

/**
 * 
 * @param {string} fileAlias - the alias of the file
 * @param {string} entryId - the id of the file entry
 */
export const getFileEntry = async ({ fileAlias, entryId }) => {

    if (!fileAlias || !entryId) {
        console.error(`api-file-get-file-entry - Missing fileAlias:${!fileAlias} or entryId:${!entryId}`);
        return {
            success: false,
            errorMessage: 'common:err_default'
        } 
    }

    try {
        return await awsApiRequest({
            method: 'GET',
            path: `/files/${fileAlias}/entries/${entryId}`,
        });
    } catch (error) {
        return error;
    }
};