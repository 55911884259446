import React from 'react';
import { Redirect, Route } from 'react-router';
import { withAuthentication } from '../authenticationContext';

const PrivateRoute = ({ component, isSignedIn, authApi: { setLandingPage }, ...rest }) => {
  const Component = component;

  const render = (props) => {
    if (isSignedIn) {
      return (<Component { ...props } />)
    } else {
      setLandingPage(props.location.pathname);
      return (<Redirect to={ { pathname: '/signin' } }/>)
    }
  };

  return (
    <Route { ...rest } render={ render }/>
  )
};

export default withAuthentication(PrivateRoute);