import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    whitelist: [ 'en' ],
    ns: 'common',
    backend: {
      loadPath: '/translations/{{lng}}/{{ns}}.json'
    },
    react: {
      wait: true,
      useSuspense: false, // using the react suspense functionality causes issues with the material ui components like tabs and drawers see related issues for reference:
      // https://github.com/mui-org/material-ui/issues/14441
      // https://github.com/mui-org/material-ui/issues/14077
      // https://github.com/mui-org/material-ui/issues/14319
      // https://github.com/facebook/react/issues/14536
    },
  });

export const getCurrentLanguage = () => {
  return i18n.language;
};

export const changeLanguage = (lang) => {
  return i18n.changeLanguage(lang);
};

export default i18n;