import * as actionTypes from './drawer.action.types';

export const openDrawer = () => ({
    type: actionTypes.ACTION_OPEN_DRAWER,
});

export const closeDrawer = () => ({
    type: actionTypes.ACTION_CLOSE_DRAWER,
});

export const toggleDrawer = () => ({
    type: actionTypes.ACTION_TOGGLE_DRAWER,
});