
import Dashboard from './dashboard';
import { compose } from '../../../utils/func';
import { withRouter } from 'react-router';
import { connect } from 'redhooks';
import {
    selectErrorMessage,
    selectContextMenu,
    selectRemoveDialog,
    selectRenameDialog,
} from './dashboard.selectors';
import * as actions from './dashboard.actions';
import { getFolderData, reorderFolderContents, createFolder, renameFolder, removeFolder, startMoveFiles, startRemoveFiles, setContextMenuItems, sortFilesAtoZ, sortFilesZtoA, exportFiles, } from '../../../store/store.actions';
import { selectCurrentFolderId, selectCurrentFolderName, selectParentFolderId, selectParentFolderName, selectUserDashboardFolderId, selectDisabledFolderActionFolderIds, selectFolderContents, selectContentsSortType, selectViewingUserDashboardId, } from '../../../store/store.selectors';
import withGA from '../../../utils/withGA';

const mapStateToProps = state => ({
    currentFolderId: selectCurrentFolderId(state),
    currentFolderName: selectCurrentFolderName(state),
    parentFolderId: selectParentFolderId(state),
    parentFolderName: selectParentFolderName(state),
    dashboardFolderId: selectUserDashboardFolderId(state),
    contents: selectFolderContents(state),
    contentsSortType: selectContentsSortType(state),
    errorMessage: selectErrorMessage(state),
    contextMenu: selectContextMenu(state),
    removeDialog: selectRemoveDialog(state),
    renameDialog: selectRenameDialog(state),
    disabledFolderActionFolderIds: selectDisabledFolderActionFolderIds(state),
    viewingOtherUserDashboardId: selectViewingUserDashboardId(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps, {
        getFolderData,
        reorderFolderContents,
        createFolder,
        renameFolder,
        removeFolder,
        startMoveFiles,
        startRemoveFiles,
        setContextMenuItems,
        sortFilesAtoZ,
        sortFilesZtoA,
        exportFiles,
        ...actions,
    }),
    withGA,
)(Dashboard);