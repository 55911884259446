import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import './config';
import Dashboard from './components/pages/dashboard';
import PrivateRoute from './auth/route/privateRoute';
import Header from './components/header';
import AppDrawer from './components/drawer';
import Authentication from './auth/authentication';
import { Router } from 'react-router-dom';
import { browserHistory } from './nav';
import Provider from "redhooks";
import store from "./store/store";
import NewFile from './components/pages/file/new-file';
import EditFile from './components/pages/file/edit-file';
import Search from './components/pages/search';
import Legal from './components/pages/legal';
import InfoSnackbar from './components/info-snackbar';
import CookieDisclaimer from './components/cookie-disclaimer';
import ViewFile from './components/pages/file/view-file';
import Users from './components/pages/users/user-list';
import ActivityIndicator from './components/common/activity-indicator';
import SessionExpiredDialog from './components/session-expired-dialog';
import ViewingUserDashboard from './components/viewing-user-dashboard';

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <InfoSnackbar />
        <ActivityIndicator />
        <Router history={browserHistory}>
          <Authentication>
            <AppDrawer>
              <Header/>
              <ViewingUserDashboard />
              <Switch>
                <PrivateRoute path='/dashboard/:folderId?'
                              component={ Dashboard }/>
                <Route exact path='/' render={() => <Redirect to='/dashboard'/>}/>
                <PrivateRoute exact path='/file'
                              component={ NewFile }/>
                <PrivateRoute path='/file/:fileAlias/edit'
                              component={ EditFile }/>
                <PrivateRoute path='/file/:fileAlias'
                              component={ ViewFile }/>
                <PrivateRoute exact path='/search'
                              component={ Search }/>
                <PrivateRoute path='/users'
                              component={ Users } />
                <Route exact path='/legal'
                              component={ Legal} />
              </Switch>
              <CookieDisclaimer />
              <SessionExpiredDialog />
              <div id="system-snackbar" />
            </AppDrawer>
          </Authentication>
        </Router>
      </Provider>
    );
  }
}

export default App;
