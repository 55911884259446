import _ from 'lodash';
import { SYSTEM_FOLDER_LOOKUP_NAMES } from '../api/folder/constants';
import { MAX_NUM_SELECTED_FILES } from './constants';

export const selectUser = state => state.user;

export const selectUserList = state => state.main.userList;

export const selectUserDashboardFolderId = state => _.get(state, `user.systemFolders.${SYSTEM_FOLDER_LOOKUP_NAMES.dashboard}.id`);

export const selectUserProblemsFolderId = state => _.get(state, `user.systemFolders.${SYSTEM_FOLDER_LOOKUP_NAMES.problems}.id`);

const emptyArray = [];
export const selectDisabledFolderActionFolderIds = state => _.get(state, 'user.disabledFolderActionFolderIds', emptyArray);

export const selectIsAdminUser = state => state.user && state.user.isAdmin;

export const selectCurrentFolderId = state => state.main.currentFolderId;

export const selectCurrentFolderName = state => state.main.currentFolderName;

export const selectParentFolderId = state => state.main.parentFolderId;

export const selectParentFolderName = state => state.main.parentFolderName;

export const selectFolderContents = state => state.main.contents;

export const selectContentsSortType = state => state.main.contentsSortType;

export const selectOrderedFolderContents = state => _.sortBy(state.main.contents, item => item.order);

export const selectViewFile = state => state.main.viewFile;

export const selectEditFile = state => state.main.editFile;

export const selectViewFileEntry = state => state.main.viewFileEntry;

export const selectSelectFilesMode = state => state.main.selectFiles.mode;

export const selectIsSelectFilesInProgress = state => state.main.selectFiles.isInProgress;

export const selectSelectingFilesFromFolderId = state => state.main.selectFiles.currentFolderId;

export const selectSelectedFileIds = state => state.main.selectFiles.fileIds;

export const selectIsMaxSelectFilesReached = state => _.size(state.main.selectFiles.fileIds) >= MAX_NUM_SELECTED_FILES;

export const selectNumFilesSelected = state => _.size(state.main.selectFiles.fileIds);

export const selectShowSelectedFilesActionFailed = state => state.main.selectFiles.showActionFailed;

export const selectViewingUserDashboard = state => state.main.viewingUserDashboard;

export const selectViewingUserDashboardName = state => state.main.viewingUserDashboard.displayName;

export const selectViewingUserDashboardId = state => _.get(state, `main.viewingUserDashboard.systemFolders.${SYSTEM_FOLDER_LOOKUP_NAMES.dashboard}.id`);

export const selectViewingUserDashboardProblemsFolderId = state => _.get(state, `main.viewingUserDashboard.systemFolders.${SYSTEM_FOLDER_LOOKUP_NAMES.problems}.id`);

export const selectShowInfoSnackbar = state => state.infoSnackbar.isOpen;

export const selectInfoSnackbarMessageKey = state => state.infoSnackbar.messageKey;

export const selectInfoSnackbarMessageOptions = state => state.infoSnackbar.messageOptions;

export const selectShowActivityIndicator = state => state.activityIndicator.isShowing;

export const selectIsContextMenuOpen = state => state.headerContextMenu.isOpen;

export const selectContextMenuItems = state => state.headerContextMenu.menuItems;

export const selectIsSessionExpiredDialogOpen = state => state.sessionExpiredDialog.isOpen;
