import EntryView from './entryView';
import materialStyles from './entryView.materialStyles';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { compose } from '../../../../../utils/func';
import { connect } from 'redhooks';
import { viewFileEntry, downloadFileEntryAttachment, } from '../../../../../store/store.actions';
import { selectViewFileEntry } from '../../../../../store/store.selectors';

const mapStateToProps = (state, props) => ({
  entry: selectViewFileEntry(state),
});

export default compose(
  withRouter,
  withTranslation('file'),
  withStyles(materialStyles),
  connect(mapStateToProps, {
    viewFileEntry,
    downloadFileEntryAttachment,
  }),
)(EntryView);