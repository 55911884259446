import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

const ErrorSnackbar = ({ error, dismissBtnTxt, dismissError }) => {

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open={!!error}
            autoHideDuration={6000}
            onClose={dismissError}
            ContentProps={{
                'aria-describedby': 'error-snackbar-message-id',
            }}
            message={<Typography id='error-snackbar-message-id' color='inherit'>{error}</Typography>}
            action={
                !!dismissBtnTxt ? [
                    <Button key='dismiss-error' color='primary' size='small' onClick={dismissError}>
                        {dismissBtnTxt}
                    </Button>,
                ] :
                []
            }
      />
    );
}

export default ErrorSnackbar;