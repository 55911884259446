import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FOLDER_ITEM_TYPE_FILE, FOLDER_ITEM_TYPE_FOLDER } from '../../../../constants';

const ContextMenu = ({ hideDeleteOption, folderItemId, anchorEl, isOpen, handleClose, handleRename, handleMove, handleDelete, type, t }) => {

    let renameT;
    let moveT;
    let deleteT;
    if (type === FOLDER_ITEM_TYPE_FOLDER) {
        renameT = t('dashboard:context_menu_rename_folder');
        deleteT = t('dashboard:context_menu_remove_folder');
    } else if (type === FOLDER_ITEM_TYPE_FILE) {
        moveT = t('dashboard:context_menu_move_file');
        deleteT = t('dashboard:context_menu_remove_file');
    }

    const onRename = () => handleRename(folderItemId);
    const onMove = () => handleMove(folderItemId);
    const onDelete = () => handleDelete(folderItemId);

    const hideRenameOption = (type === FOLDER_ITEM_TYPE_FILE);
    const hideMoveOption = (type === FOLDER_ITEM_TYPE_FOLDER);
    if (!isOpen || (hideDeleteOption && hideRenameOption && hideMoveOption)) {
        return null;
    }

    return (
        <Menu
            id={`context-menu-${folderItemId}`}
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}>
            { !hideRenameOption && <MenuItem onClick={onRename}>{renameT}</MenuItem> }
            { !hideMoveOption && <MenuItem onClick={onMove}>{moveT}</MenuItem> }
            { !hideDeleteOption && <MenuItem onClick={onDelete}>{deleteT}</MenuItem> }
        </Menu>
    );
}

export default ContextMenu;