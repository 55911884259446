import _ from 'lodash';
import React, { useState } from 'react';
import ChecklistQuestions from '../../../file/checklist-questions';
import PageContainer from '../../../common/page-container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormHelperText  from '@material-ui/core/FormHelperText';
import Typography  from '@material-ui/core/Typography';
import TextField from '../../../common/form/texField/textField';
import NumberField from '../../../common/form/numberField/numberField';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import SuspenseView from '../../../suspense-view';
import FileField from '../../../common/form/fileField';
import Interweave from 'interweave';

const NUMBER_COPIES_MIN = 1;
const NUMBER_COPIES_MAX = 75;

const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB*1000*1000;

const ERROR_FILE_TOO_LARGE = 'FILE_TOO_LARGE';
const ERROR_FILE_NOT_IMAGE = 'FILE_NOT_IMAGE';

export const ALIAS_FORMAT = /^[a-zA-Z0-9-._~()'!*]+$/;

const FileForm = ({ mode, editFileData, isAdmin, createFile, updateFile, navViewFile, history, classes, }) => {

    const { t, ready } = useTranslation(['common', 'form', 'file']);

    const initialValues = {
        name: _.get(editFileData, 'name', ''),
        alias: _.get(editFileData, 'alias', ''),
        description: _.get(editFileData, 'description', ''),
        questions: _.get(editFileData, 'questions', []).map(item => item.question),
        numCopies: NUMBER_COPIES_MIN,
    };

    if (isAdmin) {
        initialValues.image = '';
        initialValues.advertiserUrl = _.get(editFileData, 'advertiser_url', '');
    }

    const NewFileValidationSchema = Yup.object().shape({
        alias: mode === 'edit' ? Yup.string().required(t('form:required')).matches(ALIAS_FORMAT, t('file:err_alias_format')) : null,
        name: Yup.string()
            .required(t('form:required')),
        description: Yup.string()
            .required(t('form:required')),
        advertiserUrl: Yup.string(),
        numCopies: Yup.number()
            .min(NUMBER_COPIES_MIN, t('file:lbl_err_number_copies_min', { copiesMin: NUMBER_COPIES_MIN }))
            .max(NUMBER_COPIES_MAX, t('file:lbl_err_number_copies_max', { copiesMax: NUMBER_COPIES_MAX }))
            .required(t('file:lbl_err_number_copies_required', { copiesMin: NUMBER_COPIES_MIN, copiesMax: NUMBER_COPIES_MAX })),
        image: Yup.mixed()
            .test('test-file-type-image', ERROR_FILE_NOT_IMAGE, file => {
                const fileType = _.get(file, 'type', '');
                if (fileType === '') {
                    // user didn't select an image, skip the validation
                    return true;
                }
                return fileType.startsWith('image');
            })
            .test('test-file-too-large', ERROR_FILE_TOO_LARGE, file => {
                return MAX_FILE_SIZE_BYTES > _.get(file, 'size', 0);
            }),          
    });
  
    const onSubmit = async (values, actions) => {

        actions.setSubmitting(false); // we show the activity indicator

        if (mode === 'create') {
            createFile({
                fileData: values,
                history: history,
            });
        } else if (mode === 'edit') {
            updateFile({
                fileData: {
                    currentAlias: editFileData.alias,
                    name: values.name,
                    description: values.description,
                    alias: values.alias,
                    image: values.image,
                    imageKey: _.get(editFileData, 'image_key', ''), // required so that we can remove the existing image
                    advertiserUrl: values.advertiserUrl,
                },
                history: history,
                successMessage: t('file:info_snackbar_file_updated'),
            });
        } else {
            actions.setStatus({ msg: 'common:err_default' });
        }
    };

    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

    const showCancelDialog = () => {
        if (!isCancelDialogOpen) {
            setIsCancelDialogOpen(true);
        }
    }

    const hideCancelDialog = () => {
        if (isCancelDialogOpen) {
            setIsCancelDialogOpen(false);
        }
    }

    const handleCancelFileForm = () => {
        if (mode === 'edit') {
            navViewFile({
                fileAlias: editFileData.alias,
                parentFolderId: _.get(history, 'location.state.parentFolderId'),
                parentFolderName: _.get(history, 'location.state.parentFolderName'),
            });
        } else {
            history.replace('/dashboard');
        }
    }

    if (!ready) {
        return <SuspenseView />
    }

    return (
        <PageContainer theme='light'>
                <Formik
                    initialValues={ initialValues }
                    validationSchema={ NewFileValidationSchema }
                    onSubmit={ onSubmit }
                    render={ ({ status, isSubmitting, values, errors, touched, setFieldValue, setFieldTouched, }) => (
                    <Form className={classes.container} noValidate>
                        <Typography className={classes.fileFormTitle} color='textPrimary' variant='h6'>
                            {mode === 'create' ? t('file:file_form_title_new') : t('file:file_form_title_edit')}
                        </Typography>
                        <div className={classes.itemInfoContainer}>
                            <Typography className={classes.sectionHeader} color='primary' variant='h6'>{t('file:header_section_item_info')}</Typography>
                            { isAdmin && mode === 'edit' &&
                            <>
                            <div className={classes.itemInfoFieldContainer}>
                                <Field name='alias'
                                    label={ t('file:alias') }
                                    required
                                    component={ TextField }
                                    disableUnderline={true}
                                    type='text'
                                    theme='light'
                                    hideFieldError={true}
                                    disabled={mode !== 'edit'}
                                    showLockIcon={true}
                                    defaultValue={_.get(editFileData, 'alias', [])} />
                             </div>
                             <FormHelperText error>{ (touched.alias && errors.alias) ? <Interweave content={errors.alias} /> : '' }</FormHelperText>
                            </>
                            }
                            <div className={classes.itemInfoFieldContainer}>
                                <Field name='name'
                                    label={ t('file:name') }
                                    required
                                    component={ TextField }
                                    disableUnderline={true}
                                    type='text'
                                    theme='light'
                                    hideFieldError={true} />
                            </div>
                            <FormHelperText error>{ (touched.name && errors.name) ? errors.name : '' }</FormHelperText>
                            <div className={classes.itemInfoFieldContainer}>
                                <Field name='description'
                                    label={ t('file:description') }
                                    required
                                    component={ TextField }
                                    disableUnderline={true}
                                    type='text'
                                    theme='light'
                                    hideFieldError={true}
                                    multiline={true}
                                    rowsMax={8} />
                            </div>
                            <FormHelperText error>{ (touched.description && errors.description) ? errors.description : '' }</FormHelperText>
                            { isAdmin &&
                                <>
                                    <div className={classes.imageFieldContainer}>
                                        <Typography className={classes.imageLabel} color='secondary' variant='inherit'>{ t('file:lbl_image') }</Typography>
                                        <Typography color='secondary' variant='caption'>
                                            { mode === 'create' ? t('file:lbl_image_note_default') : t('file:lbl_image_note_default_edit') }
                                        </Typography>
                                        <Typography className={classes.imageLabel} color='secondary' variant='caption'>{ t('file:lbl_image_note_ratio') }</Typography>
                                        <FileField name='image' value={values.image} acceptedFileType='image/*' setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
                                    </div>
                                    <div className={classes.imageFieldErrorMessageContainer}>
                                        <FormHelperText error>
                                            { (touched.image && errors.image) === ERROR_FILE_NOT_IMAGE && t('file:file_type_not_image') }
                                            { (touched.image && errors.image) === ERROR_FILE_TOO_LARGE && t('file:max_file_size_mb', { mb: MAX_FILE_SIZE_MB }) }
                                        </FormHelperText>
                                    </div>
                                    <div className={classes.itemInfoFieldContainer}>
                                        <Field name='advertiserUrl'
                                            label={ mode === 'create' ? t('file:lbl_advertiser_url_create') : t('file:lbl_advertiser_url_edit') }
                                            required
                                            component={ TextField }
                                            disableUnderline={true}
                                            type='text'
                                            theme='light'
                                            hideFieldError={true}
                                            showLockIcon={true}
                                            defaultValue={_.get(editFileData, 'questions', [])} />
                                    </div>
                                    <FormHelperText error />
                                   
                                </>
                            }
                        </div>
                        { ((mode === 'create') || (mode === 'edit' && _.get(editFileData, 'questions.length', 0) > 0)) &&
                            <div className={classes.checklistQuestionsContainer}>
                                <Typography className={classes.sectionHeader} color='primary' variant='h6'>{t('file:header_section_checklist_questions')}</Typography>
                                <Field name='questions'
                                    component={ ChecklistQuestions }
                                    disabled={mode==='edit'}/>
                            </div>
                        }
                        { (isAdmin && (mode === 'create')) &&
                            <div className={classes.numberOfCopiesContainer}>
                                <Field name='numCopies'
                                label={ t('file:lbl_number_of_copies') }
                                component={ NumberField }
                                theme='light'
                                min={NUMBER_COPIES_MIN}
                                max={NUMBER_COPIES_MAX} />
                            </div>
                        }
                        { status && status.msg &&
                            <div className={classes.errorMessageContainer}>
                                <FormHelperText error>{ t(status.msg) }</FormHelperText>
                            </div>
                        }
                        <div className={classes.btnsContainer}>
                            <Button className={ classes.btn } variant='contained' disabled={ isSubmitting } fullWidth onClick={showCancelDialog}>
                                {t('common:btn_cancel')}
                            </Button>
                            <Button className={ classes.btn } type='submit' color='primary' variant='contained' disabled={ isSubmitting } fullWidth>
                                {t('common:btn_submit')}
                            </Button>
                        </div>
                    </Form>
                ) }
                />
                <Dialog open={isCancelDialogOpen}
                    onClose={hideCancelDialog}
                    aria-labelledby='cancel-file-dialog-title'
                    aria-describedby='cancel-file-dialog-description'>
                    <DialogTitle id='cancel-file-dialog-title' disableTypography={true}>
                    <Typography variant='h6' color='inherit'>{ mode === 'create' ? t('file:dialog_title_cancel_create_file') : t('file:dialog_title_cancel_edit_file') }</Typography>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id='cancel-file-dialog-description'>
                        { t('file:dialog_message_cancel_file_form') }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={hideCancelDialog} color='primary' autoFocus>
                        {t('common:cancel')}
                    </Button>
                    <Button onClick={handleCancelFileForm} color='primary'>
                        {t('common:yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        </PageContainer>
    )
}

export default FileForm;