import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Interweave from 'interweave';
import materialStyles from '../form.materialStyles';
import { FormHelperText, FormControl, IconButton, Input, InputAdornment, InputLabel, withStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { withTranslation } from 'react-i18next';
import { compose } from '../../../../utils/func';

export const TextField = (props) => {

  const { label, type, disabled, disableUnderline, maxLength, multiline, rows, rowsMax, form: { errors, touched }, field, theme, hideFieldError, showLockIcon, classes, t } = props;
  const showError = touched[field.name] && !!errors[field.name];

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const inputProps = {};
  if (maxLength) {
    inputProps.maxLength = maxLength;
  }

  let labelClassName;
  let labelClasses;
  let inputClasses;
  let inputThemeLightClasses;
  if (theme === 'light') {
    labelClassName = showLockIcon ? classes.formInputLabelThemeLightMarginRight : classes.formInputLabelThemeLight;
    labelClasses = {
      root: classes.inputLabelThemeLightRoot,
      focused: classes.formLabelThemeLightFocused,
      disabled: classes.inputLabelThemeLightDisabled,
    };
    inputClasses = classes.inputThemeLight;
    inputThemeLightClasses = {
      root: classes.inputThemeLightRoot,
      disabled: classes.inputThemeLightDisabled,
      underline: classes.inputThemeLightUnderline,
    };
  } else {
    labelClassName = showLockIcon ? classes.formInputLabelMarginRight : classes.formInputLabel;
    inputClasses = classes.input;
  }

  return (
    <FormControl className={classes.formControl} fullWidth={true} error={showError} disabled={disabled}>
      {
        label ?
          (<InputLabel className={labelClassName} FormLabelClasses={labelClasses} disabled={disabled}>{label}</InputLabel>) :
          null
      }
      <Input
        color='secondary'
        className={inputClasses}
        classes={inputThemeLightClasses}
        {...field}
        type={ (type==='password') ? showPassword ? 'text' : 'password' : type }
        disabled={disabled}
        error={showError}
        disableUnderline={disableUnderline}
        inputProps={inputProps}
        endAdornment = {
          type==='password' ?
          <InputAdornment position="end">
            <IconButton
              aria-label={t('common:toggle_password_visibility')}
              onClick={toggleShowPassword}
            >
              {showPassword ? <VisibilityOff className={classes.textFieldIcon} /> : <Visibility className={classes.textFieldIcon} />}
            </IconButton>
          </InputAdornment>
          : showLockIcon ?
            <InputAdornment position="end">
                {disabled ? <LockIcon className={classes.lockIcon} /> : <LockOpenIcon className={classes.lockIcon} />}
            </InputAdornment>
            : null
        }
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}

      />
      { !hideFieldError &&
        <FormHelperText className={classes.formHelperText} component='div'>
          <Interweave content={showError ? errors[field.name] : ''}/>
        </FormHelperText>
      }
    </FormControl>
  );
};

TextField.defaultProps = {
  disabled: false,
  theme: 'dark',
};

TextField.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.node,
  input: PropTypes.any,
  disabled: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  maxLength: PropTypes.number,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
  }),
  field: PropTypes.object,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  hideFieldError: PropTypes.bool,
  showLockIcon: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
};

export default compose(
  withTranslation(['common']),
  withStyles(materialStyles),
)(TextField);