import * as actionTypes from './locationExplanationDialog.action.types';

const initialState = {
    isShowing: false,
};

export const REDUCER_NAME = 'locationExplanationDialog';

const locationExplanationDialogReducer = (state=initialState, action) => {
    switch(action.type) {
        case actionTypes.ACTION_SHOW_LOCATION_EXPLANATION_DIALOG:
            return {
                isShowing: true,
            };
        case actionTypes.ACTION_HIDE_LOCATION_EXPLANATION_DIALOG:
            return {
                isShowing: false,
            };
        default:
            return state;
    }
};

export default locationExplanationDialogReducer;