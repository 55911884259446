import _ from 'lodash';
import SelectFilesSnackbar from './selectFilesSnackbar';
import { connect } from 'redhooks';
import { completeSelectFiles, cancelSelectFiles, } from '../../../../store/store.actions';
import { selectDisabledFolderActionFolderIds, selectIsSelectFilesInProgress, selectSelectFilesMode, selectIsMaxSelectFilesReached, selectNumFilesSelected, selectSelectingFilesFromFolderId, selectShowSelectedFilesActionFailed, } from '../../../../store/store.selectors';

const mapStateToProps = state => ({
    isOpen: selectIsSelectFilesInProgress(state),
    selectFilesMode: selectSelectFilesMode(state),
    disabledMoveToFolderIds: _.concat(selectDisabledFolderActionFolderIds(state), [selectSelectingFilesFromFolderId(state)]),
    isMaxSelectedFilesReached: selectIsMaxSelectFilesReached(state),
    numFilesSelected: selectNumFilesSelected(state),
    showActionFailedErrorMessage: selectShowSelectedFilesActionFailed(state),
});

export default connect(mapStateToProps, {
    completeSelectFiles,
    cancelSelectFiles,
})(SelectFilesSnackbar);