import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography  from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import createPersistedState from 'use-persisted-state';
import { STATE_HAVE_SEEN_LOCATION_PERMISSION_DIALOG, STATE_ALLOW_REQUEST_LOCATION_PERMISSION, } from './constants';

const useHaveSeenLocationPermissionDialog = createPersistedState(STATE_HAVE_SEEN_LOCATION_PERMISSION_DIALOG);
const useAllowRequestLocationPermission = createPersistedState(STATE_ALLOW_REQUEST_LOCATION_PERMISSION);

const LocationExplanationDialog = ({ isShowing, hideLocationExplanationDialog, }) => {

    const { t } = useTranslation(['common', 'file']);
    const [haveSeenLocationPermissionDialog, setHaveSeenLocationPermissionDialog] = useHaveSeenLocationPermissionDialog(false); // eslint-disable-line no-unused-vars
    const [allowRequestLocationPermission, setAllowRequestLocationPermission] = useAllowRequestLocationPermission(false); // eslint-disable-line no-unused-vars

    const handleCancel = () => {
        setHaveSeenLocationPermissionDialog(true);
        setAllowRequestLocationPermission(false);
        hideLocationExplanationDialog(false); // false is used in the saga to know that user declined the location permission
    };

    const handleOk = () => {
        setHaveSeenLocationPermissionDialog(true);
        setAllowRequestLocationPermission(true);
        hideLocationExplanationDialog(true); // true is used in the saga to know that user accepted the location permission
    };

    return (
        <Dialog open={isShowing}
                onClose={hideLocationExplanationDialog}
                aria-labelledby='location-explanation-dialog-title'
                aria-describedby='location-explanation-dialog-description'
                maxWidth='xs'>
                <DialogTitle id='location-explanation-dialog-title' disableTypography={true}>
                <Typography variant='h6' color='inherit'>{ t('file:dialog_title_location_explanation') }</Typography>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id='location-explanation-dialog-description' style={{ whiteSpace: 'pre-line' }} >
                    { t('file:dialog_message_location_explanation') }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color='primary' autoFocus>
                    {t('common:cancel')}
                </Button>
                <Button onClick={handleOk} color='primary'>
                    { t('common:ok') }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LocationExplanationDialog;