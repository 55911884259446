import React, { Component } from 'react';
import SignInPage from './signin';
import { Redirect, Route, withRouter } from 'react-router';
import AuthApi from './api';
import AuthenticationContext from './authenticationContext';
import SignUpPage from './signup';
import ResetPasswordPage from './reset-password';
import SetPasswordPage from './set-password';
import { STATE_STAY_LOGGED_IN } from './constants';
import { signUp } from './api/users/index';
import { getUser as refreshUserState, reset as resetAppState } from '../store/store.actions';
import { connect } from 'redhooks';

/**
 * Provides child components with Authentication
 * API and User data
 */
class Authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      isSignedIn: false,
      user: null,
      api: {
        signIn: this.signIn,
        completeNewPassword: this.completeNewPassword,
        resetPassword: this.resetPassword,
        confirmResetPassword: this.confirmResetPassword,
        signOut: this.signOut,
        signUp: signUp,
        resendSignUp: this.resendSignUp,
        confirmSignUp: this.confirmSignUp,
        setLandingPage: this.setLandingPage,
        goToPage: this.goToPage
      },
    };
    this.landingPage = '/';
    this.initialize();
  }

  initialize = async () => {
    if (localStorage && (localStorage.getItem(STATE_STAY_LOGGED_IN) !== 'true')) {
      await AuthApi.signOut();
    }
    const user = await AuthApi.currentAuthenticatedUser();
    this.setState({ isInitialized: true, isSignedIn: user && !user.error });
    this.props.refreshUserState();
  };

  signIn = async (username, password) => {
    const result = await AuthApi.signIn(username, password);
    if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
      this.newPasswordRequiredUser = result; // temporarily store the user challenge result for when we call completeNewPassword (important to use the Cognito User object as is)
    } else if (result.success) {
      this.setState({ isSignedIn: true });
      this.props.refreshUserState();
    }
    return result;
  };

  completeNewPassword = async (newPassword) => {
    return await AuthApi.completeNewPassword(this.newPasswordRequiredUser, newPassword);
  }

  resetPassword = async (username) => await AuthApi.resetPassword(username);

  confirmResetPassword = async (username, code, password) => await AuthApi.confirmResetPassword(username, code, password);

  signOut = async () => {
    const response = await AuthApi.signOut();
    this.setState({ isSignedIn: false });
    this.props.resetAppState();
    this.setLandingPage('/');
    return response;
  };

  resendSignUp = async (username) => await AuthApi.resendSignUp(username);

  confirmSignUp = async (username, code) => await AuthApi.confirmSignUp(username, code);

  goToPage = page => this.props.history.push(page ? page : this.landingPage);

  setLandingPage = url => this.landingPage = url;

  render() {
    const { children } = this.props;
    if (!this.state.isInitialized) {
      return (
        <div>Loading...</div>
      )
    }
    return (
      <AuthenticationContext.Provider value={ this.state }>
        { children }
        <>
          <Route path="/signup/:confirmEmail?"
            render={() => this.state.isSignedIn ? <Redirect to='/dashboard'/> : <SignUpPage /> } />
          <Route path="/signin/:email?"
            render={() => this.state.isSignedIn ? <Redirect to='/dashboard'/> : <SignInPage /> } />
          <Route path="/set-password/:email"
            render={() => this.state.isSignedIn ? <Redirect to='/dashboard'/> : <SetPasswordPage /> } />
          <Route path="/reset-password/:confirmEmail?"
            render={() => this.state.isSignedIn ? <Redirect to='/dashboard'/> : <ResetPasswordPage /> } />
        </>
      </AuthenticationContext.Provider>
    );
  }
}

export default withRouter(connect(null, { refreshUserState, resetAppState, } )(Authentication));
