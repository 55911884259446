import { inputThemeLight, inputThemeLightRoot, inputThemeLightDisabled, inputThemeLightUnderline} from '../../common/form/form.materialStyles';

export default theme => ({
    addQuestionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    addQuestionInputContainer: {
        flexDirection: 'row',
        flex: 1,
        marginRight: theme.spacing.unit*2,
    },
    questionsContainer: {
        marginTop: theme.spacing.unit,
    },
    questionContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        backgroundColor: '#F6F6F6',
        alignItems: 'center',
        paddingTop: theme.spacing.unit,
        marginTop: theme.spacing.unit*2,
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    addQuestionFabContainer: {
        marginTop: theme.spacing.unit*2,
    },
    removeIconContainer: {
        color: theme.palette.secondary.light,
    },
    inputThemeLight: {
        ...inputThemeLight(theme),
        flex: 1,
    },
    inputThemeLightRoot: inputThemeLightRoot(theme),
    inputThemeLightDisabled: inputThemeLightDisabled(theme),
    inputThemeLightUnderline: inputThemeLightUnderline(theme),
});