export const CLAIM_STATUS = {
    UNCLAIMED: 'UNCLAIMED',
    CLAIMED: 'CLAIMED',
};

export const RATINGS = {
    NONE: 0,
    PASS: 1,
    FAIL: 2,
    CAUTION: 3,
};