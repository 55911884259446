import Search from './search';
import searchMaterialStyles from './search.materialStyles';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'redhooks';
import { setContextMenuItems } from '../../../store/store.actions';
import { searchFiles } from './search.actions';
import { compose } from '../../../utils/func';
import { selectFiles, selectLastSearch, selectIsInitialSearch } from './search.selectors';
import { navViewFile } from '../../../nav/nav.actions';
import withGA from '../../../utils/withGA'

const mapStateToProps = state => ({
  files: selectFiles(state),
  lastSearch: selectLastSearch(state),
  isInitialSearch: selectIsInitialSearch(state),
});

export default compose(
    withStyles(searchMaterialStyles),
    connect(mapStateToProps, {
      setContextMenuItems,
      searchFiles,
      navViewFile,
    }),
    withGA,
)(Search);