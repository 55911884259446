import _ from 'lodash';
import * as actionTypes from './store.action.types';
import { SELECT_FILES_MODE_MOVE, SELECT_FILES_MODE_REMOVE, } from './constants';

export const reset = () => ({
    type: actionTypes.ACTION_RESET,
});

export const getUser = () => ({
    type: actionTypes.ACTION_GET_USER,
});

export const setUser = payload => ({
    type: actionTypes.ACTION_SET_USER,
    payload: payload,
});

export const getUserList = () => ({
    type: actionTypes.ACTION_GET_USER_LIST,
});

export const setUserList = payload => ({
    type: actionTypes.ACTION_SET_USER_LIST,
    payload: payload,

});

export const getFolderData = parentId  => ({
    type: actionTypes.ACTION_GET_FOLDER_DATA,
    payload: parentId,
});

export const setFolderData = payload => ({
    type: actionTypes.ACTION_SET_FOLDER_DATA,
    payload: payload,
});

export const reorderFolderContents = payload => ({
    type: actionTypes.ACTION_REORDER_FOLDER_CONTENTS,
    payload: payload,
});

export const setFolderContents = payload => ({
    type: actionTypes.ACTION_SET_FOLDER_CONTENTS,
    payload: payload,
});

export const sortFilesDefault = () => ({
    type: actionTypes.ACTION_SORT_FILES_DEFAULT,
});

export const sortFilesAtoZ = () => ({
    type: actionTypes.ACTION_SORT_FILES_A_TO_Z,
});

export const sortFilesZtoA = () => ({
    type: actionTypes.ACTION_SORT_FILES_Z_TO_A,
});

export const exportFiles = ({ folderId }) => ({
    type: actionTypes.ACTION_EXPORT_FILES,
    payload: { folderId },
});

export const createFolder = payload => ({
    type: actionTypes.ACTION_CREATE_FOLDER,
    payload: payload,
});

export const renameFolder = payload => ({
    type: actionTypes.ACTION_RENAME_FOLDER,
    payload: payload,
});

export const setFolderName = payload => ({
    type: actionTypes.ACTION_SET_FOLDER_NAME,
    payload: payload,
});

export const removeFolder = payload => ({
    type: actionTypes.ACTION_REMOVE_FOLDER,
    payload: payload,
});

export const createFile = payload => ({
    type: actionTypes.ACTION_CREATE_FILE,
    payload: payload,
});

export const updateFile = payload => ({
    type: actionTypes.ACTION_UPDATE_FILE,
    payload: payload,
});

export const viewFile = payload => ({
    type: actionTypes.ACTION_VIEW_FILE,
    payload: payload,
});

export const setViewFile = payload => ({
    type: actionTypes.ACTION_SET_VIEW_FILE,
    payload: payload,
});

export const editFile = payload => ({
    type: actionTypes.ACTION_EDIT_FILE,
    payload: payload,
});

export const setEditFile = payload => ({
    type: actionTypes.ACTION_SET_EDIT_FILE,
    payload: payload,
});

export const createFileEntry = payload => ({
    type: actionTypes.ACTION_CREATE_FILE_ENTRY,
    payload: payload,
});

export const viewFileEntry = payload => ({
    type: actionTypes.ACTION_VIEW_FILE_ENTRY,
    payload: payload,
});

export const setViewFileEntry = payload => ({
    type: actionTypes.ACTION_SET_VIEW_FILE_ENTRY,
    payload: payload,
});

export const downloadFileEntryAttachment = ({ attachmentKey }) => ({
    type: actionTypes.ACTION_DOWNLOAD_FILE_ENTRY_ATTACHMENT,
    payload: { attachmentKey },
});

export const showInfoSnackbar = message => ({
    type: actionTypes.ACTION_SHOW_INFO_SNACKBAR,
    payload: {
        messageKey: _.isString(message) ? message : _.get(message, 'messageKey', ''),
        messageOptions: _.get(message, 'messageOptions', {}),
    },
});

export const hideInfoSnackbar = () => ({
    type: actionTypes.ACTION_HIDE_INFO_SNACKBAR,
});

export const setShowActivityIndicator = show => ({
    type: actionTypes.ACTION_SET_SHOW_ACTIVITY_INDICATOR,
    payload: show,
});

export const openContextMenu = () => ({
    type: actionTypes.ACTION_OPEN_CONTEXT_MENU,
});

export const closeContextMenu = () => ({
    type: actionTypes.ACTION_CLOSE_CONTEXT_MENU,
});

export const setContextMenuItems = menuItems => ({
    type: actionTypes.ACTION_SET_CONTEXT_MENU_ITEMS,
    payload: menuItems,
});

export const startMoveFiles = ({ currentFolderId, fileId, }) => ({
    type: actionTypes.ACTION_START_SELECT_FILES,
    payload: { mode: SELECT_FILES_MODE_MOVE, currentFolderId, fileId, },
});

export const startRemoveFiles = ({ currentFolderId, fileId }) => ({
    type: actionTypes.ACTION_START_SELECT_FILES,
    payload: { mode: SELECT_FILES_MODE_REMOVE, currentFolderId, fileId },
});

export const setSelectFilesInProgress = ({ mode, isInProgress, currentFolderId }) => ({
    type: actionTypes.ACTION_SET_SELECT_FILES_IN_PROGRESS,
    payload: { mode, isInProgress, currentFolderId },
});

export const addFileToSelectedFileIds = fileId => ({
    type: actionTypes.ACTION_ADD_FILE_TO_SELECTED_FILE_IDS,
    payload: fileId,
});

export const removeFileFromSelectedFileIds = fileId => ({
    type: actionTypes.ACTION_REMOVE_FILE_FROM_SELECTED_FILE_IDS,
    payload: fileId,
});

export const completeSelectFiles = newFolderId => ({
    type: actionTypes.ACTION_COMPLETE_SELECT_FILES,
    payload: newFolderId,
});

export const cancelSelectFiles = () => ({
    type: actionTypes.ACTION_CANCEL_SELECT_FILES,
});

export const setShowSelectedFilesActionFailed = show => ({
    type: actionTypes.ACTION_SET_SHOW_SELECTED_FILES_ACTION_FAILED,
    payload: show,
});

export const showSessionExpiredDialog = () => ({
    type: actionTypes.ACTION_SHOW_SESSION_EXPIRED_DIALOG
});

export const hideSessionExpiredDialog = () => ({
    type: actionTypes.ACTION_HIDE_SESSION_EXPIRED_DIALOG
});

export const continueSessionExpired = () => ({
    type: actionTypes.ACTION_CONTINUE_SESSION_EXPIRED
});

export const viewUserDashboard = (email) => ({
    type: actionTypes.ACTION_VIEW_USER_DASHBOARD,
    payload: { email },
});

export const stopViewingUserDashboard = () => ({
    type: actionTypes.ACTION_STOP_VIEWING_USER_DASHBOARD,
});

export const setViewingUserDashboard = user => ({
    type: actionTypes.ACTION_SET_VIEWING_USER_DASHBOARD,
    payload: user,
});