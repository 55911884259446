import React from 'react';
import { Typography } from '@material-ui/core';
import UserIcon from '@material-ui/icons/Person';


const DrawerHeader = ({ name, email, classes }) => {
    return (
        <div className={classes.container}>
            <div className={classes.userIconContainer}>
                <UserIcon className={ classes.userIcon } fontSize="inherit" />
            </div>
            <Typography className={classes.name} color="textPrimary" variant="h6">{name}</Typography>
            <Typography color="textSecondary" variant="body2">{email}</Typography>
        </div>
    )
}

export default DrawerHeader;