import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FolderItem from '../../../folder-item';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { isMobile, isIOS } from 'react-device-detect';
import { FOLDER_ITEM_TYPE_FILE } from '../../../../constants';

const PRESS_TO_DRAG_THRESHOLD_PX = 10; // Number of pixels of movement to tolerate before ignoring a press event.
const MOBILE_DEVICE_PRESS_TO_DRAG_DELAY_MS = 200; // Elements to only become sortable after being pressed for a certain time (milliseconds).
const MOBILE_DEVICE_LONG_PRESS_TO_OPEN_CONTEXT_MENU_MS = 800; // Number of milliseconds to wait before triggering the context menu

const GridFolderItem = ({ folderItem, onShowFolderItemContextMenu, currentFolderId, currentFolderName }) => (
    <Grid item xs={3}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FolderItem id={folderItem.id} name={folderItem.name} type={folderItem.type} parentId={currentFolderId} parentName={currentFolderName} itemsCount={folderItem.itemsCount} isReadOnly={folderItem.isReadOnly} onShowFolderItemContextMenu={onShowFolderItemContextMenu} />
        </div>
    </Grid>
);

const SortableFolderItem = SortableElement(({ folderItem, onShowFolderItemContextMenu, currentFolderId, currentFolderName, }) => {
    return (
        <GridFolderItem folderItem={folderItem} onShowFolderItemContextMenu={onShowFolderItemContextMenu} currentFolderId={currentFolderId} currentFolderName={currentFolderName} />
    );
});

const SortableFolderItems = SortableContainer(({ items, onShowFolderItemContextMenu, currentFolderId, currentFolderName }) => {
    // Note: see reason for special handling of the disabled prop on mobile devices below in the handleSortEnd function
    return (
        <Grid container>
            {
                items.map((item, index) => (
                    <SortableFolderItem key={item.id}
                        index={index} folderItem={item}
                        onShowFolderItemContextMenu={onShowFolderItemContextMenu}
                        disabled={isMobile ? item.isReadOnly : (item.isReadOnly || (item.type === FOLDER_ITEM_TYPE_FILE))}
                        currentFolderId={currentFolderId}
                        currentFolderName={currentFolderName} />
                ))
            }
        </Grid>
    );
});

const SortableItem = ({ folderItems, onChangeFolderContentOrder, onShowFolderItemContextMenu, currentFolderId, currentFolderName }) => {

    let sortStartIndex = -1;
    let sortStartEvent = null;
    let sortStartTarget = null;
    let contextMenuTimeout = null;

    const resetSortState = () => {
        sortStartIndex = -1;
        sortStartEvent = null;
        sortStartTarget = null;
    };

    // Make sure we clear the timeout on unmount
    useEffect(() => {
        if (contextMenuTimeout) {
            clearTimeout(contextMenuTimeout);
        }
    }, []);

    const callContextMenuOnLongTouch = () => {

        if (sortStartIndex < 0 || !sortStartEvent || !sortStartTarget) {
            return;
        }
        
        sortStartEvent.preventDefault();

        onShowFolderItemContextMenu({
            folderItemId: folderItems[sortStartIndex].id,
            folderItemType: folderItems[sortStartIndex].type,
            eventCurrentTarget: sortStartTarget,
        });

        resetSortState();
    };

    const handleOnSortStart = ({ index }, event) => {

        sortStartIndex = index;
        sortStartEvent = event;
        sortStartTarget = event.target;

        contextMenuTimeout = setTimeout(callContextMenuOnLongTouch, MOBILE_DEVICE_LONG_PRESS_TO_OPEN_CONTEXT_MENU_MS);
    };

    const handleSortEnd = ({ oldIndex, newIndex }, event) => {

        if (contextMenuTimeout) {
            clearTimeout(contextMenuTimeout);
        }

        if (folderItems[oldIndex].type === FOLDER_ITEM_TYPE_FILE) {
            // Note: File re-arrangement is not allowed
            // Reason for not preventing this earlier in the flow (i.e. onSortStart or disable sorting) is because
            // we cannot detect a long press on mobile otherwise.
            return;
        }

        if (oldIndex === newIndex) {
            if (isIOS) {
                // required for iOS to prevent automatic selection of context menu items
                // Note iOS: See folder component - set disableTouchRipple to true to prevent the ripple effect from lingering
                // Note Android: don't call this for Android, otherwise the button ripple effect lingers on
                event.preventDefault();
            }
            return;
        }

        onChangeFolderContentOrder({
            id: folderItems[oldIndex].id,
            oldIndex,
            newIndex,
        });
    };

    // Callback that is invoked when moving over an item
    const handleOnSortOver = () => {

        if (contextMenuTimeout) {
            clearTimeout(contextMenuTimeout);
        }
    };

    const sortableContainerProps = {};
    if (isMobile) {
        sortableContainerProps.pressDelay = MOBILE_DEVICE_PRESS_TO_DRAG_DELAY_MS;
        sortableContainerProps.pressThreshold = PRESS_TO_DRAG_THRESHOLD_PX;
        sortableContainerProps.onSortStart = handleOnSortStart;
        sortableContainerProps.onSortOver = handleOnSortOver;
    } else {
        sortableContainerProps.distance = PRESS_TO_DRAG_THRESHOLD_PX; // Note: we have to set the distance prop for desktop to be more than 0, otherwise the click event is not propagated (i.e. the folder is not opened)
    }

    return (
        <SortableFolderItems items={folderItems}
            axis='xy'
            useWindowAsScrollContainer={true}
            onSortEnd={handleSortEnd}
            onShowFolderItemContextMenu={onShowFolderItemContextMenu}
            {...sortableContainerProps}
            currentFolderId={currentFolderId}
            currentFolderName={currentFolderName}/>
    );
};

export default SortableItem;