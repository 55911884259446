import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ResetPasswordForm from './resetPasswordForm';
import ConfirmResetPasswordForm from './confirmResetPasswordForm';
import { useTranslation } from 'react-i18next';
import SuspenseView from '../../components/suspense-view';

export const ResetPasswordPage = (props) => {

  const { t, ready } = useTranslation(['common', 'form', 'authentication']);

  const confirmEmail = _.get(props, 'match.params.confirmEmail', '');
  const showConfirmResetPasswordForm = !!confirmEmail;
  const showResetPasswordForm = !showConfirmResetPasswordForm;

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div className={props.classes.formContainer}>
      { showResetPasswordForm && <ResetPasswordForm {...props} t={t} /> }
      { showConfirmResetPasswordForm && <ConfirmResetPasswordForm {...props} email={confirmEmail} t={t} /> }
    </div>
  );
};

ResetPasswordPage.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ResetPasswordPage;
