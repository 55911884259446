import * as actionTypes from './locationExplanationDialog.action.types';

export const showLocationExplanationDialog = () => ({
    type: actionTypes.ACTION_SHOW_LOCATION_EXPLANATION_DIALOG,
});

export const hideLocationExplanationDialog = accepted => ({
    type: actionTypes.ACTION_HIDE_LOCATION_EXPLANATION_DIALOG,
    payload: accepted, // Note: this payload is only used in { createFileEntrySaga } from store.file.sagas
});
