import ViewFile from './viewFile';
import materialStyles from './viewFile.materialStyles';
import  { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { selectViewFile, selectUserDashboardFolderId, selectUser } from '../../../../store/store.selectors';
import { viewFile, setContextMenuItems, createFileEntry, showInfoSnackbar, downloadFileEntryAttachment, } from '../../../../store/store.actions';
import { navViewFile, navEditFile, navCreateFileEntry, navViewFileEntry, } from '../../../../nav/nav.actions';
import { showClaimFileDialog, showUnclaimFileDialog, } from '../../file/view-file/claim-unclaim-file-dialog/claimUnclaimFileDialog.actions';
import { showAddFileToDashboardDialog } from '../../file/view-file/add-file-to-dashboard-dialog/addFileToDashboardDialog.actions';
import { connect } from 'redhooks';
import { compose } from '../../../../utils/func';
import withGA from '../../../../utils/withGA'

const mapStateToProps = (state, props) => ({
    dashboardFolderId: selectUserDashboardFolderId(state),
    file: selectViewFile(state),
    user: selectUser(state),
});

export default compose(
    withRouter,
    withStyles(materialStyles),
    connect(mapStateToProps, {
        viewFile,
        setContextMenuItems,
        showClaimFileDialog,
        showUnclaimFileDialog,
        showAddFileToDashboardDialog,
        createFileEntry,
        showInfoSnackbar,
        downloadFileEntryAttachment,
        navViewFile,
        navEditFile,
        navCreateFileEntry,
        navViewFileEntry,
    }),
    withGA,
  )(ViewFile);