import React from 'react';
import materialStyles from './mainContentContainer.materialStyles';
import { withStyles } from '@material-ui/core/styles';

const MainContentContainer = ({ children, classes }) => (
    <div className={classes.mainContentContainer}>
      {children}
    </div>
);

export default withStyles(materialStyles)(MainContentContainer);