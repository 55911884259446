import * as actionTypes from './dashboard.action.types';

export const REDUCER_NAME = 'dashboard';

const initialState = {
    folderContentToolbar: {},
    errorMessage: '',
    contextMenu: {
        isOpen: false,
    },
    removeDialog: {
        showRemoveDialog: false,
        removeDialogFolderItemId: '',
    },
    renameDialog: {
        showRenameDialog: false,
        renameDialogFolderItemId: '',
    },
    showCreateFolderDialog: false,
};

const dashboardReducer = (state=initialState, { type, payload }) => {
    switch(type) {
        case actionTypes.ACTION_SET_FOLDER_CONTENT_TOOLBAR:
            return {
                ...state,
                folderContentToolbar: payload
            };
        case actionTypes.ACTION_SET_ERROR_MESSAGE:
        return {
            ...state,
            errorMessage: payload
        };
        case actionTypes.ACTION_SET_CONTEXT_MENU:
            return {
                ...state,
                contextMenu: payload,
            };
        case actionTypes.ACTION_SET_SHOW_REMOVE_DIALOG:
            return {
                ...state,
                removeDialog: {
                    showRemoveDialog: payload.show,
                    removeDialogFolderItemId: payload.folderId,
                },
            };
        case actionTypes.ACTION_SET_SHOW_RENAME_DIALOG:
            return {
                ...state,
                renameDialog: {
                    showRenameDialog: payload.show,
                    renameDialogFolderItemId: payload.folderId,
                },
            };
        case actionTypes.ACTION_SET_SHOW_CREATE_FOLDER_DIALOG: 
            return {
                ...state,
                showCreateFolderDialog: payload,
            };
        default:
            return state;
    }
};

export default dashboardReducer;