import React, { useEffect } from 'react';
import PageContainer from '../../common/page-container';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Interweave from 'interweave';
import SuspenseView from '../../suspense-view';

const Legal = ({ setContextMenuItems, classes, history }) => {

    const { t, ready } = useTranslation('legal');
    
    useEffect(() => {setContextMenuItems([])}, []);

    if (!ready) {
        return <SuspenseView />;
    }

    return (
        <PageContainer theme="light">
            <div className={classes.container}>
                <Typography color='primary' noWrap={true} variant='h12' style={{ textAlign:"center"}}><Interweave content={t('legal:top_heading')} /></Typography>
                    <div style={{ textAlign:"center"}}>
                    <Link           
                        component='button'
                        variant='button'
                        color='textPrimary'
                        underline='always'
                        onClick={() => {
                        history.goBack();
                        }}>
                        {t('legal:btn_home')}
                    </Link>
                    </div>
                <Typography color='textPrimary' style={{ textAlign:"center"}}><Interweave content={t('legal:lbl_punch')} /></Typography>
                <Typography color='textPrimary' style={{ textAlign:"center"}}><Interweave content={t('legal:lbl_consent')} /></Typography>
                <Typography color='textPrimary' style={{ textAlign:"center"}}><Interweave content={t('legal:lbl_date')} /></Typography>
                <div style={{marginRight: 10, marginLeft: 10, textAlign: 'left'}}>
                    <Typography color='primary'><Interweave content={t('legal:lbl_cookies')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_cookies')} /></Typography>
                    <Typography color='primary'><Interweave content={t('legal:lbl_terms')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_account_information')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_account_information')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_public_content')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_public_content')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_communications')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_communications')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_website_activity')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_website_activity')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_files_and_entries_into_files')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_files_and_entries_into_files')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_service_providers')} /></Typography>               
                    <Typography color='textPrimary'><Interweave content={t('legal:info_service_providers')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_aggregate_information')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_aggregate_information')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_investigations')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_investigations')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_links')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_links')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_third_party_usage')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_third_party_usage')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_control')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_control')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_data_retention')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_data_retention')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_children')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_children')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:lbl_security')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_security')} /></Typography>
                    <Typography color='primary'><Interweave content={t('legal:lbl_modifications')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_modifications_top')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_modifications_middle')} /></Typography>
                    <Typography color='textPrimary'><Interweave content={t('legal:info_modifications_bottom')} /></Typography>   
                </div>
            </div>
        </PageContainer>

    );
};

 
export default Legal;  