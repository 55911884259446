import * as actionTypes from './userList.action.types';

const initialState = {
    isShowing: false,
    enable: false, 
    selectedUserId: ""
};

export const REDUCER_NAME = 'enableDisableUserDialog';

const enableDisableUserDialogReducer = (state=initialState, action) => {
    switch(action.type) {
        case actionTypes.ACTION_SHOW_ENABLE_USER_DIALOG:

            return {
                isShowing: true,
                enable: true,
                selectedUserId: action.payload.userId
            };
        case actionTypes.ACTION_SHOW_DISABLE_USER_DIALOG:
            return {
                isShowing: true,
                enable: false,
                selectedUserId: action.payload.userId
            };
        case actionTypes.ACTION_HIDE_ENABLE_DISABLE_USER_DIALOG:
            return {
                isShowing: false,
                selectedUserId: ""
            };
        default:
            return state;
    }
};

export default enableDisableUserDialogReducer;


