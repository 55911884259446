import React from 'react';
import Typography  from '@material-ui/core/Typography';
import materialStyles from './materialStyles';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const UserListHeader = ({ classes }) => {

    const { t } = useTranslation('users');

    return (
        <Typography className={classes.header} color='textPrimary' variant='h6'>
            { t('users:header') }
        </Typography>
    );
};

export default withStyles(materialStyles)(UserListHeader);