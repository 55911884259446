import React, {useEffect} from 'react';
import List from '@material-ui/core/List';
import UserListItem from '../user-list-item';
import UserListHeader from '../user-list-header';
import PageContainer from '../../../common/page-container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography  from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import SuspenseView from '../../../suspense-view';

const UserList = ({isShowing, 
                  enable, 
                  selectedUserId, 
                  userList, 
                  getUserList, 
                  showEnableUserDialog, 
                  hideEnableDisableUserDialog, 
                  showDisableUserDialog, 
                  setEnableUser, 
                  setDisableUser,
                  setContextMenuItems,
                  viewUserDashboard,
                  classes,
                }) => {

    const { t, ready } = useTranslation(['users','common']);

    let enableTitle = t('users:dialog_title_enable_user');
    let disableTitle = t('users:dialog_title_disable_user');
    let enableMessage = t('users:dialog_msg_enable_user');
    let disableMessage = t('users:dialog_msg_disable_user');
    let btnEnable = t('users:btn_user_enable');
    let btnDisable = t('users:btn_user_disable');


    useEffect(() => {
        getUserList();
        setContextMenuItems([]);
     }, []);


    const handleToggleEnableUser = (userId, active) => {
        if (!active) {
            showEnableUserDialog({ userId: userId });
        } else if (active) {
            showDisableUserDialog({ userId: userId });
        } else {
            return null;
        }
    }


    const handleActionClick = () => {
        if (enable){
            setEnableUser({ userId: selectedUserId, successMessage: t('users:msg_user_enable') })
        }else{
            setDisableUser({ userId: selectedUserId, successMessage: t('users:msg_user_disable') })
        } 
    };

    const handleViewUserDashboard = email => {
        viewUserDashboard(email);
    };

    if (!ready) {
        return <SuspenseView />;
    }
   
    return (
        <PageContainer theme='light'>
            <div className={classes.container}>
                <UserListHeader />
                <List >
                    { userList.map(user =>
                        <UserListItem key={user.id}
                            id={user.id}
                            username={user.display_name}
                            email={user.email}
                            active={user.active}
                            industry={user.industry}
                            handleToggle={handleToggleEnableUser}
                            handleViewUserDashboard={handleViewUserDashboard} />
                    )}
                </List>
            </div>
            <Dialog open={isShowing}
                onClose={hideEnableDisableUserDialog}
                aria-labelledby='enable-user-dialog-title'
                aria-describedby='enable-user-dialog-description'
                maxWidth='xs'>
                <DialogTitle id='enable-user-dialog-title' disableTypography={true}>
                <Typography variant='h6' color='inherit'> { enable ? enableTitle : disableTitle } </Typography>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id='enable-user-dialog-description'>
                   { enable ? enableMessage : disableMessage }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hideEnableDisableUserDialog} color='primary' autoFocus>
                    {t('common:cancel')}
                </Button>
                <Button onClick={handleActionClick} color='primary'>
                    { enable ? btnEnable : btnDisable }
                </Button>
            </DialogActions>
        </Dialog>          
        </PageContainer>

    );
};

 
export default UserList;  