import * as actionTypes from './nav.action.types';

export const navDashboard = () => ({
    type: actionTypes.ACTION_NAV_DASHBOARD,
});

export const navViewFolder = ({ folderId }) => ({
    type: actionTypes.ACTION_NAV_VIEW_FOLDER,
    payload: { folderId },
});

export const navViewFile = ({ fileAlias, parentFolderId, parentFolderName, fromSearchResults, }) => ({
    type: actionTypes.ACTION_NAV_VIEW_FILE,
    payload: { fileAlias, parentFolderId, parentFolderName, fromSearchResults, },
});

export const navCreateFile = () => ({
    type: actionTypes.ACTION_NAV_CREATE_FILE,
});

export const navEditFile = ({ fileAlias, parentFolderId, parentFolderName, }) => ({
    type: actionTypes.ACTION_NAV_EDIT_FILE,
    payload: { fileAlias, parentFolderId, parentFolderName, },
});

export const navCreateFileEntry = ({ fileAlias, parentFolderId, parentFolderName, }) => ({
    type: actionTypes.ACTION_NAV_CREATE_FILE_ENTRY,
    payload: { fileAlias, parentFolderId, parentFolderName, },
});

export const navViewFileEntry = ({ fileAlias, fileEntryId, parentFolderId, parentFolderName, }) => ({
    type: actionTypes.ACTION_NAV_VIEW_FILE_ENTRY,
    payload: { fileAlias, fileEntryId, parentFolderId, parentFolderName, },
});

export const navSearch = () => ({
    type: actionTypes.ACTION_NAV_SEARCH,
});

export const navUsers = () => ({
    type: actionTypes.ACTION_NAV_USERS,
});
