import { awsApiRequest } from '../../utils/func';

/**
 *
 * @param {Object} data - the request data
 * @param {string} data.userId - (required) the id of the user we should do a file search for
 * @param {string} data.folderId - the folder id to do the file search in
 * @param {string} data.fileNameSearchTerm - the search term to match against the file names
 * @param {string} data.fileDescriptionSearchTerm - the search term to match against the file descriptions
 */
export const searchUserFiles = async ({ userId, folderId, fileNameSearchTerm, fileDescriptionSearchTerm }) => {

    if (!userId) {
        console.error(`api-search-user-files - Missing userId:${!userId}`);
        return {
            success: false,
            errorMessage: 'common:err_default'
        }
    }

    try {

        let apiPath = `/users/${userId}/files/search`;

        if (folderId || fileNameSearchTerm || fileDescriptionSearchTerm) {

            apiPath = `${apiPath}?`;

            if (folderId) {
                apiPath = `${apiPath}folder_id=${encodeURIComponent(folderId)}`;
            }
            if (fileNameSearchTerm) {
                apiPath = `${apiPath}${folderId ? '&' :''}contains_name=${encodeURIComponent(fileNameSearchTerm)}`;
            }
            if (fileDescriptionSearchTerm) {
                apiPath = `${apiPath}${fileNameSearchTerm ? '&' :''}contains_description=${encodeURIComponent(fileDescriptionSearchTerm)}`;
            }
        }

        return await awsApiRequest({
            method: 'GET',
            path: apiPath,
        });
    } catch (error) {
        return error;
    }
}