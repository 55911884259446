import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import TextField from '../../components/common/form/texField/textField';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import SelectField from '../../components/common/form/selectField/selectField';
import createPersistedState from 'use-persisted-state';
import { getData } from 'country-list';
import { getIndustries } from '../../api/industry/industry';
import { PASSWORD_FORMAT_REGEX, PASSWORD_FORMAT_CONFIG, PASSWORD_FORMAT_MAX_CHARS } from '../constants';
import { HAVE_SEEN_ADD_TO_HOME_SCREEN_DIALOG } from '../../components/pages/dashboard/add-to-home-screen-dialog/constants';

const useHaveSeenAddToHomeScreenDialog = createPersistedState(HAVE_SEEN_ADD_TO_HOME_SCREEN_DIALOG);

const countryOptions = _.map(getData(), country => {
  return { label: country.name, value: country.name }
});

const SignUpForm = ({ authApi, t, classes, history }) => {

    const [industries, setIndustries] = useState([]);
    const [fetchIndustriesError, setFetchIndustriesError] = useState('');
    const [haveSeenAddToHomeScreenDialog, setHaveSeenAddToHomeScreenDialog] = useHaveSeenAddToHomeScreenDialog(false); // eslint-disable-line no-unused-vars

    const fetchIndustries = async () => {
        const result = await getIndustries();
        if (result.success) {
          setIndustries(_.map(result.data, industry => {
            return { label: industry.name, value: industry.name }
          }));
        } else {
          setFetchIndustriesError(t(result.errorMessage));
        }
    };

    useEffect(() => {
        fetchIndustries();
    }, []);

    const initialValues = { email: '', name: '', country: '', industry: '', password: '', confirmPassword: '', };

    const passwordFormatTranslated = t('form:passwordFormat', PASSWORD_FORMAT_CONFIG);
    const requiredTranslated = t('form:required');

    const SignUpSchema = Yup.object().shape({
        email: Yup.string()
            .email(t('form:invalidEmail'))
            .required(requiredTranslated),
        name: Yup.string()
            .required(requiredTranslated),
        country: Yup.string()
            .required(requiredTranslated),
        industry: Yup.string()
            .required(requiredTranslated),
        password: Yup.string()
            .required(requiredTranslated)
            .matches(PASSWORD_FORMAT_REGEX, passwordFormatTranslated),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], t('form:passwordsDoNotMatch'))
            .required(requiredTranslated)
    });

  const onSubmit = async (values, actions) => {
    const result = await authApi.signUp({
      email: values.email,
      password: values.password,
      display_name: values.name,
      country: values.country,
      industry: values.industry,
    });

    if (result.success) {
      setHaveSeenAddToHomeScreenDialog(false); // reset the value to false, so that we can show it on the Dashboard page once the whole registration flow is complete
      actions.setSubmitting(false);
      authApi.goToPage(`/signup/${values.email}`);
    } else {

      const errorMessagesDisplayName = _.get(result, 'errorData.messages.display_name', []);
      const displayNameAlreadyInUse = _.some(errorMessagesDisplayName, message => _.includes(message, 'already in use'));
      const errorMessagesEmail = _.get(result, 'errorData.messages.email', []);
      const emailAlreadyInUse = _.some(errorMessagesEmail, message => _.includes(message, 'already created'))

      let errorMessage;
      if (displayNameAlreadyInUse) {
        errorMessage = t('authentication:err_display_name_already_in_use');
      } else if (emailAlreadyInUse) {
        errorMessage = t('authentication:err_email_already_in_use', { email: values.email });
      } else {
        errorMessage = t(result.errorMessage);
      }

      actions.setSubmitting(false);
      actions.setStatus({ msg: errorMessage });
    }
  };

  return (
    <div className={ classes.container }>
      <Formik
        initialValues={ initialValues }
        validationSchema={ SignUpSchema }
        onSubmit={ onSubmit }
        render={ ({ status, isSubmitting, isValid }) => (
          <Form>
            <Field name="email"
                   type="email"
                   required
                   component={ TextField }
                   label={ t('authentication:email') }/>
            <Field name="name"
                   type="text"
                   required
                   component={ TextField }
                   label={ t('authentication:display_name') }/>
            <Field name="country"
                   required
                   component={ SelectField }
                   label={ t('authentication:country') }
                   options={countryOptions} />
            <Field name="industry"
                   required
                   component={ SelectField }
                   label={ t('authentication:industry') }
                   options={industries} />
            <Field name="password"
                   type="password"
                   required
                   component={ TextField }
                   label={ t('authentication:password') }
                   maxLength={PASSWORD_FORMAT_MAX_CHARS} />
            <Field name="confirmPassword"
                   type="password"
                   component={ TextField }
                   label={ t('authentication:repeatPassword') }
                   maxLength={PASSWORD_FORMAT_MAX_CHARS} />
            <div className={ classes.formComponentVerticalSpace }>
                { status && status.msg && <FormHelperText error>{ t(status.msg) }</FormHelperText> }
                { !!fetchIndustriesError && <FormHelperText error>{ fetchIndustriesError }</FormHelperText> }
            </div>
            <div className={ classes.formLabelLinkMarginUp } >
              <Typography className={ classes.formLabelMarginLeft } variant='body2' color='textSecondary'>{t('authentication:lbl_terms')}</Typography>
              <Link
                component='button'
                variant='button'
                color='textPrimary'
                underline='always'
                onClick={() => {
                  history.push('/legal');
                }}>
                {t('authentication:btn_terms')}
              </Link>
            </div>
            <Button className={ classes.formComponentVerticalSpace } type="submit" color="primary" variant="contained" disabled={ isSubmitting } fullWidth>
              {t('authentication:btn_register')}
            </Button>           
          </Form>       
        ) }
      />
    </div>
  );
};

export default SignUpForm;
