import { put, takeLatest } from 'redux-saga/effects';
import { createSaga } from '../../../store/store.util.sagas';
import { ACTION_SHOW_RENAME_FOLDER_DIALOG, ACTION_SHOW_REMOVE_FOLDER_DIALOG, ACTION_HIDE_REMOVE_DIALOG, ACTION_HIDE_RENAME_DIALOG, ACTION_SHOW_CREATE_FOLDER_DIALOG, ACTION_HIDE_CREATE_FOLDER_DIALOG } from './dashboard.action.types';
import { setShowRemoveFolderDialog, setShowRenameFolderDialog, setShowCreateFolderDialog } from './dashboard.actions';

export function* showRemoveFolderDialogSaga({ payload }) {
    yield put(setShowRemoveFolderDialog({
        show: true,
        folderId: payload,
    }));
}

export function* hideRemoveFolderDialogSaga() {
    yield put(setShowRemoveFolderDialog({
        show: false,
    }));
}

export function* showRenameFolderDialogSaga({ payload }) {
    yield put(setShowRenameFolderDialog({
        show: true,
        folderId:  payload,
    }));
}

export function* hideRenameFolderDialogSaga() {
    yield put(setShowRenameFolderDialog({
        show: false,
    }));
}

export function* showCreateFolderDialogSaga() {
    yield put(setShowCreateFolderDialog(true));
}

export function* hideCreateFolderDialogSaga() {
    yield put(setShowCreateFolderDialog(false));
}

export default function* rootSaga() {
    yield takeLatest(ACTION_SHOW_REMOVE_FOLDER_DIALOG, createSaga(showRemoveFolderDialogSaga));
    yield takeLatest(ACTION_HIDE_REMOVE_DIALOG, hideRemoveFolderDialogSaga);
    yield takeLatest(ACTION_SHOW_RENAME_FOLDER_DIALOG, createSaga(showRenameFolderDialogSaga));
    yield takeLatest(ACTION_HIDE_RENAME_DIALOG, hideRenameFolderDialogSaga);
    yield takeLatest(ACTION_SHOW_CREATE_FOLDER_DIALOG, createSaga(showCreateFolderDialogSaga));
    yield takeLatest(ACTION_HIDE_CREATE_FOLDER_DIALOG, hideCreateFolderDialogSaga);
}