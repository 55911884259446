import _ from 'lodash';
import { call, put, race, select, takeLatest, take, } from 'redux-saga/effects';
import { fetchFolderContents, updateFolderContentOrder, createFolder, renameFolder, removeFolder, batchMove, batchRemove, removeFile, moveFileToFolder, exportFiles, } from '../api/folder/folder';
import { ACTION_GET_FOLDER_DATA, ACTION_REORDER_FOLDER_CONTENTS, ACTION_RENAME_FOLDER, ACTION_REMOVE_FOLDER, ACTION_CREATE_FOLDER, ACTION_COMPLETE_SELECT_FILES, ACTION_CANCEL_SELECT_FILES, ACTION_START_SELECT_FILES, ACTION_EXPORT_FILES, } from './store.action.types';
import { setFolderData, setFolderContents, setFolderName, setShowActivityIndicator, showInfoSnackbar, setSelectFilesInProgress, addFileToSelectedFileIds, setShowSelectedFilesActionFailed, } from './store.actions';
import { setErrorMessage, hideRenameDialog, hideRemoveDialog, setShowCreateFolderDialog, } from '../components/pages/dashboard/dashboard.actions';
import { selectOrderedFolderContents, selectUser, selectFolderContents, selectSelectedFileIds } from './store.selectors';
import { getUserSaga } from './store.user.sagas';
import { FOLDER_ITEM_TYPE_FOLDER, FOLDER_ITEM_TYPE_FILE } from '../constants';
import { SYSTEM_FOLDER_LOOKUP_NAMES } from '../api/folder/constants';
import { SELECT_FILES_MODE_MOVE } from './constants';
import { handleErrorIfSessionExpired, createSaga } from './store.util.sagas';

export const getError = error => {
    if (_.get(error, 'errorData.code') === 'error.resource_not_found') {
        return {
            errorMessage: 'common:err_folder_not_found'
        };
    } else {
        return {
            errorMessage: _.get(error, 'errorMessage')
        };
    }
};

export function* getFolderDataSaga({ payload: { folderId } }) {
    let user = yield select(selectUser);
    if (_.isEmpty(user)) {
        yield call(getUserSaga);
        user = yield select(selectUser);
    }

    if (!folderId) {
        // we are grabbing the user's dashboard folder
        folderId = _.get(user, `systemFolders.${SYSTEM_FOLDER_LOOKUP_NAMES.dashboard}.id`);
    }

    // Fetch the folder data
    const result = yield call(fetchFolderContents, { folderId });
    if (result.success) {
        yield put(setFolderData(result.data));
    } else {
        throw getError(result);
    }
}

const reorderArray = ({ originalArray, oldIndex, newIndex }) => {

    const movedItem = originalArray[oldIndex];
    const remainingItems = originalArray.filter((_, index) => index !== oldIndex);

    const reorderedItems = [
        ...remainingItems.slice(0, newIndex),
        movedItem,
        ...remainingItems.slice(newIndex)
    ];

    return reorderedItems;
}

export function* reorderFolderContentsSaga({ payload: { folderId, oldOrder, newOrder } }) {

    if (oldOrder === newOrder) return;

    const folderContents = yield select(selectOrderedFolderContents);

    if (_.get(folderContents, `${newOrder}.isReadOnly`, false)) {
        // user is not allowed to move a custom folder to the position of a system folder
        return;
    }

    yield put(setShowActivityIndicator(true));

    const newFolderContents = reorderArray({
        originalArray: folderContents,
        oldIndex: oldOrder,
        newIndex: newOrder,
    });
    _.each(newFolderContents, (folder, index) => folder.order = index);

    yield put(setFolderContents(_.keyBy(newFolderContents, 'id')));

    const result = yield call(updateFolderContentOrder, {
        folderId: folderId,
        order: newOrder,
    });

    if (!result.success) {
        // Put folder content back to previous state and inform the user
        const currentFolderContents = yield select(selectOrderedFolderContents);
        const previousFolderContent = reorderArray({
            originalArray: currentFolderContents,
            oldIndex: newOrder,
            newIndex: oldOrder,
        });
        _.each(previousFolderContent, (folder, index) => folder.order = index);
        yield put(setFolderContents(_.keyBy(previousFolderContent, 'id')));
        yield put(setErrorMessage('dashboard:failed_to_change_folder_order'));
    }

    yield put(setShowActivityIndicator(false));
}

export function* renameFolderSaga({ payload: { folderId, folderName } }) {
    yield put(hideRenameDialog());
    const result = yield call(renameFolder, { folderId, folderName, });
    if (result.success) {
        yield put(setFolderName({ folderId, folderName }));
    } else {
        const folderErrorMessages = _.get(result, 'errorData.messages.folderName', []);
        const folderNameExists = _.some(folderErrorMessages, message => _.includes(message, 'already exists in the parent folder'));
        if (folderNameExists) {
            yield put(setErrorMessage('common:err_folder_name_already_exists'));
        } else {
            throw getError(result);
        }
    }
}

export function* removeFolderSaga({ payload: { folderId } }) {

    yield put(hideRemoveDialog());
    yield put(setShowActivityIndicator(true));
    const result = yield call(removeFolder, { folderId });
    if (result.success) {
        // Get the current folder content
        const folderContent = yield select(selectFolderContents);
        // Filter out the removed folder and save the removedOrder
        const removedOrder = _.get(folderContent, `${folderId}.order`);
        const newFolderContent = _.omit(folderContent, folderId);
        // Decrement the order of the folder items with order > the removed folder's order
        _.each(newFolderContent, folderItem => (folderItem.order > removedOrder) ? folderItem.order = folderItem.order-1 : folderItem.order);
        // Update the state with the new folder orders
        yield put(setFolderContents(newFolderContent));
        yield put(setShowActivityIndicator(false));
    } else {
        throw getError(result);
    }
}

export function* createFolderSaga({ payload: { folderName, parentId, } }) {
    yield put(setShowCreateFolderDialog(false));
    yield put(setShowActivityIndicator(true));
    const user = yield select(selectUser);
    if (!parentId) {
        parentId = _.get(user, `systemFolders.${SYSTEM_FOLDER_LOOKUP_NAMES.dashboard}.id`);
    }
    const result = yield call(createFolder, { folderName: folderName, parentId: parentId });
    if (result.success) {
        // Grab the new folder and update its order (the backend updates the order after creation)
        const newFolder =  _.cloneDeep(result.data);
        newFolder.id = newFolder.folderId;
        newFolder.name = newFolder.folderName;
        newFolder.type = FOLDER_ITEM_TYPE_FOLDER;
        // Get the current folder content and make a copy of it
        const folderContent = yield select(selectFolderContents);
        const newFolderContent = _.cloneDeep(folderContent);
        // Increment the order of each folder item with order >= the new folder order
        _.each(newFolderContent, folderItem => (folderItem.order >= newFolder.order) ? folderItem.order = folderItem.order+1 : folderItem.order);
        // Add the newFolder to the newFolderContent
        _.set(newFolderContent, newFolder.id, newFolder);
        // Update the state with the new folder orders
        yield put(setFolderContents(newFolderContent));
        yield put(setShowActivityIndicator(false));
    } else {
        const folderNameErrorMessages = _.get(result, 'errorData.messages.folderName', []);
        const folderNameExists = _.some(folderNameErrorMessages, message => _.includes(message, 'already exists in the parent folder'));
        const parentIdErrorMessages = _.get(result, 'errorData.messages.parentId', []);
        const filterFolderAddError = _.some(parentIdErrorMessages, message => _.includes(message, 'sub folders to read only filter'));
        if (folderNameExists) {
            yield put(setShowActivityIndicator(false));
            yield put(setErrorMessage('common:err_folder_name_already_exists'));
        } else if (filterFolderAddError){
            yield put(setShowActivityIndicator(false));
            yield put(setErrorMessage('common:err_folder_unable_to_add_read_only'));
        } else {
            throw getError(result);
        }
    }
}

export function* selectFilesSaga({ payload: { skipToRace, mode, currentFolderId, fileId } }) {
    try {
        if (!skipToRace) {
            yield put(setSelectFilesInProgress({
                mode: mode,
                isInProgress: true,
                currentFolderId: currentFolderId,
            }));

            if (fileId) {
                yield put(addFileToSelectedFileIds(fileId));
            }
        }

        const { completeSelectFiles, cancelSelectFiles } = yield race({
            completeSelectFiles: take(ACTION_COMPLETE_SELECT_FILES),
            cancelSelectFiles: take(ACTION_CANCEL_SELECT_FILES),
        });

        let failed = false;
        let cancelled = false;
        let successMessage;
        if (!!completeSelectFiles) {

            const selectedFileIds = yield select(selectSelectedFileIds);

            if (_.get(selectedFileIds, 'length', 0) === 0) {
                throw new Error('No files selected');
            }

            let refreshFolderId;
            let result;
            if (mode === SELECT_FILES_MODE_MOVE) {
                const newFolderId = completeSelectFiles.payload.newFolderId;

                // Handle user trying to move the files to its current folder
                if (currentFolderId === newFolderId) {
                    throw new Error('Files already exists in folder.');
                }

                // Handle user is trying to move the files to a new folder
                yield put(setShowActivityIndicator(true));
                refreshFolderId = newFolderId;
                if (selectedFileIds.length > 1) {
                    result = yield call(batchMove, {
                        sourceFolderId: currentFolderId,
                        targetFolderId: newFolderId,
                        fileIds: selectedFileIds,
                    });
                    successMessage = 'dashboard:msg_success_files_move_request_in_progress';
                } else {
                    result = yield call(moveFileToFolder, {
                        sourceFolderId: currentFolderId,
                        targetFolderId: newFolderId,
                        fileId: selectedFileIds[0],
                    });
                    successMessage = 'dashboard:msg_success_file_moved_to_folder';
                }
            } else {
                // Handle mode=SELECT_FILES_MODE_REMOVE
                yield put(setShowActivityIndicator(true));
                refreshFolderId = currentFolderId;
                if (selectedFileIds.length > 1) {
                    result = yield call(batchRemove, {
                        folderId: currentFolderId,
                        fileIds: selectedFileIds,
                    });
                    successMessage = 'dashboard:msg_success_files_remove_request_in_progress';
                } else {
                    result = yield call(removeFile, {
                        folderId: currentFolderId,
                        fileId: selectedFileIds[0]
                    });
                    successMessage = 'dashboard:msg_success_file_removed_from_folder';
                }
            }

            if (result.success) {
                if (selectedFileIds.length === 1) {
                    // Reload the folder contents
                    yield call(getFolderDataSaga, {
                        payload: {
                            folderId: refreshFolderId,
                        },
                    });
                }
            } else {
                failed = true;
            }
        } else if (cancelSelectFiles) {
            cancelled = true;
            failed = false;
        }

        yield put(setShowActivityIndicator(false));
        if (failed) {
            throw new Error(`Select file(s) action failed for mode: ${mode}`);
        } else {
            yield put(setSelectFilesInProgress({
                isInProgress: false,
            }));
            if (!cancelled) {
                yield put(showInfoSnackbar(successMessage));
            }
        }
    } catch(error) {    
        const handled = yield call(handleErrorIfSessionExpired, error);
        if (!handled) {
            yield put(setShowSelectedFilesActionFailed(true));
            // We need to listen for a cancel/complete event again:
            yield call(selectFilesSaga,{
                payload: {
                    skipToRace: true,
                    mode: mode,
                    currentFolderId: currentFolderId,
                },
            });
        }        
    }
}

export function* exportFilesSaga({ payload: { folderId } }) {
    yield put(setShowActivityIndicator(true));
    const folderItems = yield select(selectOrderedFolderContents);
    // Note: a folder is sorted folders first, then files
    // this means if the last item in the folder is not a file, then there are no files in the folder.
    const containFiles = _.get(folderItems, `[${folderItems.length-1}].type`) === FOLDER_ITEM_TYPE_FILE;
    if (!containFiles) {
        const error = {
            errorMessage: 'common:snackbar_export_files_err_no_files_available_in_current_folder'
        };
        throw error;
    }
    const { email } = yield select(selectUser);
    const result = yield call(exportFiles, { userEmail: email, folderId });
    if (result.success) {
        yield put(setShowActivityIndicator(false));
        yield put(showInfoSnackbar({
            messageKey: 'common:snackbar_export_files_request_success',
            messageOptions: { email },
        }));
    } else {
        throw getError(result);
    }
}

export default function* rootSaga() {
    yield takeLatest(ACTION_GET_FOLDER_DATA, createSaga(getFolderDataSaga));
    yield takeLatest(ACTION_REORDER_FOLDER_CONTENTS, createSaga(reorderFolderContentsSaga));
    yield takeLatest(ACTION_CREATE_FOLDER, createSaga(createFolderSaga));
    yield takeLatest(ACTION_RENAME_FOLDER, createSaga(renameFolderSaga));
    yield takeLatest(ACTION_REMOVE_FOLDER, createSaga(removeFolderSaga));
    yield takeLatest(ACTION_START_SELECT_FILES, createSaga(selectFilesSaga));
    yield takeLatest(ACTION_EXPORT_FILES, createSaga(exportFilesSaga));
}
