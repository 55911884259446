import React from 'react';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Interweave from 'interweave';

const UserListItem = ({
    id,
    username,
    email,
    active,
    industry,
    handleToggle,
    handleViewUserDashboard,
}) => {

    let newLine = "\n";
    let details = email.concat(newLine,industry)

    const onToggle = () => {
        handleToggle(id, active);
    }
    
    const onViewUserDashboard = () => {
        handleViewUserDashboard(email);
    };

    return (
        <>
            <ListItem onClick={onViewUserDashboard} button>
                <ListItemText primary={
                    <Typography color='textPrimary' variant='body1' noWrap={true} style={{ marginRight: 10 }}>{username}</Typography>
                } secondary={
                    <Typography color='textSecondary' noWrap={true} style={{ marginRight: 10 }}><Interweave content={details} /></Typography>
                } />
                <ListItemSecondaryAction>
                    <Switch color='primary'
                        onChange={onToggle}
                        checked={active} />
                </ListItemSecondaryAction>
            </ListItem>
            <Divider variant='middle' />
        </>
    );
}

export default UserListItem;