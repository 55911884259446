import React from 'react';
import Fab from '@material-ui/core/Fab';
import CreateFolderIcon from '@material-ui/icons/CreateNewFolderOutlined';

const CreateFolderFab = ({ handleCreateFolder, classes }) => {
    return (
        <Fab className={classes.createFolderFab} color='primary' onClick={handleCreateFolder}>
            <CreateFolderIcon className={classes.createFolderIcon} />
        </Fab>
    );
}

export default CreateFolderFab;