import _ from 'lodash';
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import EntryListItemView from '../entry-list-item-view';
import { sectionStyles } from '../viewFile.styleConstants';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const EntryListView = ({ entries, handleViewEntry, handleDownloadAttachment, classes }) => {

    const { t } = useTranslation('file');

    if (_.get(entries, 'length', 0) === 0) {
        return null;
    }

    return (
        <div className={classes.container}>
            <Typography color='primary' variant='h6'>{t('file:header_section_history')}</Typography>
            <List disablePadding={true}>
                { entries.map(entry => (
                        <ListItem key={entry.entry_id} disableGutters={true}>
                            <EntryListItemView id={entry.entry_id}
                                username={entry.username}
                                location={entry.location}
                                timestamp={entry.timestamp}
                                overallRating={entry.overall_rating}
                                comment={entry.comment}
                                attachmentKey={entry.attachment_key}
                                handleViewEntry={handleViewEntry}
                                handleDownloadAttachment={handleDownloadAttachment} />
                        </ListItem>
                    ))
                }
            </List>
        </div>
    );
}

const styles = theme => ({
    container: sectionStyles(theme),
});

export default withStyles(styles)(EntryListView);