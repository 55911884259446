import * as actionTypes from './claimUnclaimFileDialog.action.types';

export const showClaimFileDialog = () => ({
    type: actionTypes.ACTION_SHOW_CLAIM_FILE_DIALOG,
});

export const hideClaimUnclaimFileDialog = () => ({
    type: actionTypes.ACTION_HIDE_CLAIM_UNCLAIM_FILE_DIALOG,
});

export const claimFile = fileAlias => ({
    type: actionTypes.ACTION_CLAIM_FILE,
    payload: fileAlias,
});

export const showUnclaimFileDialog = () => ({
    type: actionTypes.ACTION_SHOW_UNCLAIM_FILE_DIALOG,
});

export const unclaimFile = fileAlias => ({
    type: actionTypes.ACTION_UNCLAIM_FILE,
    payload: fileAlias,
});