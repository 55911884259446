import React from 'react';
import TextField from '../../components/common/form/texField/textField';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, FormHelperText, Typography } from '@material-ui/core';
import Interweave from 'interweave';

const ResetPasswordForm = ({ authApi, t, classes }) => {

    const initialValues = { email: '' };

    const SignUpSchema = Yup.object().shape({
        email: Yup.string()
            .email(t('form:invalidEmail'))
            .required(t('form:required')),
    });

  const onSubmit = async (values, actions) => {
    const { success, errorMessage } = await authApi.resetPassword(values.email);
    actions.setSubmitting(false);
    if (success) {
      authApi.goToPage(`/reset-password/${values.email}`);
    } else {
      actions.setStatus({ msg: t(errorMessage) });
    }
  };

  return (
    <div className={ classes.container }>
        <Typography className={ classes.formTitleVerticalSpace } variant='subtitle1' align='center' color="textSecondary"><Interweave content={t('authentication:info_password_reset')} /></Typography>
        <Formik
            initialValues={ initialValues }
            validationSchema={ SignUpSchema }
            onSubmit={ onSubmit }
            render={ ({ status, isSubmitting, isValid }) => (
            <Form className={ classes.formFieldsContainer }>
                <Field name="email"
                    type="email"
                    required
                    component={ TextField }
                    label={ t('authentication:email') }/>
                
                <div className={ classes.formComponentVerticalSpace }>
                    { status && status.msg && <FormHelperText error>{ t(status.msg) }</FormHelperText> }
                </div>
                <Button className={ classes.formComponentVerticalSpace } type="submit" color="primary" variant="contained" disabled={ isSubmitting } fullWidth>
                  {t('authentication:btn_reset_password')}
                </Button>
            </Form>
            ) }
        />
    </div>
  );
};

export default ResetPasswordForm;
