export default theme => ({
    addFileEntryFab: {
        position: 'fixed',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
        [theme.breakpoints.up('sm')]: {
            marginRight: `calc((((100vw - ${theme.breakpoints.values.sm}px)*0.5)px + ${theme.spacing.unit * 2}px))`,
        },
    },
    addFileEntryIcon: {
        fontSize: 32,
    },
});