import _ from 'lodash';
import { call, delay, put, select, take, } from 'redux-saga/effects';
import { OVERRIDE_LANDING_PAGE } from '../auth/constants';
import { showSessionExpiredDialog, hideSessionExpiredDialog, reset, showInfoSnackbar, setShowActivityIndicator } from './store.actions';
import { ACTION_CONTINUE_SESSION_EXPIRED, ACTION_CREATE_FOLDER, ACTION_REMOVE_FOLDER, ACTION_RENAME_FOLDER, ACTION_REORDER_FOLDER_CONTENTS, ACTION_START_SELECT_FILES } from './store.action.types';
import { browserHistory } from '../nav';
import { selectViewingUserDashboard, selectShowActivityIndicator } from './store.selectors';
import { ACTION_ADD_FILE_TO_DASHBOARD, ACTION_SHOW_ADD_FILE_TO_DASHBOARD_DIALOG } from '../components/pages/file/view-file/add-file-to-dashboard-dialog/addFileToDashboardDialog.action.types';
import { ACTION_NAV_CREATE_FILE } from '../nav/nav.action.types';
import { ACTION_SHOW_REMOVE_FOLDER_DIALOG, ACTION_SHOW_RENAME_FOLDER_DIALOG, ACTION_SHOW_CREATE_FOLDER_DIALOG } from '../components/pages/dashboard/dashboard.action.types';
import { isMobile } from 'react-device-detect';

/**
 * 
 * Navigates the user to the signin page if the status code from the server was a 401
 * 
 * @returns {boolean} true if the error was handled, false otherwise
 */
export function* handleErrorIfSessionExpired(error) {
    try {
        if (_.get(error, 'statusCode', -1) === 401) {
            const currentLocationPath = _.get(window, 'location.pathname', '/');
            yield put(showSessionExpiredDialog());
            yield take(ACTION_CONTINUE_SESSION_EXPIRED);
            yield put(hideSessionExpiredDialog());
            yield put(reset());
            yield call(browserHistory.replace, '/signin', {
                [OVERRIDE_LANDING_PAGE]: currentLocationPath,
            });
            return true;
        }
        return false;
    } catch(e) {
        return false;
    }
}

const DISABLED_ADMIN_ACTIONS = [
    ACTION_SHOW_CREATE_FOLDER_DIALOG,
    ACTION_CREATE_FOLDER,
    ACTION_SHOW_RENAME_FOLDER_DIALOG,
    ACTION_RENAME_FOLDER,
    ACTION_SHOW_REMOVE_FOLDER_DIALOG,
    ACTION_REMOVE_FOLDER,
    ACTION_REORDER_FOLDER_CONTENTS,
    ACTION_START_SELECT_FILES, // includes move and remove files
    ACTION_SHOW_ADD_FILE_TO_DASHBOARD_DIALOG,
    ACTION_ADD_FILE_TO_DASHBOARD,
    ACTION_NAV_CREATE_FILE,
];

const ERROR_DISABLED_ADMIN_ACTIONS = {
    errorMessage: 'common:err_admin_manage_action_not_allowed'
};

function* checkIsActionAllowed(actionType) {
    const viewingUserDashboard = yield select(selectViewingUserDashboard);
    if (!!_.get(viewingUserDashboard, 'email')) {
        if (_.some(DISABLED_ADMIN_ACTIONS, notAllowedActionType => notAllowedActionType === actionType)) {
            throw ERROR_DISABLED_ADMIN_ACTIONS;   
        }
    }
}

export function createSaga(saga) {
    return function*(action) {
        try {
            yield call(checkIsActionAllowed, action.type);
            yield call(saga, action);
        } catch(error) {
            const isShowingActivityIndicator = yield select(selectShowActivityIndicator);
            if (isShowingActivityIndicator) {
                yield put(setShowActivityIndicator(false));
            }
            const handled = yield handleErrorIfSessionExpired(error);
            if (!handled) {
                if ((action.type === ACTION_REORDER_FOLDER_CONTENTS) && isMobile) {
                    // Note: workaround for a very odd scenario where on mobile the snackbar doesn't show up when re-ordering folder fails:
                    // Adding a timeout does the trick, it most probably has to do with an animation clash between the "drag/drop library animation" and "material ui show snackbar"
                    yield delay(300);
                }
                yield put(showInfoSnackbar(_.get(error, 'errorMessage', 'common:err_default')));
            }
        }
    };
};
