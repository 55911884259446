
import InfoSnackbar from './infoSnackbar';
import { compose } from '../../utils/func';
import { withTranslation } from 'react-i18next';
import { connect } from 'redhooks';
import { hideInfoSnackbar} from '../../store/store.actions';
import { selectShowInfoSnackbar, selectInfoSnackbarMessageKey, selectInfoSnackbarMessageOptions } from '../../store/store.selectors';

const mapStateToProps = state => ({
    isOpen: selectShowInfoSnackbar(state),
    messageKey: selectInfoSnackbarMessageKey(state),
    messageOptions: selectInfoSnackbarMessageOptions(state),
});

export default compose(
    withTranslation(['common']),
    connect(mapStateToProps, {
        hideInfoSnackbar,
    }),
)(InfoSnackbar);