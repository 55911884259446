export default theme => ({
    mainContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: theme.breakpoints.values.sm,
        },
        backgroundColor: theme.palette.common.white,
        paddingBottom: theme.spacing.unit*2,
    },
})