import { withAuthentication } from '../authenticationContext';
import { withRouter } from 'react-router';
import SignUpPage from './signUpPage';
import { compose } from '../../utils/func';
import { withStyles } from '@material-ui/core';
import formMaterialStyles from '../../components/common/form/form.materialStyles';
import withGA from '../../utils/withGA'

export default compose(
  withRouter,
  withStyles(formMaterialStyles),
  withAuthentication,
  withGA,
)(SignUpPage);