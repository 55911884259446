import _ from 'lodash';
import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import DownloadAttachmentIcon from '../download-attachment-icon';
import { getDisplayTimestamp, getGoogleMapsUrl, getDisplayCoordinate } from '../util';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RATINGS } from '../../../../../api/file/constants';
import { isEdge, isFirefox } from 'react-device-detect';

const handleMapLinkEvent = e => e.stopPropagation(); // If the user clicks the 'open map' Link button we don't want the click event to bubble up to the ButtonBase click handler

const EntryListItemView = ({ id, username, timestamp, location, overallRating, comment, attachmentKey, handleViewEntry, handleDownloadAttachment, classes, }) => {

    const { t } = useTranslation('file');

    let googleMapsUrl;
    if (_.has(location, 'latitude') && _.has(location, 'longitude')) {
        googleMapsUrl = getGoogleMapsUrl(_.get(location, 'latitude'), _.get(location, 'longitude'));
    }

    let entryContainer;
    switch(overallRating) {
        case RATINGS.PASS:
            entryContainer = classes.entryContainerRatingPass;
            break;
        case RATINGS.FAIL:
            entryContainer = classes.entryContainerRatingFail;
            break;
        case RATINGS.CAUTION:
            entryContainer = classes.entryContainerRatingCaution;
            break;
        default:
            entryContainer = classes.entryContainerRatingNone;
            break;
    }

    const handleClickViewEntry = () => {
        handleViewEntry(id);
    };

    const onDownloadAttachment = e => {
        e.stopPropagation(); // If the user clicks the 'Download attachment' button we don't want the click event to bubble up to the ButtonBase click handler
        handleDownloadAttachment(attachmentKey);
    };

    return (
        <div className={classes.container}>
            <ButtonBase className={entryContainer} onClick={handleClickViewEntry}>
                <Typography className={classes.entryInfoBold} component='div'>{t('file:lbl_created_by')}<Typography className={classes.entryInfoNormal}>{username}</Typography></Typography>
                <Typography className={classes.entryInfoBold}>{getDisplayTimestamp(timestamp)}</Typography>
                { !!googleMapsUrl &&
                            <div className={classes.entryLocationContainer}>
                                <Typography className={classes.entryInfoBold} component='div' noWrap={true}>{t('file:lbl_lat')}<Typography className={classes.entryInfoNormal} noWrap={true}>{getDisplayCoordinate(location.latitude)}</Typography></Typography>
                                <Typography className={classes.entryInfoBold} component='div' noWrap={true}>{t('file:lbl_long')}<Typography className={classes.entryInfoNormal} noWrap={true}>{getDisplayCoordinate(location.longitude)}</Typography></Typography>
                                <div>
                                    (<Link color='primary' target='_blank' rel='noreferrer' href={googleMapsUrl} underline='always' onClick={handleMapLinkEvent}>{t('file:btn_map')}</Link>)
                                </div>
                            </div>
                }
                <div className={classes.entryCommentContainer}>
                    <Typography className={classes.entryComment} color='inherit'>{comment}</Typography>
                </div>
            </ButtonBase>
            { !!attachmentKey &&
                <IconButton className={classes.attachmentBtn} color='secondary' aria-label={t('file:btn_download_attachment')} onClick={onDownloadAttachment}>
                    <DownloadAttachmentIcon />
                </IconButton>
            }
        </div>
    );
};

const entryContainer = theme => ({
    padding: theme.spacing.unit*2,
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing.unit,
    },
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.secondary['100'],
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: '#F3F3F3',
    color: 'rgba(0, 0, 0, 0.87)',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
});

const entryCommentContainer = {
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    textAlign: 'left',
};

if (isEdge || isFirefox) {
    entryCommentContainer.wordBreak = 'break-all';
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    entryContainerRatingNone: entryContainer(theme),
    entryContainerRatingPass: {
        ...entryContainer(theme),
        borderColor: theme.palette.common.ratingPass,
        backgroundColor: theme.palette.common.ratingPassBg,
    },
    entryContainerRatingFail: {
        ...entryContainer(theme),
        display: 'flex',
        borderColor: theme.palette.common.ratingFail,
        backgroundColor: theme.palette.common.ratingFailBg,
    },
    entryContainerRatingCaution: {
        ...entryContainer(theme),
        borderColor: theme.palette.common.ratingCaution,
        backgroundColor: theme.palette.common.ratingCautionBg,
    },
    entryInfoBold: {
        display: 'flex',
        flexDirection: 'row',
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightMedium,
    },
    entryInfoNormal: {
        color: theme.palette.common.black,
        marginLeft: 3,
        marginRight: 3,
    },
    entryLocationContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    entryCommentContainer: entryCommentContainer,
    entryComment: {
        marginTop: theme.spacing.unit,
    },
    attachmentBtn: {
        position: 'absolute',
        top: 13, // MuiListItem padding Top (11) + 2 for the margin
        right: 2,
    },
});

export default withStyles(styles)(EntryListItemView);