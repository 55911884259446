import _ from 'lodash';
import React from 'react';
import CreateFolderFab from '../create-folder-fab';
import CreateRenameDialog from '../create-rename-dialog';
import { SYSTEM_FOLDER_LOOKUP_NAMES } from '../../../../api/folder/constants';
import { selectUser } from '../../../../store/store.selectors';
import { compose } from '../../../../utils/func';
import { connect } from 'redhooks';
import { selectShowCreateFolderDialog } from '../dashboard.selectors';
import { showCreateFolderDialog, hideCreateFolderDialog } from '../dashboard.actions';

const CreateFolder = ({ user, currentFolderId, isShowingCreateFolderDialog, showCreateFolderDialog, hideCreateFolderDialog, handleCreateFolder }) => {

    const disableCreateFolder = [
        _.get(user, `systemFolders.${SYSTEM_FOLDER_LOOKUP_NAMES.claims}.id`),
        _.get(user, `systemFolders.${SYSTEM_FOLDER_LOOKUP_NAMES.owned}.id`),
        _.get(user, `systemFolders.${SYSTEM_FOLDER_LOOKUP_NAMES.unclaimed}.id`),
        _.get(user, `systemFolders.${SYSTEM_FOLDER_LOOKUP_NAMES.builds}.id`),
        _.get(user, `systemFolders.${SYSTEM_FOLDER_LOOKUP_NAMES.problems}.id`),
    ];

    return (
        <>
            { !_.includes(disableCreateFolder, currentFolderId) && <CreateFolderFab handleCreateFolder={showCreateFolderDialog} /> }
            <CreateRenameDialog isOpen={isShowingCreateFolderDialog} handleClose={hideCreateFolderDialog} handleAction={handleCreateFolder} type='createFolder' />
        </>
    );
};

const mapStateToProps = state => ({
    user: selectUser(state),
    isShowingCreateFolderDialog: selectShowCreateFolderDialog(state),
});

export default compose(
    connect(mapStateToProps, {
        showCreateFolderDialog,
        hideCreateFolderDialog,
    }),
)(CreateFolder);