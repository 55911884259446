import ContextMenu from './contextMenu';
import { connect } from 'redhooks';
import { openContextMenu, closeContextMenu } from '../../store/store.actions';
import { selectIsContextMenuOpen, selectContextMenuItems, } from '../../store/store.selectors';

const mapStateToProps = state => ({
    isOpen: selectIsContextMenuOpen(state),
    menuItems: selectContextMenuItems(state),
});

export default connect(mapStateToProps, { openContextMenu, closeContextMenu })(ContextMenu);