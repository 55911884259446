import { normalize, schema } from 'normalizr';

// Folder Item schema
const folderItemSchema = new schema.Entity('folderItems');

// Folder Contents schema
const folderContentsSchema = [folderItemSchema];

export const normalizeFolderContents = (folders) => {
    return normalize(folders, folderContentsSchema);
}
