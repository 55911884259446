import React from 'react';
import Fab from '@material-ui/core/Fab';
import AddFileEntryIcon from '@material-ui/icons/Add';

const AddFileEntryFab = ({ handleAddFileEntry, classes }) => {

    return (
        <Fab className={classes.addFileEntryFab} color='primary' onClick={handleAddFileEntry}>
            <AddFileEntryIcon className={classes.addFileEntryIcon} />
        </Fab>
    );
}

export default AddFileEntryFab;