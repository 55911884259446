import { select, call, put, takeLatest, } from 'redux-saga/effects';
import { enableUser, disableUser } from '../../../../api/admin';
import { ACTION_ENABLE_USER, ACTION_DISABLE_USER } from './userList.action.types';
import { hideEnableDisableUserDialog } from './userList.actions';
import { setShowActivityIndicator, showInfoSnackbar } from '../../../../store/store.actions';
import { setUserList } from '../../../../store/store.actions';
import { selectUserList } from '../../../../store/store.selectors';
import { createSaga } from '../../../../store/store.util.sagas';

export function* enableUserSaga({ payload: { userId, successMessage } }) {
    yield put(hideEnableDisableUserDialog());
    yield put(setShowActivityIndicator(true));
    const result = yield call(enableUser, { userId: userId });
    if (result.success) {
        const users = yield select(selectUserList);
        const updatedUsers = users.map(user => {
          if (user.id === userId) {
              return {
                ...user,
                active: true,
              };
          }
          return user;
        });
        yield put(setUserList(updatedUsers));

        yield put(setShowActivityIndicator(false));
        yield put(showInfoSnackbar(successMessage));
    } else {
        throw result;
    }
}

export function* disableUserSaga({ payload: { userId, successMessage } }) {
    yield put(hideEnableDisableUserDialog());
    yield put(setShowActivityIndicator(true));
    const result = yield call(disableUser, { userId: userId });
    if (result.success) {
        const users = yield select(selectUserList);
        const updatedUsers = users.map(user => {
          if (user.id === userId) {
              return {
                ...user,
                active: false,
              };
          }
          return user;
        });
        yield put(setUserList(updatedUsers));
        yield put(setShowActivityIndicator(false));
        yield put(showInfoSnackbar(successMessage));
    } else {
        throw result;
    }
}

export default function* rootSaga() {
    yield takeLatest(ACTION_ENABLE_USER, createSaga(enableUserSaga));
    yield takeLatest(ACTION_DISABLE_USER, createSaga(disableUserSaga));
}
