import { sectionStyles, sectionTitleStyles, sectionCardStyles, } from '../viewFile.styleConstants';

export default theme => ({
    container: sectionStyles(theme),
    title: sectionTitleStyles(theme),
    entryContainer: {
        ...sectionCardStyles(theme),
        position: 'relative',
        minHeight: 66, // To leave enough space for the 'Download attachement' button when there is no Lat/Long showing
    },
    entryHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    entryInfoBold: {
        display: 'flex',
        flexDirection: 'row',
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightMedium,
    },
    entryInfoNormal: {
        color: theme.palette.common.black,
        marginLeft: 3,
        marginRight: 3,
    },
    entryLocationContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    entryRatingsContainer: {
        marginTop: theme.spacing.unit*2,
    },
    attachmentBtn: {
        position: 'absolute',
        top: 0,
        right: 2,
    },
});