import _ from 'lodash';
import React, { useState } from 'react';
import TextField from '../../components/common/form/texField/textField';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckboxField from '../../components/common/form/checkboxField/checkboxField';
import createPersistedState from 'use-persisted-state';
import { STATE_STAY_LOGGED_IN, OVERRIDE_LANDING_PAGE } from '../constants';
import { callFuncOnEnterKeyDown } from '../../utils/keyboard';
import { useTranslation } from 'react-i18next';
import SuspenseView from '../../components/suspense-view';

const useStayLoggedInState = createPersistedState(STATE_STAY_LOGGED_IN);

const SignInPage = ({ authApi, classes, history, match }) => {

  const { t, ready } = useTranslation(['common', 'form', 'authentication']);

  if (_.has(history, `location.state.${OVERRIDE_LANDING_PAGE}`)) {
    authApi.setLandingPage(_.get(history, `location.state.${OVERRIDE_LANDING_PAGE}`));
  }

  const email = _.get(match, 'params.email', '');

  const [stayLoggedIn, setStayLoggedIn] = useStayLoggedInState(false); // eslint-disable-line no-unused-vars
  const [confirmUsername, setConfirmUsername] = useState('');

  const initialValues = { email: email, password: '', stayLoggedIn: false };
  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form:invalidEmail'))
      .required(t('form:required')),
    password: Yup.string()
      .required(t('form:required'))
  });

  const onSubmit = async (values, actions) => {
    setStayLoggedIn(values.stayLoggedIn);
    const { success, awsErrorCode, challengeName, errorMessage } = await authApi.signIn(values.email, values.password);
    actions.setSubmitting(false);
    if (challengeName === 'NEW_PASSWORD_REQUIRED') {
      history.push(`/set-password/${values.email}`);
    } else if (success) {
      authApi.goToPage();
    } else {
      if (awsErrorCode === 'UserNotConfirmedException') {
        setConfirmUsername(values.email);
      } else {
        actions.setStatus({ msg: errorMessage });
      }
    }
  };

  const handleConfirmAccount = () => {
    authApi.resendSignUp(confirmUsername);
    authApi.goToPage(`/signup/${confirmUsername}`);
  };

  if (!ready) {
    return <SuspenseView />
  }

  return (
    <div className={ classes.formContainer }>
      <img className={ classes.formAppLogo }src={`${window.location.origin}/punch_icon_192.png`} alt='Punch It logo' />
      <Typography className={ classes.formLogoText } variant='h2' align='center'>{ t('common:punch') }</Typography>
      <div className={ classes.container }>
        <Formik
          initialValues={ initialValues }
          validationSchema={ SignInSchema }
          onSubmit={ onSubmit }
          render={ ({ status, isSubmitting, handleSubmit }) => (
            <Form className={ classes.formFieldsContainer } onKeyDown={e =>  callFuncOnEnterKeyDown(e, handleSubmit)}>
              <Field name='email'
                    label={ t('authentication:email') }
                    required
                    component={ TextField }
                    type='email' />
              <Field name='password'
                    label={ t('authentication:password') }
                    required
                    component={ TextField }
                    type='password' />
              { status && status.msg && <FormHelperText error>{ t(status.msg) }</FormHelperText> }
              <div className={ classes.formComponentContainer }>
                <Link
                  component='button'
                  variant='body2'
                  color='textSecondary'
                  underline='always'
                  onClick={() => {
                    history.push('/reset-password');
                  }}
                >
                  {t('authentication:forgotPassword')}
                </Link>
              </div>
              <Button className={ classes.formComponentVerticalSpace } type='submit' color='primary' variant='contained' disabled={ isSubmitting } fullWidth>
                {t('authentication:btn_log_in')}
              </Button>
              <div className={ classes.formComponentContainer }>
                <Field name='stayLoggedIn'
                       type='checkbox'
                       component={ CheckboxField }
                       label={ t('authentication:stay_logged_in') }/>
              </div>
            </Form>
          ) }
        />
        <Divider className={classes.formDivider} color='textSecondary' variant='middle' />
        <div className={ classes.formLabelLinkContainer }>
          <Typography className={ classes.formLabelMarginRight } variant='body2' color='textSecondary'>{t('authentication:sign_up_label')}</Typography>
          <Link
            component='button'
            variant='button'
            color='textPrimary'
            underline='always'
            onClick={() => {
              history.push('/signup');
            }}
          >
            {t('authentication:btn_link_sign_up')}
          </Link>
        </div>
        <Link
          component='button'
          variant='button'
          onClick={() => {
            window.open("https://www.youtube.com/channel/UCnfDNKSjG29i29o_6P8y56Q", "_blank");
          }}
        >
          <img title='Punch Verified' alt='Youtube Logo' src={`${window.location.origin}/logo_youtube.png`} height={30} width={30}></img>
        </Link>
      </div>
      <Dialog open={!!confirmUsername}
              onClose={handleConfirmAccount}
              aria-labelledby='confirm-account-dialog-title'
              aria-describedby='confirm-account-dialog-description'>
            <DialogTitle id='confirm-account-dialog-title' disableTypography={true}>
              <Typography variant='h6' color='inherit'>{t('authentication:dialog_title_account_not_confirmed_yet')}</Typography>
            </DialogTitle>
            <DialogContent>
            <DialogContentText id='confirm-account-dialog-description'>
              {t('authentication:dialog_message_account_not_confirmed_yet')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmAccount} color='primary' autoFocus>
              {t('common:ok')}
            </Button>
          </DialogActions>
      </Dialog>
    </div>
  );
};

export default SignInPage;
