import moment from 'moment-timezone';
import { isChrome, isIOS } from 'react-device-detect';

const DISPLAY_TIMESTAMP_FORMAT = 'MMMM D, YYYY - h:m A' // e.g. June 1, 2019 - 3:10 PM
const DISPLAY_TIMEZONE_FORMAT = 'z' // e.g. ADT (for Atlantic Daylight Time)

export const getDisplayTimestamp = (utcServerTimestamp) => {
    try {
        return `${moment.utc(utcServerTimestamp).local().format(DISPLAY_TIMESTAMP_FORMAT)} ${moment.tz(moment.tz.guess(true)).format(DISPLAY_TIMEZONE_FORMAT)}`;
    } catch(e) {
        return utcServerTimestamp;
    }
};

export const getDisplayCoordinate = (coordinate) => {
    try {
        return coordinate.toFixed(6);
    } catch(e) {
        return coordinate;
    }
};


const GOOGLE_MAPS_URL_BASE = 'https://www.google.com/maps/search/?api=1';

export const getGoogleMapsUrl = (lat, long) => {
    return `${GOOGLE_MAPS_URL_BASE}&query=${lat},${long}`;
};

export const getFileUrl = fileAlias => {
    return `${window.location.origin}/file/${fileAlias}`;
};

export const openAdvertiserUrl = url => {
    if (isIOS && isChrome) {
        window.open(url);
    } else {
        window.open(url, '_blank');
    }
}