import _ from 'lodash';
import { call, put, take, takeLatest, select } from 'redux-saga/effects';
import { createFile, updateFile, getFile, createFileEntry, getFileEntry, } from '../api/file';
import { ACTION_CREATE_FILE, ACTION_UPDATE_FILE, ACTION_VIEW_FILE, ACTION_EDIT_FILE, ACTION_CREATE_FILE_ENTRY, ACTION_VIEW_FILE_ENTRY, ACTION_DOWNLOAD_FILE_ENTRY_ATTACHMENT, } from './store.action.types';
import { showInfoSnackbar, setShowActivityIndicator, setViewFile, setEditFile, setViewFileEntry, } from './store.actions';
import { selectUser, selectViewFile, selectViewingUserDashboardId, selectUserDashboardFolderId } from './store.selectors';
import { getUserLocation } from '../utils/location';
import { STATE_HAVE_SEEN_LOCATION_PERMISSION_DIALOG, STATE_ALLOW_REQUEST_LOCATION_PERMISSION } from '../components/pages/file/view-file/location-explanation-dialog/constants';
import { showLocationExplanationDialog } from '../components/pages/file/view-file/location-explanation-dialog/locationExplanationDialog.actions';
import { ACTION_HIDE_LOCATION_EXPLANATION_DIALOG } from '../components/pages/file/view-file/location-explanation-dialog/locationExplanationDialog.action.types';
import { CLAIM_STATUS } from '../api/file/constants';
import { navViewFile, navViewFileEntry, } from '../nav/nav.actions';
import { downloadFileSaga, uploadFileSaga } from './store.storage.sagas';
import { createSaga } from './store.util.sagas';
import i18n from '../i18n/i18n';
import { FILE_ATTACHMENT_ACCESS_LEVEL, FILE_ATTACHMENT_CUSTOM_PATH, FILE_ENTRY_ATTACHMENT_ACCESS_LEVEL, FILE_ENTRY_ATTACHMENT_CUSTOM_PATH } from './constants';

export const getError = error => {
    if (_.get(error, 'errorData.code') === 'error.resource_not_found') {
        return {
            errorMessage: 'common:err_file_not_found',
        };
    } else {
        return {
            errorMessage: _.get(error, 'errorMessage', 'common:err_default'),
        };
    }
}

export function* createFileSaga({ payload: { fileData, history, } }) {

    yield put(setShowActivityIndicator(true));
    const user = yield select(selectUser);
    const claimStatus = user.isAdmin ? CLAIM_STATUS.UNCLAIMED : CLAIM_STATUS.CLAIMED;

    const requestData =  {
        name: fileData.name,
        description: fileData.description,
        advertiserUrl: fileData.advertiserUrl,
        questions: fileData.questions,
        userId: user.userId,
        claimStatus: claimStatus,
        numCopies: fileData.numCopies,
    };

    if (fileData.image) {
        const uploadAttachmentResult = yield call(uploadFileSaga, {
            file: fileData.image,
            accessLevel: FILE_ATTACHMENT_ACCESS_LEVEL,
            customPath: FILE_ATTACHMENT_CUSTOM_PATH,
        });

        if (_.has(uploadAttachmentResult, 'key')) {
            requestData.imageKey = uploadAttachmentResult.key;
        } else {
            // Upload failed inform the user
            const error = {
                errorMessage: 'common:err_file_upload_failed',
            };
            throw error;
        }
    }
    
    const result = yield call(createFile, requestData);
    if (result.success) {
        yield put(setShowActivityIndicator(false));
        let successMessage;
        if (user.isAdmin) {
            if (_.get(fileData, 'numCopies', 1) > 1) {
                successMessage = 'file:info_success_create_file_copies_in_progress';
            } else {
                successMessage = 'file:info_snackbar_new_file_created_admin';
            }
        } else {
            successMessage = 'file:info_snackbar_new_file_created';
        }
        yield put(showInfoSnackbar(successMessage));
        yield call(history.replace, '/dashboard');
    } else {
        throw getError(result);
    }
}

export function* updateFileSaga({ payload: { fileData, history, successMessage, } }) {

    yield put(setShowActivityIndicator(true));

    const requestData =  {
        currentAlias: fileData.currentAlias,
        name: fileData.name,
        description: fileData.description,
    };

    if (fileData.alias) {
        requestData.alias = fileData.alias;
    }

    if (fileData.advertiserUrl) {
        requestData.advertiserUrl = fileData.advertiserUrl;
    }

    if (fileData.image) {
        const uploadAttachmentResult = yield call(uploadFileSaga, {
            file: fileData.image,
            accessLevel: FILE_ATTACHMENT_ACCESS_LEVEL,
            customPath: FILE_ATTACHMENT_CUSTOM_PATH,
        });

        if (_.has(uploadAttachmentResult, 'key')) {
            requestData.imageKey = uploadAttachmentResult.key;
        } else {
            // Upload failed inform the user
            const error = {
                errorMessage: 'common:err_file_upload_failed',
            };
            throw error;
        }
    }

    const result = yield call(updateFile, requestData);
    if (result.success) {
        yield put(setEditFile(result.data)); // Update the current edit file data
        yield put(setViewFile(result.data)); // Set the view file data (before we navigate to the view file screen)
        yield put(setShowActivityIndicator(false));
        yield put(showInfoSnackbar(successMessage));

        //Navigate to view the updated file
        const viewingUserDashboardId = yield select(selectViewingUserDashboardId);
        const signedInUserDashboardId = yield select(selectUserDashboardFolderId);
        yield put(navViewFile({
            fileAlias: fileData.alias,
            parentFolderId: _.get(history, 'location.state.parentFolderId', viewingUserDashboardId ? viewingUserDashboardId : signedInUserDashboardId),
            parentFolderName: _.get(history, 'location.state.parentFolderName', i18n.t('common:dashboard')),
        }));
    } else {
        const fileErrorMessages = _.get(result, 'errorData.messages.alias', []);
        const aliasExists = _.some(fileErrorMessages, message => _.includes(message, 'already exists'));
        if (aliasExists) {
            yield put(setShowActivityIndicator(false));
            yield put(showInfoSnackbar('file:lbl_err_alias_exists'));
        } else {
            throw getError(result);
        }
    }
}

export function* viewFileSaga({ payload: { fileAlias } }) {
    yield put(setShowActivityIndicator(true));
    const result = yield call(getFile, fileAlias);
    if (result.success) {
        yield put(setViewFile(result.data));
        yield put(setShowActivityIndicator(false));
    } else {
        throw getError(result);
    }
}

export function* editFileSaga({ payload: { fileAlias } }) {

    yield put(setShowActivityIndicator(true));
    const result = yield call(getFile, fileAlias);
    if (result.success) {
        yield put(setEditFile(result.data));
        yield put(setShowActivityIndicator(false));
    } else {
        throw getError(result);
    }
}

export function* createFileEntrySaga({ payload: { fileEntryData, history, successMessage, } }) {
    // We want to grab the user device's location:
    // First we need to show an informative location permission dialog to the user if they haven't seen it yet.
    const haveSeenLocationPermissionDialog = localStorage && (localStorage.getItem(STATE_HAVE_SEEN_LOCATION_PERMISSION_DIALOG) === 'true');
    // Note: we need to grab the accepted/declined payload from the hide location dialog action,
    // we need this the first time the user accepts/declines the dialog since saving an item to localStorage
    // is asynchronous and we have no way of knowing when the STATE_ALLOW_REQUEST_LOCATION_PERMISSION was succesfully saved (using the use-persisted-state library)
    let acceptedLocationReason;
    if (!haveSeenLocationPermissionDialog) {
        yield put(showLocationExplanationDialog());
        const { payload } = yield take(ACTION_HIDE_LOCATION_EXPLANATION_DIALOG); // wait for user to cancel or accept the dialog
        acceptedLocationReason = payload;
    }
    // Now that the location dialog is hidden, let's show the loading dialog
    yield put(setShowActivityIndicator(true));
    // Grab the device location if the user allowed the location reason dialog
    const allowRequestLocationPermission = (acceptedLocationReason || (localStorage && (localStorage.getItem(STATE_ALLOW_REQUEST_LOCATION_PERMISSION) === 'true')));
    let userLocationResult;
    if (allowRequestLocationPermission) {
        userLocationResult = yield call(getUserLocation);
        if (_.has(userLocationResult, 'error')) {
            console.log('getUserLocation error: ', _.get(userLocationResult, 'error'));
        }
    }

    let location;
    if (!!userLocationResult && !_.has(userLocationResult, 'error')) {
        location = userLocationResult;
    }

    const user = yield select(selectUser);
    const requestData = {
        ...fileEntryData,
        userId: user.userId,
        username: user.displayName,
        location: location
    };

    if (fileEntryData.attachment) {
        const uploadAttachmentResult = yield call(uploadFileSaga, {
            file: fileEntryData.attachment,
            accessLevel: FILE_ENTRY_ATTACHMENT_ACCESS_LEVEL,
            customPath: FILE_ENTRY_ATTACHMENT_CUSTOM_PATH,
        });

        if (_.has(uploadAttachmentResult, 'key')) {
            requestData.attachmentKey = uploadAttachmentResult.key;
        } else {
            // Upload failed inform the user
            const error = {
                errorMessage: 'common:err_entry_file_upload_failed',
            };
            throw error;
        }
    }

    const result = yield call(createFileEntry, requestData);
    if (result.success) {
        const entryId = _.get(result, 'data.[0].entry_id'); // The latest entry will be in the first position of the array of entries returned
        console.log('TEST-LOG:File Entry Created with id: ', entryId);
        // Updated the currently viewed file's file_entries with the result returned from the server
        const viewFile = yield select(selectViewFile);
        yield put(setViewFile({
            ...viewFile,
            file_entries: _.get(result, 'data', []),
        }));
        // Update the entry to be viewed with the newly created entry returned (i.e. the entry at position 0)
        yield put(setViewFileEntry(_.get(result, 'data.[0]')));
        yield put(setShowActivityIndicator(false));
        yield put(showInfoSnackbar(successMessage));

        // Navigate to viewing the created file entry
        const viewingUserDashboardId = yield select(selectViewingUserDashboardId);
        const signedInUserDashboardId = yield select(selectUserDashboardFolderId);
        yield put(navViewFileEntry({
            fileAlias: fileEntryData.fileAlias,
            fileEntryId: entryId,
            parentFolderId: _.get(history, 'location.state.parentFolderId', viewingUserDashboardId ? viewingUserDashboardId : signedInUserDashboardId),
            parentFolderName: _.get(history, 'location.state.parentFolderName', i18n.t('common:dashboard')),
        }));
    } else {
        throw getError(result);
    }
}

export function* viewFileEntrySaga({ payload: { fileAlias, entryId } }) {
    try {
        yield put(setShowActivityIndicator(true));
        const result = yield call(getFileEntry, { fileAlias, entryId });
        if (result.success) {
            yield put(setViewFileEntry(result.data));
            yield put(setShowActivityIndicator(false));
        } else {
            throw result;
        }
    } catch(error) {
        let errorMessage;
        if (_.get(error, 'errorData.code') === 'error.resource_not_found') {
            errorMessage = 'common:err_file_entry_not_found';
        } else {
            errorMessage = _.get(error, 'errorMessage', 'common:err_default');
        }
        _.set(error, 'errorMessage', errorMessage);
        throw error;
    }
}

export function* downloadFileEntryAttachmentSaga({ payload: { attachmentKey } }) {
    yield call(downloadFileSaga, {
        s3Key: attachmentKey,
        accessLevel: FILE_ENTRY_ATTACHMENT_ACCESS_LEVEL,
        customPath: FILE_ENTRY_ATTACHMENT_CUSTOM_PATH,
    });
}

export default function* rootSaga() {
    yield takeLatest(ACTION_CREATE_FILE, createSaga(createFileSaga));
    yield takeLatest(ACTION_UPDATE_FILE, createSaga(updateFileSaga));
    yield takeLatest(ACTION_VIEW_FILE, createSaga(viewFileSaga));
    yield takeLatest(ACTION_EDIT_FILE, createSaga(editFileSaga));
    yield takeLatest(ACTION_CREATE_FILE_ENTRY, createSaga(createFileEntrySaga));
    yield takeLatest(ACTION_VIEW_FILE_ENTRY, createSaga(viewFileEntrySaga));
    yield takeLatest(ACTION_DOWNLOAD_FILE_ENTRY_ATTACHMENT, downloadFileEntryAttachmentSaga);
}