import { imageWidth, imagePaddingTop, imageBackgroundColor, } from './constants';

export default theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        [theme.breakpoints.up('sm')]: {
            width: theme.breakpoints.values.sm,
        },
    },
    imgPlaceholder: {
        width: imageWidth,
        paddingTop: imagePaddingTop,
        position: 'relative',
    },
    activityIndicatorContainer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: imageBackgroundColor,
        color: theme.palette.secondary['100'],
    },
    headerTxtContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit*2,
        paddingRight: theme.spacing.unit*2,
        wordBreak: 'break-word',
    },
});