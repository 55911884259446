import * as actionTypes from './addFileToDashboardDialog.action.types';

export const showAddFileToDashboardDialog = () => ({
    type: actionTypes.ACTION_SHOW_ADD_FILE_TO_DASHBOARD_DIALOG,
});

export const hideAddFileToDashboardDialog = () => ({
    type: actionTypes.ACTION_HIDE_ADD_FILE_TO_DASHBOARD_DIALOG,
});

export const setShowAddFileToDashboardDialog = show => ({
    type: actionTypes.ACTION_SET_SHOW_ADD_FILE_TO_DASHBOARD_DIALOG,
    payload: show,
});

export const addFileToDashboard = payload => ({
    type: actionTypes.ACTION_ADD_FILE_TO_DASHBOARD,
    payload: payload,
});
