import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Interweave from 'interweave';
import materialStyles from '../form.materialStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IncrementIcon from '@material-ui/icons/ArrowDropUp';
import DecrementIcon from '@material-ui/icons/ArrowDropDown';
import { withStyles, IconButton } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { compose } from '../../../../utils/func';

export const NumberField = (props) => {

  const { label, disabled, disableUnderline, min, max, form: { errors, touched }, field, theme, classes, t } = props;
  const showError = touched[field.name] && !!errors[field.name];

  let inputClasses;
  let inputThemeLightClasses;
  if (theme === 'light') {
    inputClasses = classes.numberInputThemeLight;
    inputThemeLightClasses = {
      root: classes.inputThemeLightRoot,
      disabled: classes.inputThemeLightDisabled,
      underline: classes.inputThemeLightUnderline,
    };
  } else {
    inputClasses = classes.numberInput;
  }

  let inputProps = {};
  if (_.isNumber(min)) {
    inputProps.min = min;
  }
  if (_.isNumber(max)) {
    inputProps.max = max;
  }

  const updateValue = newValue => {
    _.invoke(field, 'onChange', {
      target: {
        name: field.name,
        value: newValue,
      }
    });
  };

  const handleIncrementClick = () => {
    const currentValue = _.toNumber(field.value);
    if (isNaN(currentValue)) {
      return;
    }
    const newValue = currentValue + 1;
    if ((!max || (max && newValue <= max))) {
      updateValue(newValue);
    }
  };

  const handleDecrementClick = () => {
    const currentValue = _.toNumber(field.value);
    if (isNaN(currentValue)) {
      return;
    }
    const newValue = currentValue - 1;
    if ((!min || (min && newValue >= min))) {
      updateValue(newValue);
    }
  };

  return (
    <FormControl className={classes.formControl} error={showError} disabled={disabled}>
      <div className={classes.horizontalCenterContainer}>
        {
          !!label &&
          <Typography color={theme==='light' ? 'secondary' : 'textPrimary'} variant='subtitle1'>{label}</Typography>
        }
        <Input
          color='secondary'
          className={inputClasses}
          classes={inputThemeLightClasses}
          {...field}
          inputProps={inputProps}
          type='number'
          disabled={disabled}
          error={showError}
          disableUnderline={disableUnderline}
          endAdornment={
            <InputAdornment>
              <div className={classes.verticalContainer}>
                <IconButton className={classes.numberBtn} onClick={handleIncrementClick} aria-label={t('common:increment_number_field')}>
                  <IncrementIcon color='inherit' />
                </IconButton>
                <IconButton className={classes.numberBtn} onClick={handleDecrementClick} aria-label={t('common:decrement_number_field')}>
                  <DecrementIcon color='inherit' />
                </IconButton>
              </div>
            </InputAdornment>
          }/>
      </div>
      <FormHelperText className={classes.formHelperText} component='div'>
        <Interweave content={showError ? errors[field.name] : ''}/>
      </FormHelperText>
    </FormControl>
  );
};

NumberField.defaultProps = {
  disabled: false,
  theme: 'dark',
};

NumberField.propTypes = {
  label: PropTypes.node,
  input: PropTypes.any,
  disabled: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
  }),
  field: PropTypes.object,
  theme: PropTypes.oneOf(['light', 'dark']),
  min: PropTypes.number,
  max: PropTypes.number,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(['common']),
  withStyles(materialStyles),
)(NumberField);