import AddFileToDashboardDialog from './addFileToDashboardDialog';
import { selectIsShowing, } from './addFileToDashboardDialog.selectors';
import { hideAddFileToDashboardDialog, addFileToDashboard, } from './addFileToDashboardDialog.actions';
import { connect } from 'redhooks';

const mapStateToProps = state => ({
    isShowing: selectIsShowing(state),
});

export default connect(mapStateToProps, {
    hideAddFileToDashboardDialog,
    addFileToDashboard,
})(AddFileToDashboardDialog);