import PropTypes from 'prop-types';
import React, { useState } from 'react';
import materialStyles from '../form.materialStyles';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';

const CheckboxField = (props) => {

  const { label, disabled, classes, } = props;

  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckedState = () => {
    const { form, field } = props;
    const nextIsChecked = !isChecked;
    setIsChecked(nextIsChecked);
    form.setFieldValue(field.name, nextIsChecked)
  };

  return (
    <FormControlLabel className={classes.formControl} classes={{ label: classes.formControlCheckboxLabel }} disabled={disabled}
        control={
            <Checkbox
                color="primary"
                checked={isChecked}
                onChange={toggleCheckedState}
            />
        }
        label={label}
    />
  );
};

CheckboxField.defaultProps = {
  disabled: false,
};

CheckboxField.propTypes = {
  label: PropTypes.node,
  disabled: PropTypes.bool,
  form: PropTypes.object,
  field: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default withStyles(materialStyles)(CheckboxField);