export const sectionStyles = theme => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing.unit*2,
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
    [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing.unit*2,
        marginRight: theme.spacing.unit*2,
    },
});

export const sectionTitleStyles = theme => ({
    marginBottom: theme.spacing.unit,
});

export const sectionCardStyles = theme => ({
    padding: theme.spacing.unit*2,
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing.unit,
    },
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.secondary['100'],
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: '#F3F3F3',
    color: 'rgba(0, 0, 0, 0.87)',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
}); 