import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import iOSActionIcon from '../../../../images/ios_share_icon.png';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import createPersistedState from 'use-persisted-state';
import { showAddToHomeScreenPrompt, isInstalledPWA } from '../../../../utils/func';
import { isMobileSafari } from 'react-device-detect';
import { HAVE_SEEN_ADD_TO_HOME_SCREEN_DIALOG } from './constants';

const useHaveSeenAddToHomeScreenDialog = createPersistedState(HAVE_SEEN_ADD_TO_HOME_SCREEN_DIALOG);

export default ({ t, classes }) => {

    // We are on iOS Safari show the informative 'Add to Home Screen' dialog (with instructions on how to add to Home Screen)
    const [isClosed, setIsClosed] = useState(false);
    const [haveSeenAddToHomeScreenDialog, setHaveSeenAddToHomeScreenDialog] = useHaveSeenAddToHomeScreenDialog(false); // eslint-disable-line no-unused-vars

    if (!isMobileSafari && !haveSeenAddToHomeScreenDialog) {
      // Show native 'Add to Home Screen' prompt if we are not on iOS Safari
      showAddToHomeScreenPrompt(setHaveSeenAddToHomeScreenDialog);
      return null;
    }

    const closeDialog = () => {
      setHaveSeenAddToHomeScreenDialog(true);
      setIsClosed(true);
    }

    if (isInstalledPWA() || haveSeenAddToHomeScreenDialog || isClosed) {
      return null;
    }

    return (
        <Dialog open={!isClosed}
              onClose={closeDialog}
              aria-labelledby="add-to-home-screen-dialog-title"
              aria-describedby="add-to-home-screen-dialog-description">
            <DialogTitle id="add-to-home-screen-dialog-title" classes={{ root: classes.dialogTitle}} disableTypography={true}>
              <Typography variant="h6" color="inherit">{t('dashboard:dialog_title_add_to_home_screen')}</Typography>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
              <div className={classes.dialogAppLogoContainer}>
                <img className={ classes.dialogAppLogo }src={`${window.location.origin}/punch_icon_192.png`} alt="Punch It logo" />
              </div>
              <DialogContentText id="add-to-home-screen-dialog-description">
                {t('dashboard:dialog_message_add_to_home_screen')}
              </DialogContentText>
              <div className={classes.dialogFootnote}>
                <Typography variant="inherit" color="inherit" align='center'>{t('dashboard:dialog_footnote_add_to_home_screen_part_1')}</Typography>
                <img className={classes.shareIcon} src={iOSActionIcon} alt={t('dashboard:dialog_foot_note_share_icon')} />
                <Typography variant="inherit" color="inherit" align='center'>{t('dashboard:dialog_footnote_add_to_home_screen_part_2')}</Typography>
              </div>
            </DialogContent>
        </Dialog>
    );
}

