import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import CreateFolder from './create-folder';
import SortableItem from './sortable-item/sortableItem';
import ErrorSnackbar from '../../common/error-snackbar';
import AddToHomeScreenDialog from './add-to-home-screen-dialog';
import PageContainer from '../../common/page-container';
import FolderContentToolbar from './folder-content-toolbar';
import MainContentContainer from '../../common/main-content-container';
import ContextMenu from './context-menu';
import DeleteDialog from './delete-dialog';
import CreateRenameDialog from './create-rename-dialog';
import MoveFilesIcon from '@material-ui/icons/FileCopy'
import RemoveFilesIcon from '@material-ui/icons/DeleteSweep';
import SortFilesAtoZIcon from '@material-ui/icons/ArrowUpward';
import SortFilesZtoAIcon from '@material-ui/icons/ArrowDownward';
import ExportFilesIcon from './export-files-icon';
import { SortFilesAtoZIconActive, SortFilesZtoAIconActive } from './sort-icon';
import SelectFilesSnackbar from './select-files-snackbar';
import { FOLDER_ITEM_TYPE_FOLDER, FOLDER_ITEM_TYPE_FILE } from '../../../constants';
import { getOrderedFolderContents } from './util';
import { SORT_TYPE } from '../../../store/constants';
import { useTranslation } from 'react-i18next';
import SuspenseView from '../../suspense-view';

const Dashboard = ({
    currentFolderId,
    currentFolderName,
    parentFolderId,
    parentFolderName,
    dashboardFolderId,
    contents,
    contentsSortType,
    sortFilesAtoZ,
    sortFilesZtoA,
    exportFiles,
    getFolderData,
    reorderFolderContents,
    errorMessage,
    contextMenu,
    removeDialog: {
      showRemoveDialog,
      removeDialogFolderItemId,
    },
    renameDialog: {
      showRenameDialog,
      renameDialogFolderItemId,
    },
    setErrorMessage,
    setContextMenu,
    showRemoveFolderDialog,
    hideRemoveDialog,
    showRenameFolderDialog,
    hideRenameDialog,
    createFolder,
    renameFolder,
    removeFolder,
    startMoveFiles,
    startRemoveFiles,
    setContextMenuItems,
    disabledFolderActionFolderIds,
    viewingOtherUserDashboardId,
    match,
}) => {

  const { t, ready } = useTranslation(['dashboard', 'common']);

  useEffect(() => {
    getFolderData({
      folderId: _.get(match, 'params.folderId'),
    });
  }, [match.params.folderId]);

  const handleMoveFiles = () => {
    startMoveFiles({
      currentFolderId: currentFolderId,
    });
  };

  const handleRemoveFiles = () => {
    startRemoveFiles({
      currentFolderId: currentFolderId,
    });
  };

  const handleExportFiles = () => {
    exportFiles({
      folderId: currentFolderId,
    });
  };

  useEffect(() => {

    const menuItems = [];

    if (!_.includes(disabledFolderActionFolderIds, currentFolderId)) {

      menuItems.push({
        text: t('common:context_menu_move_files'),
        icon: MoveFilesIcon,
        handleClick: handleMoveFiles,
        enabled: true,
      });

      menuItems.push({
        text: t('common:context_menu_remove_files'),
        icon: RemoveFilesIcon,
        handleClick: handleRemoveFiles,
        enabled: true,
      });

      menuItems.push({
        text: t('common:context_menu_sort_files_a_to_z'),
        icon: contentsSortType === SORT_TYPE.A_TO_Z ? SortFilesAtoZIconActive : SortFilesAtoZIcon,
        handleClick: sortFilesAtoZ,
        enabled: true,
      });

      menuItems.push({
        text: t('common:context_menu_sort_files_z_to_a'),
        icon: contentsSortType === SORT_TYPE.Z_TO_A ? SortFilesZtoAIconActive : SortFilesZtoAIcon,
        handleClick: sortFilesZtoA,
        enabled: true,
      });
    }

    menuItems.push({
      text: t('common:context_menu_export_files'),
      icon: ExportFilesIcon,
      handleClick: handleExportFiles,
      enabled: true,
    });

    setContextMenuItems(menuItems);

  }, [currentFolderId, disabledFolderActionFolderIds, contentsSortType]);

  const folderContents = useMemo(() => {
    if (_.isEmpty(contents)) {
      return [];
    }
    return getOrderedFolderContents(contents, contentsSortType);
  }, [contents, contentsSortType]);

  const shouldShowFolderContentToolbar = useMemo(() => {
    if (currentFolderId !== _.get(match, 'params.folderId')) {
      // Note: the new folder content is still being loaded (after navigating to a new folder)
      return false;
    }
    const isViewingSignedInUserDashboard = (!!currentFolderId && (currentFolderId === dashboardFolderId));
    const isViewingOtherUserDashboard = (!!currentFolderId && (currentFolderId === viewingOtherUserDashboardId));
    return !(isViewingSignedInUserDashboard || isViewingOtherUserDashboard);
  }, [match.params, currentFolderId, dashboardFolderId, viewingOtherUserDashboardId]);

  const handleDismissErrorMessage = () => setErrorMessage('');

  const handleCloseContextMenu = () => setContextMenu({ isOpen: false, });

  const handleRenameFolder = (folderItemId) => {
    handleCloseContextMenu();
    showRenameFolderDialog(folderItemId);
  }

  // Note: only the folders have the rename option
  const handleRename = (folderItemName) => {
    renameFolder({ folderId: renameDialogFolderItemId, folderName: folderItemName });
  }

  const handleRemoveFolder = (folderItemId) => {
    handleCloseContextMenu();
    showRemoveFolderDialog(folderItemId);
  }

  // Note: only the folders have the single remove option
  const handleRemove = () => {
    removeFolder({ folderId: removeDialogFolderItemId });
  }

  const handleMoveFile = fileId => {
    handleCloseContextMenu();
    startMoveFiles({
      currentFolderId: currentFolderId,
      fileId: fileId,
    });
  }

  const handleRemoveFile = folderItemId => {
    handleCloseContextMenu();
    startRemoveFiles({
      currentFolderId: currentFolderId,
      fileId: folderItemId,
    });
  }

  const handleShowFolderItemContextMenu = ({ folderItemId, folderItemType, eventCurrentTarget }) => {

    const menuOptionHandlers = {};

    if (folderItemType === FOLDER_ITEM_TYPE_FOLDER) {
      menuOptionHandlers.handleRename = handleRenameFolder;
      menuOptionHandlers.handleDelete = handleRemoveFolder;
    } else if (folderItemType === FOLDER_ITEM_TYPE_FILE) {
      menuOptionHandlers.handleMove = handleMoveFile;
      menuOptionHandlers.handleDelete = handleRemoveFile;
    } else {
      console.error('error: handleShowFolderItemContextMenu: Unknown folderItemType: ', folderItemType);
      return;
    }

    setContextMenu({
      folderItemId: folderItemId,
      anchorEl: eventCurrentTarget,
      isOpen: true,
      type: folderItemType,
      ...menuOptionHandlers
    });
  }

  const handleChangeFolderContentOrder = ({ id, oldIndex, newIndex }) => {
    reorderFolderContents({
      folderId: id,
      oldOrder: oldIndex, 
      newOrder: newIndex
    });
  }

  const handleCreateFolder = (folderName) => {
    createFolder({ folderName: folderName, parentId: _.get(match, 'params.folderId') });
  }

  if(!ready) {
    return <SuspenseView />;
  }

  return (
    <PageContainer>
      { shouldShowFolderContentToolbar &&
        <FolderContentToolbar parentFolderId={parentFolderId} parentFolderName={parentFolderName} currentFolderName={currentFolderName} />
      }
      <ErrorSnackbar error={t(errorMessage)} dismissError={handleDismissErrorMessage} dismissBtnTxt={t('common:btn_ok')} />
      <MainContentContainer>
        <SortableItem folderItems={folderContents}
          onChangeFolderContentOrder={handleChangeFolderContentOrder}
          onShowFolderItemContextMenu={handleShowFolderItemContextMenu}
          currentFolderId={currentFolderId}
          currentFolderName={currentFolderName} />
        <ContextMenu {...contextMenu} currentFolderId={currentFolderId} handleClose={handleCloseContextMenu} />
      </MainContentContainer>
      <CreateFolder currentFolderId={currentFolderId} handleCreateFolder={handleCreateFolder}/>
      <CreateRenameDialog type='renameFolder' isOpen={showRenameDialog} handleClose={hideRenameDialog} handleAction={handleRename} />
      <DeleteDialog isOpen={showRemoveDialog} handleClose={hideRemoveDialog} handleDelete={handleRemove} />
      <AddToHomeScreenDialog />
      <SelectFilesSnackbar currentFolderId={currentFolderId} />
    </PageContainer>
  );
};

export default Dashboard;