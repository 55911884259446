import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography  from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const AddFileToDashboardDialog = ({ isShowing, fileAlias, fileName, hideAddFileToDashboardDialog, addFileToDashboard }) => {

    const { t } = useTranslation(['common', 'file']);

    const handleAddClick = () => {
        addFileToDashboard({
            fileAlias: fileAlias,
            fileName: fileName,
            successMessage: t('file:msg_success_add_file_to_dashboard'),
        });
    };

    return (
        <Dialog open={isShowing}
                onClose={hideAddFileToDashboardDialog}
                aria-labelledby='add-file-to-dashboard-dialog-title'
                aria-describedby='add-file-to-dashboard-dialog-description'
                maxWidth='xs'>
                <DialogTitle id='add-file-to-dashboard-dialog-title' disableTypography={true}>
                <Typography variant='h6' color='inherit'>{ t('file:dialog_title_add_file_to_dashboard') }</Typography>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id='add-file-to-dashboard-dialog-description'>
                    { t('file:dialog_message_add_file_to_dashboard') }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hideAddFileToDashboardDialog} color='primary' autoFocus>
                    {t('common:cancel')}
                </Button>
                <Button onClick={handleAddClick} color='primary'>
                    { t('file:btn_add_file_to_dashboard') }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddFileToDashboardDialog;