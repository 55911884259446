import _ from 'lodash';
import * as actionTypes from './store.action.types';
import { normalizeFolderContents } from './store.schemas';
import { MAX_NUM_SELECTED_FILES, SORT_TYPE, } from './constants';
import { SYSTEM_FOLDER_LOOKUP_NAMES } from '../api/folder/constants';

const userInitialState = {};

export const user = (state=userInitialState, { type, payload }) => {
    switch(type) {
        case actionTypes.ACTION_SET_USER:
            if (payload === null || !payload.cognitoId) {
                return userInitialState;
            }

            return {
                cognitoId: payload.cognitoId,
                email: payload.email,
                userId: payload.userId,
                displayName: payload.displayName,
                systemFolders: payload.systemFolders,
                isAdmin: payload.isAdmin,
                // A list of folder ids for which the following folder actions should be disabled:
                // - create folder inside folder
                // - move file(s) from folder
                // - move file(s) to folder
                // - delete file(s) from folder
                // - sort files in folder
                disabledFolderActionFolderIds: _.map(
                    _.filter(_.get(payload, 'systemFolders', {}), folder => (
                            (folder.lookup_name !== SYSTEM_FOLDER_LOOKUP_NAMES.adds) && (folder.lookup_name !== SYSTEM_FOLDER_LOOKUP_NAMES.templates) && (folder.lookup_name !== SYSTEM_FOLDER_LOOKUP_NAMES.dashboard)
                        )
                    ),
                    folder => folder.id
                ),
            };
        case actionTypes.ACTION_RESET:
            return userInitialState;
        default:
            return state;
    }
};

const mainInitialState = {
    parentFolderId: '',
    parentFolderName: '',
    currentFolderId: '',
    currentFolderName: '',
    contents: {},
    contentsSortType: SORT_TYPE.DEFAULT,
    viewFile: {},
    editFile: {},
    viewFileEntry: {},
    userList: [],
    selectFiles: {
        mode: '', // move or remove - see the constants file
        isInProgress: false,
        currentFolderId: '',
        fileIds: [], // array of file ids
        showActionFailed: false,
    },
    viewingUserDashboard: {},
};

export const main = (state=mainInitialState, action) => {
    switch(action.type) {
        case actionTypes.ACTION_SET_FOLDER_DATA:

            const parentFolderId = _.get(action, 'payload.parentId', '');
            const parentFolderName = _.get(action, 'payload.parentName', '');
            const currentFolderId = _.get(action, 'payload.folderId', '');
            const currentFolderName = _.get(action, 'payload.folderName', '');
            const contents = _.get(action, 'payload.contents', []);

            return {
                ...state,
                parentFolderId: parentFolderId,
                parentFolderName: parentFolderName,
                currentFolderId: currentFolderId,
                currentFolderName: currentFolderName,
                contents: _.get(normalizeFolderContents(contents), 'entities.folderItems', {}),
                contentsSortType: (currentFolderId !== state.currentFolderId) ? SORT_TYPE.DEFAULT : state.contentsSortType,
            };
        case actionTypes.ACTION_SET_FOLDER_CONTENTS:
            return {
                ...state,
                contents: _.get(normalizeFolderContents(action.payload), 'entities.folderItems', {}),
            };
        case actionTypes.ACTION_SORT_FILES_DEFAULT:
            return {
                ...state,
                contentsSortType: SORT_TYPE.DEFAULT,
            };
        case actionTypes.ACTION_SORT_FILES_A_TO_Z:
            return {
                ...state,
                contentsSortType: SORT_TYPE.A_TO_Z,
            };
        case actionTypes.ACTION_SORT_FILES_Z_TO_A:
            return {
                ...state,
                contentsSortType: SORT_TYPE.Z_TO_A,
            };
        case actionTypes.ACTION_SET_FOLDER_NAME:
            const folderId = action.payload.folderId;
            const folderName = action.payload.folderName;
            return {
                ...state,
                contents: {
                    ...state.contents,
                    [folderId]: {
                        ...state.contents[folderId],
                        name: folderName,
                    },
                },
            };
        case actionTypes.ACTION_SET_VIEW_FILE:
            return {
                ...state,
                viewFile: action.payload,
            };
        case actionTypes.ACTION_SET_EDIT_FILE:
            return {
                ...state,
                editFile: action.payload,
            };
        case actionTypes.ACTION_SET_VIEW_FILE_ENTRY:
            return {
                ...state,
                viewFileEntry: action.payload,
            };
        case actionTypes.ACTION_SET_USER_LIST:
            return {
                ...state,
                userList: action.payload,
            };
        case actionTypes.ACTION_SET_SELECT_FILES_IN_PROGRESS:
            return {
                ...state,
                selectFiles: {
                    mode: action.payload.mode || '',
                    isInProgress: action.payload.isInProgress,
                    currentFolderId: action.payload.currentFolderId || '',
                    fileIds: [],
                },
            };
        case actionTypes.ACTION_ADD_FILE_TO_SELECTED_FILE_IDS:
            if (_.size(state.selectFiles.fileIds) === MAX_NUM_SELECTED_FILES) {
                return state;
            }
            return {
                ...state,
                selectFiles: {
                    ...state.selectFiles,
                    fileIds: _.concat(state.selectFiles.fileIds, action.payload),
                },
            };
        case actionTypes.ACTION_REMOVE_FILE_FROM_SELECTED_FILE_IDS: 
            return {
                ...state,
                selectFiles: {
                    ...state.selectFiles,
                    fileIds: _.without(state.selectFiles.fileIds, action.payload),
                },
            };
        case actionTypes.ACTION_SET_SHOW_SELECTED_FILES_ACTION_FAILED: 
            return {
                ...state,
                selectFiles: {
                    ...state.selectFiles,
                    showActionFailed: action.payload,
                },
            };
        case actionTypes.ACTION_SET_VIEWING_USER_DASHBOARD:
            return {
                ...state,
                viewingUserDashboard: action.payload || {},
            };
        case actionTypes.ACTION_RESET:
            return mainInitialState;
        default:
            return state;
    }
}

const infoSnackbarInitialState = {
    isOpen: false,
    messageKey: '',
    messageOptions: {},
};

export const infoSnackbar = (state=infoSnackbarInitialState, { type, payload }) => {
    switch(type) {
        case actionTypes.ACTION_SHOW_INFO_SNACKBAR:
            if (payload === null || payload === '') {
                return state;
            }

            return {
                isOpen: true,
                messageKey: payload.messageKey,
                messageOptions: payload.messageOptions,
            };
        case actionTypes.ACTION_HIDE_INFO_SNACKBAR:
            return infoSnackbarInitialState;
        case actionTypes.ACTION_RESET:
            return infoSnackbarInitialState;
        default:
            return state;
    }
};

const activityIndicatorInitialState = {
    isShowing: false,
};

export const activityIndicator = (state=activityIndicatorInitialState, { type, payload }) => {
    switch(type) {
        case actionTypes.ACTION_SET_SHOW_ACTIVITY_INDICATOR:
            return {
                isShowing: payload,
            };
        case actionTypes.ACTION_RESET:
            return activityIndicatorInitialState;
        default:
            return state;
    }
};

const contextMenuInitialState = {
    isOpen: false,
    menuItems: [], // array of objects with text, icon, handleClick and enabled flag,
};

export const headerContextMenu = (state=contextMenuInitialState, action) => {
    switch(action.type) {
        case actionTypes.ACTION_OPEN_CONTEXT_MENU:
            return {
                ...state,
                isOpen: true,
            };
        case actionTypes.ACTION_CLOSE_CONTEXT_MENU:
            return {
                ...state,
                isOpen: false,
            };
        case actionTypes.ACTION_SET_CONTEXT_MENU_ITEMS:
            return {
                ...state,
                menuItems: action.payload,
            };
        case actionTypes.ACTION_RESET:
            return contextMenuInitialState;
        default:
            return state;
    }
};

const sessionExpiredDialogInitialState = {
    isOpen: false,
};

export const sessionExpiredDialog = (state=sessionExpiredDialogInitialState, { type }) => {
    switch(type) {
        case actionTypes.ACTION_SHOW_SESSION_EXPIRED_DIALOG:
            return {
                isOpen: true,
            };
        case actionTypes.ACTION_HIDE_SESSION_EXPIRED_DIALOG:
            return {
                isOpen: false,
            };
        case actionTypes.ACTION_RESET:
            return sessionExpiredDialogInitialState;
        default:
            return state;
    }
};