import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { connect } from 'redhooks';
import { continueSessionExpired } from '../../store/store.actions';
import { selectIsSessionExpiredDialogOpen } from '../../store/store.selectors';

const SessionExpiredDialog = ({ isOpen, continueSessionExpired }) => {

    const { t } = useTranslation('common');

    return (
        <Dialog open={isOpen}
              onClose={continueSessionExpired}
              aria-labelledby='session-expired-dialog-title'
              aria-describedby='session-expired-dialog-description'
              maxWidth='xs'>
            <DialogTitle id='session-expired-dialog-title' disableTypography={true}>
              <Typography variant='h6' color='inherit'>{t('common:dialog_title_session_expired')}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="session-expired-dialog-description">
                    {t('common:dialog_msg_session_expired')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={continueSessionExpired} color='primary' autoFocus>
                    {t('common:btn_ok')}
                </Button>
          </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = state => ({
    isOpen: selectIsSessionExpiredDialogOpen(state),
});

export default connect(mapStateToProps, {
    continueSessionExpired,
})(SessionExpiredDialog);