import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const AppOfflineReadySnackbar = ({ handleClose }) => {

    const { t } = useTranslation('system');

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            open={true}
            autoHideDuration={6000}
            onClose={handleClose}
            ContentProps={{
                'aria-describedby': 'system-snackbar-app-offline-ready-message-id',
            }}
            message={<Typography id='system-snackbar--app-offline-ready-message-id' color='inherit'>{t('app_offline_ready')}</Typography>}
            action={[
                <Button key='got-it' color='primary' size='small' onClick={handleClose}>
                    {t('btn_got_it')}
                </Button>,
            ]}
      />
    );
}

export default AppOfflineReadySnackbar;