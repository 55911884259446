export const MAX_NUM_SELECTED_FILES = 100;

export const SELECT_FILES_MODE_MOVE = 'move';
export const SELECT_FILES_MODE_REMOVE = 'remove';

export const SORT_TYPE = {
    DEFAULT: 'default',
    A_TO_Z: 'a-z',
    Z_TO_A: 'z-a',
};

export const FILE_ENTRY_ATTACHMENT_ACCESS_LEVEL = 'public';
export const FILE_ENTRY_ATTACHMENT_CUSTOM_PATH = 'fileEntryAttachments/';

export const FILE_ATTACHMENT_ACCESS_LEVEL = 'public';
export const FILE_ATTACHMENT_CUSTOM_PATH = 'fileAttachments/';