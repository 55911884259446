import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import ContextMenu from '../context-menu';

export const defaultConfig = {
  hideHeader: false, 
  hideBackBtn: false,
  handleBackPress: null,
  showHamburgerMenu: false,
  showSearch: false,
  title: '',
  titleMain: true,
};

export const getConfig = (path='/', history) => {
  const pathSegments = path.split('/');
  const pathOne = pathSegments[1];

  switch(pathOne) {
    case '':
    case 'users':
    case 'dashboard':
      return {
        ...defaultConfig,
        hideBackBtn: true,
        showHamburgerMenu: true,
        showSearch: pathOne === 'dashboard',
        title: 'common:header_dashboard',
      };
    case 'search':
      return {
        ...defaultConfig,
        title: 'common:header_dashboard',
      };
    case 'file':
      const hideBackBtn = !_.get(history, 'location.state.fromSearchResults', false);
      return {
        ...defaultConfig,
        hideBackBtn: hideBackBtn,
        showHamburgerMenu: hideBackBtn,
        title: 'common:header_dashboard',
      };
    case 'signin':
      return {
        hideHeader: true,
      };
    case 'set-password':
      return {
        ...defaultConfig,
        title: 'header_set_password',
        titleMain: false,
      }
    case 'signup':
      return {
        ...defaultConfig,
        title: (pathSegments && pathSegments.length > 2) ? 'common:header_sign_up_confirmation' : 'common:header_sign_up',
        titleMain: false,
      };
    case 'reset-password':
      return {
        ...defaultConfig,
        title: (pathSegments && pathSegments.length > 2) ? 'common:header_reset_password_confirmation' : 'common:header_reset_password',
        titleMain: false,
      };
    case 'legal':
      return {
        ...defaultConfig,
        title: 'common:header_legal_information',
        titleMain: false,
      };
    default:
      return defaultConfig;
  }
}

export const Header = (props) => {
  const { classes, history, location, toggleDrawer, navSearch, navDashboard, } = props;
  const { hideHeader, hideBackBtn, handleBackPress, showHamburgerMenu, showSearch, title, titleMain } = getConfig(location.pathname, history);

  const { t, ready } = useTranslation('common');
  
  let titleAlign, titleVariant;
  if (titleMain) {
    titleAlign = 'center';
    titleVariant = 'h4';
  } else {
    titleAlign = 'left';
    titleVariant = 'h6';
  }

  if (hideHeader) {
    return null;
  }

  const handleTitleClick = () => {
    if (title === 'common:header_dashboard') {
      navDashboard();
    }
  }

  return (
    <AppBar position="static">
      <Toolbar>
        { !hideBackBtn ?
          <IconButton className={classes.menuButton} color="inherit" aria-label={t('header:back')} onClick={handleBackPress ? handleBackPress : history.goBack}>
            <ArrowBack />
          </IconButton>
          : null
        }
        {
          showHamburgerMenu ?
          <IconButton className={classes.menuButton} color="inherit" aria-label={t('header:main_menu')} onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          : null
        }
        <div className={classes.titleContainer}>
          <Link className={classes.titleLinkContainer} component='button' color='inherit' underline='none' onClick={handleTitleClick}>
            <Typography variant={titleVariant} className={ titleMain ? classes.titleMain : classes.title } color='inherit' align={titleAlign}>{ ready ? t(title) : '' }</Typography>
          </Link>
        </div>
        { showSearch &&
          <IconButton color="inherit" aria-label={t('header:search')} onClick={navSearch}>
            <SearchIcon />
          </IconButton>
        }
        <ContextMenu />
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default Header;