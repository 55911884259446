import _ from 'lodash';
import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../../common/page-container';
import FileContentToolbar from './file-content-toolbar';
import FileHeaderSection from './file-header-section';
import FileLabelText from './file-label-text';
import EditFileIcon from '@material-ui/icons/EditOutlined';
import ClaimFileIcon from '@material-ui/icons/Link';
import UnclaimFileIcon from '@material-ui/icons/LinkOff';
import ShareFileIcon from '@material-ui/icons/Share';
import AddFileToDashboardIcon from '@material-ui/icons/FolderOpen';
import ClaimUnclaimFileDialog from './claim-unclaim-file-dialog';
import AddFileToDashboardDialog from './add-file-to-dashboard-dialog'
import AddFileEntryFab from './add-file-entry-fab';
import EntryForm from './entry-form';
import EntryView from './entry-view';
import { Route } from 'react-router';
import EntryListView from './entry-list-view';
import ViewFileInAppSnackbar from './view-file-in-app-snackbar';
import { isInstalledPWA } from '../../../../utils/func';
import { isAndroid, isChrome, isMobile, isIOS, } from 'react-device-detect'
import { shareFile, isShareSupported, iOSCopyTextToClipboard } from '../../../../utils/share';
import { getFileUrl } from './util';
import SuspenseView from '../../../suspense-view';

export const populateContextMenuItems = (user, file, t, menuClickHandlers) => {
    const menuItems = [];

    if (!file.claimed_by) {
        menuItems.push({
            text: t('common:context_menu_claim_file'),
            icon: ClaimFileIcon,
            handleClick: menuClickHandlers.showClaimFileDialog,
            enabled: true,
        });                
    }

    if (!!file.claimed_by && (user.isAdmin || (user.userId === file.claimed_by))) {
        menuItems.push({
            text: t('common:context_menu_unclaim_file'),
            icon: UnclaimFileIcon,
            handleClick: menuClickHandlers.showUnclaimFileDialog,
            enabled: true,
        });                
    }

    menuItems.push({
        text: t('common:context_menu_add_file_to_dashboard'),
        icon: AddFileToDashboardIcon,
        handleClick: menuClickHandlers.showAddFileToDashboardDialog,
        enabled: !!user
    });

    menuItems.push({
        text: t('common:context_menu_edit_file'),
        icon: EditFileIcon,
        handleClick: menuClickHandlers.handleEditFile,
        enabled: (user.isAdmin || (user.userId === file.claimed_by))
    });

    if (isMobile && isInstalledPWA()) {
        menuItems.push({
            text: t('common:context_menu_share_file'),
            icon: ShareFileIcon,
            handleClick: menuClickHandlers.handleShareFile,
            enabled: true,
        });
    }

    return menuItems;
}

const ViewFile = ({ user, dashboardFolderId, file, viewFile, setContextMenuItems, showClaimFileDialog, showUnclaimFileDialog, showAddFileToDashboardDialog, createFileEntry, showInfoSnackbar, downloadFileEntryAttachment, navViewFile, navEditFile, navCreateFileEntry, navViewFileEntry, history, match, classes }) => {

    const { t, ready } = useTranslation(['common', 'file']);
    const fileAlias = _.get(match, 'params.fileAlias');
    const handleEditFile = () => {
        navEditFile({
            fileAlias: fileAlias,
            parentFolderId: _.get(history, 'location.state.parentFolderId', dashboardFolderId),
            parentFolderName: _.get(history, 'location.state.parentFolderName', t('common:dashboard')),
        });
    };

    const shareFileAsync = async fileUrl => {
        const result = await shareFile(fileUrl);
        if (result.success) {
            // If the browser does not support the share api and we defaulted to copy to clipboard we inform the user
            if (result.copiedToClipboard) {
                showInfoSnackbar(t('common:snackbar_file_link_copied_to_clipboard'));
            }
        } else {
            showInfoSnackbar(t('common:snackbar_failed_to_share_file_link'));
        }
    }

    const handleShareFile = event => {

        const fileUrl = getFileUrl(file.id);

        if (isIOS && !isShareSupported) {
            // If we are on iOS and share is not supported we need to execute "copy to clipboard" inside a click event
            if (iOSCopyTextToClipboard(fileUrl)) {
                showInfoSnackbar(t('common:snackbar_file_link_copied_to_clipboard'));
            } else {
                showInfoSnackbar(t('common:snackbar_failed_to_share_file_link'));
            }
            return;
        }

        shareFileAsync(fileUrl);
    };

    // Note: using useLayoutEffect instead of useEffect here to prevent a weird state where
    // the cleanup function seems to run after the component has been mounted again with menuItems
    // useLayoutEffect fires synchronously after all DOM mutations while useEffect run after the render is committed to the screen
    useLayoutEffect(() => {
        const fileAlias = _.get(match, 'params.fileAlias');
        if (_.isEmpty(file) || file.alias !== fileAlias) {
            viewFile({ fileAlias: fileAlias });
        }

        if (_.has(file, 'id') && _.has(user, 'userId')) {

            const menuItems = populateContextMenuItems(user, file, t, {
                handleEditFile,
                showClaimFileDialog,
                showUnclaimFileDialog,
                showAddFileToDashboardDialog,
                handleShareFile,
            });

            if (menuItems.length > 0) {
                setContextMenuItems(menuItems);
            }
        }

        // Return the cleanup function
        // We need to unset the context menu items when moving away from this screen
        return () => {
            setContextMenuItems([]);
        };
    }, [user, file]);

    // Only show the 'View file in app' banner if:
    // - we are on an Android device and a Chrome browser
    // - we are not already inside the Progressive Web App
    // - and we did not navigate to the file via a folder (i.e. the parentFolderId is not set)
    const shouldShowViewFileInAppSnacbar = (isAndroid && isChrome) && !isInstalledPWA() && !_.has(history, 'location.state.parentFolderId');

    const [isViewFileInAppSnackbarOpen, setIsViewFileInAppSnackbarOpen] = useState(shouldShowViewFileInAppSnacbar);

    const closeViewFileInAppSnackbar = () => setIsViewFileInAppSnackbarOpen(false);

    const handleCreateFileEntry = ({ ratings, comment, attachment }) => {
        createFileEntry({
            fileEntryData: {
                fileAlias: fileAlias,
                ratings,
                comment,
                attachment,
            },
            history,
            successMessage: t('file:info_snackbar_file_entry_created'),
        });
    };

    const showFileEntryForm = () => {
        navCreateFileEntry({
            fileAlias: fileAlias,
            parentFolderId: _.get(history, 'location.state.parentFolderId', dashboardFolderId),
            parentFolderName: _.get(history, 'location.state.parentFolderName', t('common:dashboard')),
        });
    };

    const handleViewFile = () => {
        navViewFile({
            fileAlias: fileAlias,
            parentFolderId: _.get(history, 'location.state.parentFolderId', dashboardFolderId),
            parentFolderName: _.get(history, 'location.state.parentFolderName', t('common:dashboard')),
        });
    };

    const handleViewEntry = id => {
        navViewFileEntry({
            fileAlias: fileAlias,
            fileEntryId: id,
            parentFolderId: _.get(history, 'location.state.parentFolderId', dashboardFolderId),
            parentFolderName: _.get(history, 'location.state.parentFolderName', t('common:dashboard')),
        });
    };

    const handleDownloadAttachment = attachmentKey => {
        downloadFileEntryAttachment({
            attachmentKey,
        });
    };

    if (!ready) {
        return <SuspenseView />;
    }

    return (
        <PageContainer theme='light'>
            { !_.isEmpty(file) &&
                <>
                    <div className={classes.mainContainer}>
                        <FileContentToolbar parentFolderId={_.get(history, 'location.state.parentFolderId', dashboardFolderId)}
                            parentFolderName={_.get(history, 'location.state.parentFolderName', t('common:dashboard'))}
                            advertiserUrl={_.get(file, 'advertiser_url', '')}
                            numFileEntries={_.get(file, 'file_entries', []).length} />
                        <FileHeaderSection headerText={_.get(file, 'name', '')}
                            imageKey={_.get(file, 'image_key', '')}
                            advertiserUrl={_.get(file, 'advertiser_url', '')} />
                        <FileLabelText label={t('file:lbl_description')} text={_.get(file, 'description', '')} />
                        <Route exact path={`${match.path}`} render={() => <EntryListView entries={file.file_entries} handleViewEntry={handleViewEntry} handleDownloadAttachment={handleDownloadAttachment} />} />
                        <Route exact path={`${match.path}/entry`} render={() => <EntryForm questions={file.questions} addFileEntry={handleCreateFileEntry} closeFileEntryForm={handleViewFile}/>} />
                        <Route exact path={`${match.path}`} render={() => <AddFileEntryFab handleAddFileEntry={showFileEntryForm} />} />
                        <Route path={`${match.path}/entry/:entryId`} render={() => <EntryView handleViewAllEntries={handleViewFile}/> } />
                    </div>
                </>
            }
            <ClaimUnclaimFileDialog fileAlias={file.alias} />
            <AddFileToDashboardDialog fileAlias={file.alias} fileName={file.name} />
            <ViewFileInAppSnackbar isOpen={isViewFileInAppSnackbarOpen} handleClose={closeViewFileInAppSnackbar} fileLink={getFileUrl(file.alias)} />
        </PageContainer>
    );
}

export default ViewFile;