import _ from 'lodash';
import { browserHistory } from './index';
import { call, put, select, takeLatest, } from 'redux-saga/effects';
import * as actionsTypes from './nav.action.types';
import { selectViewFile, selectCurrentFolderId, selectViewingUserDashboardId, selectUserDashboardFolderId, } from '../store/store.selectors';
import { setViewFile, setFolderContents, setViewingUserDashboard } from '../store/store.actions';
import { createSaga } from '../store/store.util.sagas';
import { searchFiles } from '../components/pages/search/search.actions';

// we want to clear the current dashboard folder contents state value if we are switching to a completely different folder
// this is to prevent the old folder contents flashing/showing before the new one loads
function* clearFolderContentsIfNeeded(folderId) {
    const id = yield select(selectCurrentFolderId);
    if (id !== folderId) {
        yield put(setFolderContents([]));
    }
}

export function* navDashboardSaga () {
    const viewingUserDashboardId = select(selectViewingUserDashboardId);
    if (!!viewingUserDashboardId) {
        // Clear the 'Viewing Dashboard mode'
        yield put(setViewingUserDashboard());
    }
    const userDashboardFolderId = yield select(selectUserDashboardFolderId);
    yield call(clearFolderContentsIfNeeded, userDashboardFolderId);
    yield call(browserHistory.replace, '/dashboard');
};

export function* viewFolderSaga ({ payload: { folderId } }) {
    yield call(clearFolderContentsIfNeeded, folderId);
    yield call(browserHistory.replace, `/dashboard/${folderId}`);
};


// we want to clear the current viewFile state value if we are switching to a completely different file
// this is to prevent the old file flashing/showing before the new one loads
function* clearViewFileIfNeeded(fileAlias) {
    const { alias } = yield select(selectViewFile);
    if (alias !== fileAlias) {
        yield put(setViewFile({}));
    }
}

export function* viewFileSaga ({ payload: { fileAlias, parentFolderId, parentFolderName, fromSearchResults, } }) {
    yield call(clearViewFileIfNeeded, fileAlias);
    yield call(fromSearchResults ? browserHistory.push : browserHistory.replace, `/file/${fileAlias}`, {
        parentFolderId,
        parentFolderName,
        fromSearchResults: fromSearchResults ? fromSearchResults : _.get(browserHistory, 'location.state.fromSearchResults'),
    });
};

export function* editFileSaga ({ payload: { fileAlias, parentFolderId, parentFolderName, } }) {
    yield call(clearViewFileIfNeeded, fileAlias);
    yield call(browserHistory.replace, `/file/${fileAlias}/edit`, {
        parentFolderId: parentFolderId ? parentFolderId : _.get(browserHistory, 'location.state.parentFolderId'),
        parentFolderName: parentFolderName ? parentFolderName : _.get(browserHistory, 'location.state.parentFolderName'),
        fromSearchResults: _.get(browserHistory, 'location.state.fromSearchResults'),
    });
};

export function* createFileSaga () {
    yield call(browserHistory.replace, `/file`);
};

export function* createFileEntrySaga ({ payload: { fileAlias, parentFolderId, parentFolderName, } }) {
    yield call(clearViewFileIfNeeded, fileAlias);
    yield call(browserHistory.replace, `/file/${fileAlias}/entry`, {
        parentFolderId: parentFolderId ? parentFolderId : _.get(browserHistory, 'location.state.parentFolderId'),
        parentFolderName: parentFolderName ? parentFolderName : _.get(browserHistory, 'location.state.parentFolderName'),
        fromSearchResults: _.get(browserHistory, 'location.state.fromSearchResults'),
    });
};

export function* viewFileEntrySaga ({ payload: { fileAlias, fileEntryId, parentFolderId, parentFolderName, } }) {
    yield call(clearViewFileIfNeeded, fileAlias);
    yield call(browserHistory.replace, `/file/${fileAlias}/entry/${fileEntryId}`, {
        parentFolderId: parentFolderId ? parentFolderId : _.get(browserHistory, 'location.state.parentFolderId'),
        parentFolderName: parentFolderName ? parentFolderName : _.get(browserHistory, 'location.state.parentFolderName'),
        fromSearchResults: _.get(browserHistory, 'location.state.fromSearchResults'),
    });
};

export function* searchSaga () {
    yield put(searchFiles({
        name: '',
        description: '',
    }));
    yield call(browserHistory.push, '/search');
};

export function* viewUsersSaga () {
    yield call(browserHistory.replace, '/users');
};

export default function* rootSaga() {
    yield takeLatest(actionsTypes.ACTION_NAV_DASHBOARD, navDashboardSaga);
    yield takeLatest(actionsTypes.ACTION_NAV_VIEW_FOLDER, viewFolderSaga);
    yield takeLatest(actionsTypes.ACTION_NAV_VIEW_FILE, viewFileSaga);
    yield takeLatest(actionsTypes.ACTION_NAV_EDIT_FILE, editFileSaga);
    yield takeLatest(actionsTypes.ACTION_NAV_CREATE_FILE, createSaga(createFileSaga));
    yield takeLatest(actionsTypes.ACTION_NAV_CREATE_FILE_ENTRY, createFileEntrySaga);
    yield takeLatest(actionsTypes.ACTION_NAV_VIEW_FILE_ENTRY, viewFileEntrySaga);
    yield takeLatest(actionsTypes.ACTION_NAV_SEARCH, searchSaga);
    yield takeLatest(actionsTypes.ACTION_NAV_USERS, viewUsersSaga);
}