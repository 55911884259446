import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import materialAppTheme from './styles/materialAppTheme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import * as serviceWorker from './serviceWorker';
import AppOfflineReadySnackbar from './components/system-snackbars/AppOfflineReadySnackbar';
import AppUpdateAvailableSnackbar from './components/system-snackbars/AppUpdateAvailableSnackbar';


const AppWrapper = ({ children }) => (
    <MuiThemeProvider theme={materialAppTheme}>
        <I18nextProvider i18n={ i18n }>
            {children}
        </I18nextProvider>
    </MuiThemeProvider>
);

ReactDOM.render(
    <AppWrapper>
        <App/>
    </AppWrapper>,
    document.getElementById('root')
);

const removeSystemSnackbar = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('system-snackbar'));
};

const updateApp = () => { 
    window.location.reload();
};

const config = {
    onSuccess: () => {
        // Inform the user that the app is offline ready (i.e. the service worker was successfully installed and files were cached)
        ReactDOM.render(
            <AppWrapper>
                <AppOfflineReadySnackbar handleClose={removeSystemSnackbar} />
            </AppWrapper>,
            document.getElementById('system-snackbar'));
    },
    onUpdate: () => {
        // Inform the user that an app update is available
        ReactDOM.render(
            <AppWrapper>
                <AppUpdateAvailableSnackbar handleUpdate={updateApp} handleClose={removeSystemSnackbar} />
            </AppWrapper>,
        document.getElementById('system-snackbar'));
    }
}

// We need to register a service worker to allow the Progressive Web App (PWA) 'offline ready' feature and `Add to Home Screen` feature
serviceWorker.register(config);