import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '../../../common/form/texField/textField';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

export default ({ isOpen, handleClose, type, handleAction, t, classes }) => {

    const initialValues = { name: '' };

    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .required(t('form:required'))
    });

    const onSubmit = (values) => {
        handleAction(values.name);
    };

    let title;
    let subtitle;
    let label;

    switch(type) {
        case 'createFolder':
            title = t('dashboard:dialog_title_create_folder');
            subtitle = t('dashboard:dialog_subtitle_create_folder');
            label = t('dashboard:lbl_folder_name');
            break;
        case 'renameFolder':
            title = t('dashboard:dialog_title_rename_folder');
            subtitle = t('dashboard:dialog_subtitle_rename_folder');
            label = t('dashboard:lbl_folder_rename');
            break;
        default:
            title = '';
            subtitle = '';
            label= '';
    }

    return (
        <Dialog open={isOpen}
              onClose={handleClose}
              aria-labelledby='create-rename-dialog-title'
              aria-describedby='create-rename-dialog-description'>
            <DialogTitle id='create-rename-dialog-title' disableTypography={true}>
              <Typography variant='h6' color='inherit'>{title}</Typography>
              <div className={classes.subtitle}>
                <Typography variant='subtitle1' color='inherit'>{subtitle}</Typography>
              </div>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={ initialValues }
                    validationSchema={ validationSchema }
                    onSubmit={ onSubmit }
                    render={ ({ status, isSubmitting }) => (
                        <Form className={ classes.formContainer }>
                            <Field 
                                id='create-rename-dialog-description'
                                name='name'
                                label={ label }
                                required
                                component={ TextField }
                                type='text'
                                theme='light' />
                            { status && status.msg && <FormHelperText error>{ t(status.msg) }</FormHelperText> }
                            <div className={classes.btnsContainer}>
                                <Button color='primary' variant='text' disabled={ isSubmitting } onClick={handleClose}>
                                    {t('common:btn_cancel')}
                                </Button>
                                <Button className={ classes.btnOk } type='submit' color='primary' variant='text' disabled={ isSubmitting }>
                                    {t('common:btn_ok')}
                                </Button>
                            </div>
                        </Form>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
}