import * as actionTypes from './claimUnclaimFileDialog.action.types';

const initialState = {
    isShowing: false,
    mode: '', // mode can be empty, claim or unclaim
};

export const REDUCER_NAME = 'claimUnclaimFileDialog';

const claimUnclaimFileDialogReducer = (state=initialState, action) => {
    switch(action.type) {
        case actionTypes.ACTION_SHOW_CLAIM_FILE_DIALOG:
            return {
                isShowing: true,
                mode: 'claim',
            };
        case actionTypes.ACTION_SHOW_UNCLAIM_FILE_DIALOG:
            return {
                isShowing: true,
                mode: 'unclaim',
            };
        case actionTypes.ACTION_HIDE_CLAIM_UNCLAIM_FILE_DIALOG:
            return {
                isShowing: false,
                mode: '',
            };
        default:
            return state;
    }
};

export default claimUnclaimFileDialogReducer;