import _ from 'lodash';
import { call, put, takeLatest, } from 'redux-saga/effects';
import { ACTION_GET_USER, ACTION_GET_USER_LIST, ACTION_VIEW_USER_DASHBOARD, ACTION_STOP_VIEWING_USER_DASHBOARD } from './store.action.types';
import { showInfoSnackbar, setShowActivityIndicator, setUser, setUserList, setViewingUserDashboard  } from './store.actions';
import { Auth } from "aws-amplify";
import { getUserList } from '../api/admin';
import { getUser } from '../auth/api/users';
import { handleErrorIfSessionExpired, createSaga } from './store.util.sagas';
import { SYSTEM_FOLDER_LOOKUP_NAMES } from '../api/folder/constants';
import { navViewFolder, navUsers, } from '../nav/nav.actions';
import { browserHistory } from '../nav';

const getUserStateData = (apiUserData, cognitoEmail, cognitoId) => {

    // Note: flattening the 'system_folders' array to a map: [{ dashboard: { id: '123', lookup_name: 'dashboard' } }] to { dashboard: { id: '123', lookup_name: 'dashboard' } }
    const systemFolders = {};
    const sysFolders = _.get(apiUserData, 'system_folders', []);
    _.each(sysFolders, sysFolder => {
        const systemFolderKey = _.keys(sysFolder, [''])[0];
        _.set(systemFolders, systemFolderKey, _.get(sysFolder, systemFolderKey, {}));
    });

    return {
        cognitoId: cognitoId,
        email: cognitoEmail,
        userId: _.get(apiUserData, 'id', ''),
        displayName: _.get(apiUserData, 'display_name', ''),
        systemFolders: systemFolders,
        isAdmin: _.get(apiUserData, 'group') === 2, // Note: group 1 is general users and group 2  is admin users
    };
};

export function* getUserSaga() {
    try {
        const cognitoUser = yield Auth.currentAuthenticatedUser();

        const cognitoId = _.get(cognitoUser, 'username', '');
        const email = _.get(cognitoUser, 'attributes.email', '');

        const result = yield getUser(email);

        if (result.success) {
            yield put(setUser(
                getUserStateData(_.get(result, 'data', {}), email, cognitoId)
            ));
        } else {
            yield put(setUser(null));
        }
    } catch(error) {
        const handled = yield call(handleErrorIfSessionExpired, error);
        if (!handled) {
            yield put(setUser(null));
        }
    }
}


export function* getUserListSaga() {
    yield put(setShowActivityIndicator(true));
    const result = yield call(getUserList);

    if (result.success) {
        yield put(setUserList(result.data));
        yield put(setShowActivityIndicator(false));
    } else {
        throw result;
    }
}

export function* viewUserDashboardSaga({ payload: { email } }) {
    try {
        yield put(setShowActivityIndicator(true));
        const result = yield getUser(email);

        if (result.success) {
            const userStateData = getUserStateData(_.get(result, 'data', {}), email);
            yield put(setViewingUserDashboard(userStateData));
            yield put(setShowActivityIndicator(false));
            yield put(navViewFolder({
                folderId: _.get(userStateData, `systemFolders.${SYSTEM_FOLDER_LOOKUP_NAMES.dashboard}.id`)
            }));
        }
    } catch(error) {
        yield put(setShowActivityIndicator(false));
        yield put(navUsers());
        yield put(showInfoSnackbar('common:err_failed_to_view_user_dashboard'));
    }
}

export function* stopViewingUserDashboardSaga() {
    try {
        yield put(setViewingUserDashboard({}));
        yield put(navUsers());
    } catch(error) {
        yield call(browserHistory.replace, '/dashboard');
    }
}

export default function* rootSaga() {
    yield takeLatest(ACTION_GET_USER, getUserSaga);
    yield takeLatest(ACTION_GET_USER_LIST, createSaga(getUserListSaga));
    yield takeLatest(ACTION_VIEW_USER_DASHBOARD, viewUserDashboardSaga);
    yield takeLatest(ACTION_STOP_VIEWING_USER_DASHBOARD, stopViewingUserDashboardSaga);
}