export default theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignSelf: 'center',
        padding: 4,
        backgroundColor: theme.palette.secondary.light,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        flex: 1,
        color: theme.palette.common.white,
    },
    txtLeft: {
        marginLeft: 4,
        maxWidth: 180,
        [theme.breakpoints.up('md')]: {
            maxWidth: 400,
        },
    },
    linkDashboard: {
        marginLeft: 4,
    },
    btn: {
        width: 32,
        height: 32,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
});