import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

const InfoSnackbar = ({ isOpen, messageKey, messageOptions, hideInfoSnackbar, t, }) => {

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open={isOpen}
            autoHideDuration={6000}
            onClose={hideInfoSnackbar}
            ContentProps={{
                'aria-describedby': 'system-snackbar-app-info-message-id',
            }}
            message={<Typography id='system-snackbar-app-info-message-id' color='inherit'>{t(messageKey, messageOptions)}</Typography>}
            action={[
                <Button key='update' color='primary' size='small' onClick={hideInfoSnackbar}>
                    {t('btn_ok')}
                </Button>,
            ]}
        />
    );
}

export default InfoSnackbar;