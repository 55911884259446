export default theme => ({
    menuButton: {
        marginLeft: -12,
        marginRight: 12,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'center',
    },
    titleLinkContainer: {
        marginLeft: -18 - theme.mixins.gutters().paddingLeft, // position the text in the center of Toolbar: -18 comes from the  IconButton icon size and padding (24 and 12) (e.g. the Hamburger menu icon button) and the gutters are applied to the Toolbar
    },
    title: {
        flexGrow: 1,
    },
    titleMain: {
        flexGrow: 1,
        fontWeight: 'lighter',
    },
});