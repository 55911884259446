import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const FolderItemToolbar = ({ leftLinkText, handleLeftLinkClick, middleText, rightLinkText, handleRightLinkClick, showDividers=false, classes }) => {
    return  (
        <div className={classes.container}>
            <div className={classes.toolbarTextContainer}>
                <Link
                    component='button'
                    variant='body2'
                    color='textPrimary'
                    align='left'
                    underline='none'
                    onClick={handleLeftLinkClick}
                >
                    <Typography className={classes.toolbarText} align='left'>{leftLinkText}</Typography> 
                </Link>
            </div>
            { showDividers && <div className={classes.divider} /> }
            <div className={classes.toolbarTextContainer}>
                <Typography className={classes.toolbarText} align='center'>{middleText}</Typography>
            </div>
            { showDividers && <div className={classes.divider} /> }
            <div className={classes.toolbarTextRightContainer}>
                {
                    !!rightLinkText &&
                    <Link
                        component='button'
                        variant='body2'
                        color='textPrimary'
                        align='left'
                        underline='none'
                        onClick={handleRightLinkClick}
                    >
                        <Typography className={classes.toolbarText} align='right'>{rightLinkText}</Typography> 
                    </Link>
                }
            </div>
        </div>
    );
}

export default FolderItemToolbar