import _ from 'lodash';
import React from 'react';
import TextField from '../../components/common/form/texField/textField';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Interweave from 'interweave';

import { PASSWORD_FORMAT_CONFIG, PASSWORD_FORMAT_REGEX, PASSWORD_FORMAT_MAX_CHARS } from '../constants';
import { callFuncOnEnterKeyDown } from '../../utils/keyboard';
import { useTranslation } from 'react-i18next';
import SuspenseView from '../../components/suspense-view';

const SetPasswordPage = ({ authApi, classes, history, match }) => {

  const { t, ready } = useTranslation(['common', 'form', 'authentication']);

  const email = _.get(match, 'params.email', '');

  const passwordFormatTranslated = t('form:passwordFormat', PASSWORD_FORMAT_CONFIG);
  const requiredTranslated = t('form:required');

  const initialValues = { email: email, newPassword: '', repeatNewPassword: '', };
  const SetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
            .required(requiredTranslated)
            .matches(PASSWORD_FORMAT_REGEX, passwordFormatTranslated),
    repeatNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword')], t('form:passwordsDoNotMatch'))
            .required(requiredTranslated),
  });

  const onSubmit = async (values, actions) => {
    const result = await authApi.completeNewPassword(values.newPassword);
    const { success, errorMessage } = result;
    actions.setSubmitting(false);
    if (success) {
        authApi.goToPage(`/signin/${email}`);
    } else {
        actions.setStatus({ msg: errorMessage });
    }
  };

  if (!ready) {
    return <SuspenseView />;
  }

  return (
    <div className={ classes.formContainer }>
      <div className={ classes.container }>
        <Typography className={ classes.formTitleVerticalSpace } variant='subtitle1' align='center' color="textSecondary"><Interweave content={t('authentication:info_set_password')} /></Typography>
        <Formik
          initialValues={ initialValues }
          validationSchema={ SetPasswordSchema }
          onSubmit={ onSubmit }
          render={ ({ status, isSubmitting, handleSubmit }) => (
            <Form className={ classes.formFieldsContainer } onKeyDown={e =>  callFuncOnEnterKeyDown(e, handleSubmit)}>
              <Field name='email'
                    label={ t('authentication:email') }
                    required
                    component={ TextField }
                    type='email'
                    disabled={true} />
              <Field name='newPassword'
                    label={ t('authentication:new_password') }
                    required
                    component={ TextField }
                    type='password'
                    maxLength={PASSWORD_FORMAT_MAX_CHARS} />
              <Field name='repeatNewPassword'
                    label={ t('authentication:repeat_new_password') }
                    required
                    component={ TextField }
                    type='password'
                    maxLength={PASSWORD_FORMAT_MAX_CHARS} />
              { status && status.msg && <FormHelperText error>{ t(status.msg) }</FormHelperText> }
              <Button className={ classes.formComponentVerticalSpace } type='submit' color='primary' variant='contained' disabled={ isSubmitting } fullWidth>
                {t('authentication:btn_set_password')}
              </Button>
            </Form>
          ) }
        />
      </div>
    </div>
  );
};

export default SetPasswordPage;
