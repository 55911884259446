import UserList from './userList';
import userListMaterialStyles  from './userList.materialStyles';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core';
import { selectUserList, selectUserDashboardFolderId } from '../../../../store/store.selectors';
import { getUserList } from '../../../../store/store.actions';
import { showEnableUserDialog, setEnableUser, setDisableUser, showDisableUserDialog, hideEnableDisableUserDialog } from './userList.actions';
import { connect } from 'redhooks';
import { compose } from '../../../../utils/func';
import { selectIsShowing, selectEnable, selectSelectedUserId } from './userList.selectors';
import { setContextMenuItems, viewUserDashboard, } from '../../../../store/store.actions';
import withGA from '../../../../utils/withGA'

const mapStateToProps = (state, props) => ({
    dashboardFolderId: selectUserDashboardFolderId(state),
    isShowing: selectIsShowing(state),
    enable: selectEnable(state),
    selectedUserId: selectSelectedUserId(state),
    userList: selectUserList(state),
});

export default compose(
    withRouter,
    withStyles(userListMaterialStyles),
    connect(mapStateToProps, {
        showEnableUserDialog,
        hideEnableDisableUserDialog,
        showDisableUserDialog,
        getUserList,
        setDisableUser,
        setEnableUser,
        setContextMenuItems,
        viewUserDashboard,
    }),
    withGA,
  )(UserList);